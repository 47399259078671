import {dataTemplate} from './dataTemplate';

const collection = 'objectives';
export const CommonTaskTypes = {
  TRAIT: 'trt',
  EXPERIENCE: 'exp',
  ACCOMPLISHMENT: 'acc',
  PRACTICE: 'prc',
  NOTE: 'not',
  TODO: 'tod',
  TASK_RECURRING: 'tkr'
};
// export const ListTypes = {
//   OBJECTIVES: 'tobjv',
//   TASKS: 'ttask', // legacy
//   XXRESPONSIBILITIES: 'tresp',
//   NOTES: 'tnote'
// }

export function commonTaskTemplate(name, allowedTaskTypes) {
  const superTemplate = dataTemplate(collection);

  return {
    name,
    getTypes: () => {
      return allowedTaskTypes;
    },
    getCollectionRef: () => {
      console.log(`---- getCollectionRef(${name})`);
      const collRef = superTemplate.getCollectionRef();
      if (!allowedTaskTypes || allowedTaskTypes.length === 0) {
        return collRef;
      }
      return collRef.where('objType', 'in', allowedTaskTypes);
    },
    getDocumentRef: (id) => superTemplate.getDocumentRef(id),
    getDocument: (id) => superTemplate.getDocument(id),
    putDocument: (id, doc) => {
      if (!doc.objType && allowedTaskTypes && allowedTaskTypes.length > 0) {
        // If no type, set type to default (first value in types list)
        doc.objType = allowedTaskTypes[0];
      }
      return superTemplate.putDocument(id, doc)
    },
    removeDocument: (id) => superTemplate.removeDocument(id)
  }
};
