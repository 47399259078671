import React from 'react';
import WeeklyPlan from '../../data/store/weeklyPlan';
import WeeklyTasks from './WeeklyTasks';
import {getToday, firstOfWeek} from '../../util/dateHelper';
import {getDailyPlan, getWeeklyPlanForDay} from '../../util/taskHelper';
import {registerWheelTreeListener} from '../../data/wheelTree';
import './DailyPlanning.scss';

class ChooseTasks extends React.Component {
  constructor(props) {
    super(props);
    const day = this.props.day || getToday();
    const week = firstOfWeek(day);
  }

  getDailyPlan() {
    const {weeklyPlan, day} = this.props;
    return getDailyPlan(weeklyPlan, day);
  }
  render() {
    const { weeklyPlan, day, week, wheelTree, wheelTreeHash, taskHash, onTasksAdded, projects, taskList, onUpdateWeeklyTasks } = this.props;
    const today = this.getDailyPlan(day);
    if (today.status !== 'done') {
      if (this.props.weeklyPlan._waitingForData) {
        return <div>loading...</div>
      }
      return (
        <div className='choose-tasks'>
          <div>
            <WeeklyTasks
              weeklyPlan={weeklyPlan}
              wheelTree={wheelTree}
              projects={projects}
              taskList={taskList}
              day={day}
              week={week}
              objHash={wheelTreeHash}
              taskHash={taskHash}
              onChangeDailyTasks={this.handleChangeDailyTasks}
              onTasksAdded={onTasksAdded}
              onUpdateWeeklyTasks={onUpdateWeeklyTasks}
            />
          </div>
        </div>
      )
    }
  }
}

export default ChooseTasks;
