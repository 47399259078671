import React, {useState, useMemo} from 'react';
import {DayButtons} from '../index';
import {formatDay, getToday} from '../../util/dateHelper';
import Positioner, {HEADER} from './Positioner';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import './Calendar.scss';

const propTypes = {
  style: PropTypes.string,   // 'day', 'week', 'month'
  day: PropTypes.number,
  startTime: PropTypes.number,      // float, where 24 hours === 1.0
}

const HOURS_PER_LINE = 2;
const DEFAULT_WIDTH = 200;

const TimeBlock = styled.div`
  height: ${props => props.height || 50}px;
  width: ${props => props.width || DEFAULT_WIDTH}px;
  background-color: #dddddd;
  font-size: ${props => props.scale || 12}pt;
`;

const TimeLabel = styled.div`
  height: ${props => props.height || 50}px;
  width: ${props => props.width || DEFAULT_WIDTH}px;
  padding-right: 3px;
  font-size: ${props => props.scale || 12}pt;
`;

export default function GridBackground(props) {
  const {style = 'day', day, onChangeDay, showHeading=true} = props;
  let {positioner} = props;
  let [scale] = useState(10);
  let [stateDay] = useState(getToday());
  const localPos = useMemo(() => new Positioner({scale}), [scale]);
  if (!positioner) {
    positioner = localPos;
  }

  const getDay = () => {
    return day || stateDay;
  }

  const timeLabel = (h) => {
    if (h===0) return '12m';
    if (h<12) return `${h}a`;
    if (h===12) return '12n';
    return `${h-12}p`
  }

  const renderDay = (col = 0) => {
    const ret = [];
    for(let hr=0; hr<24; hr += HOURS_PER_LINE) {
      const rect = positioner.getRect(hr, col, hr + HOURS_PER_LINE);
      ret.push(
        <TimeBlock scale={scale} {...rect} key={hr}>
          <div className='time-block'/>
        </TimeBlock>
      )
    }
    return (
      <div className='day-col'>
      {showHeading ?
        <div className='header'>
          {style === 'day' && onChangeDay ?
            <DayButtons day={getDay()} onChange={onChangeDay} />
          :
            formatDay(day)
          }
        </div>
        :
          null
      }
        {ret}
      </div>
    )
  }

  const renderDayLabels = () => {
    const ret = [];
    for(let hr=0; hr<24; hr += HOURS_PER_LINE) {
      const rect = positioner.getRect(hr,HEADER,hr+HOURS_PER_LINE);
      ret.push(
        <TimeLabel scale={scale} {...rect} key={hr}>
          <div className='time-block label'>{timeLabel(hr)}</div>
        </TimeLabel>
      )
    }
    return (
      <div className='day-col labels'>
        {showHeading ?
          <div className='header' />
        :
          null
        }
        {ret}
      </div>
    )
  }

  return (
    <div className='grid-background'>
      <div className='cols'>
        {renderDayLabels()}
        {renderDay()}
      </div>
    </div>
  )
}

GridBackground.propTypes = propTypes;
