import React from 'react';
import WeeklyPlan from '../../data/store/weeklyPlan';
import {startOfDay, addDays} from 'date-fns';
import {
  addMinutes,
  getToday,
  getDayTime,
  dayToDate,
  formatDay,
  firstOfWeek,
  parseTime,
  getDaysFromDayList,
  getDayOfWeek,
  dateToTimeStr,
  MILLIS_IN_DAY
} from '../../util/dateHelper';
import {withContext} from '../../util/context';
import {getDailyPlan, getInstanceId, getInstanceOf, calItemId} from '../../util/taskHelper';
import {registerProjectTreeListener} from '../../data/projectTree';
import {registerWheelTreeListener} from '../../data/wheelTree';
import {InputTime, CheckBox, Button} from '../../components';

class CalendarTasks extends React.Component {
  constructor(props) {
    super(props);
    const {day=getToday()} = this.props;
    const week = firstOfWeek(day);
    const startTime = new Date(Math.floor((new Date())/1000/60/5 + 1)*1000*60*5);
    const startAlarm = false;
    const start = dateToTimeStr(startTime, true);
    this.state = {
      day,
      week,
      weeklyPlan: {
        _waitingForData: true
      },
      calendarItems: [],
      view: 'list',
      startTime,
      startAlarm,
    }
    this.unregisterWheelTree = registerWheelTreeListener((wheelTree, wheelTreeHash) => {
      this.setState({wheelTree, wheelTreeHash});
    })
    this.unregisterProjectTreeListener = registerProjectTreeListener((projectTree, projectTreeHash) => {
      this.setState({
        projectTree,
        projectTreeHash
      })
    })
  }
  componentDidMount() {
    this.fetchData(this.state.day);
  }
  componentWillUnmount() {
    if (this.unregisterProjectTreeListener) this.unregisterProjectTreeListener();
  }
  fetchData(day) {
    const week = firstOfWeek(day);
    const begin = startOfDay(dayToDate(day));
    const end = addDays(begin, 1);
    this.props.context.gapiWrapper.listUpcomingEvents(begin, end).then(this.handleCalendarResults);
    // this.loadWeekly(week);
  }
  handleCalendarResults = (results) => {
    const calendarItems = results.result.items;
    this.setState({calendarItems});
  }
  getDocForTask(task) {
    const {id, source} = task;
    switch (source) {
      case 'objectives': return this.props.objHash[id].doc;
      case 'projects': if(this.state.projectTreeHash) return this.state.projectTreeHash[id]; break;
      case 'taskList': // legacy
      case 'todoList': if(this.props.taskHash) return this.props.taskHash[id]; break;
      default: return {
        name: '(unknown)'
      };
    }
    return {
      name: '(loading)'
    };
  }
  getDailyTask(iid) {
    const dailyTasks =  this.getDailyPlan().tasks;
    return dailyTasks.find(tid => getInstanceId(tid)===iid);
  }
  addTasktoCalendar(iid) {
    const {startTime, startAlarm} = this.state;
    const task = this.getDailyTask(iid);
    const iof = getInstanceOf(task);
    const eventInfo = this.props.getEventInfo(iof);
    const weeklyTask = this.getWeeklyTasks().find(wt => wt.id === iof);
    const {name} = this.getDocForTask(weeklyTask);
    const endTime = addMinutes(startTime, eventInfo.duration || 30);
    const addEvent = {
      id: iid,
      type: 'a',
      startTime,
      endTime,
      desc: name,
      reminder: startAlarm ? 5 : undefined
    }
    this.props.context.gapiWrapper.addEvent(addEvent).then(gevent => {
      if (gevent.error) {
        console.log(`GMail error: ${gevent.error.message}`)
        alert(`GMail error: ${gevent.error.message}`)
      }
      console.log('added event', gevent)
      const addEvents = (this.state.addEvents || []).slice();
      addEvents.push(addEvent);
      const newStart = dateToTimeStr(endTime, true);
      this.setState({
        addEvents,
        startTime: endTime,
        startAlarm: false,
      })
    });
  }
  handleTaskClick = (evt) => {
    const {id} = evt.target;
    this.addTasktoCalendar(id);
  }
  renderTaskLines() {
    const {startTime, startAlarm} = this.state;
    const ret = [];
    const dailyTasks =  this.getDailyPlan();
    const ci = this.getCalendarItems();
    dailyTasks.tasks.forEach(task => {
      const id = getInstanceId(task);
      const iof = getInstanceOf(task);
      const weeklyTask = this.getWeeklyTasks().find(wt => wt.id === iof);
      const {name} = this.getDocForTask(weeklyTask);
      const calItem = ci.find(item => item.id === id);
      const disabled = calItem ? 'disabled' : 'enabled';
      if (!calItem) {
        ret.push(
          <div key={id} id={task.id} className={`task-line ${disabled}`}>
            <Button buttonStyle="small-finger white" id={task.id} onClick={this.handleTaskClick}>+</Button>
            <span className='button-label'>
              {name}
            </span>
          </div>
        )
      }
    })
    return (
      <>
        <div className="section">Tasks to add to calendar</div>
        <div>
          Add at:
          &nbsp; <InputTime onChange={this.handleStartEdit} value={startTime} alignClock='right'/>
          <span className='bell'><CheckBox checkedImg='alarm-on.svg' uncheckedImg='alarm-off.svg' imgSize="25" value={startAlarm} onChange={this.handleAlarm}/></span>
        </div>
        {ret}
      </>
    );
  }
  getWeeklyTasks() {
    return this.props.weeklyPlan.tasks || [];
  }

  getDailyPlan(day = this.state.day) {
    const {weeklyPlan} = this.props;
    return getDailyPlan(weeklyPlan, day);
  }
  getWeeklyBlockItems(ret) {
    const {weeklyBlocking} = this.props;
    const {day} = this.state;
    const date = dayToDate(day);
    const dayOfWeek = (date.getDay() + 6) % 7;  // We have monday as zero based...
    (weeklyBlocking.blockList || []).forEach((block, ix) => {
      const days = getDaysFromDayList(block.days);
      if (days[dayOfWeek]) {
        // process this block.
        const start = new Date(date.getTime() + parseTime(block.start));
        const end = new Date(date.getTime() + parseTime(block.end));
        const desc = block.type==='s' ? 'Sleep' : (block.type==='w' ? 'Work' : block.desc);
        ret.push({
          id: `${ix}`,
          desc,
          start,
          end,
          blocking: true,
        })
      }
    })
    return ret;
  }
  getCalendarItems() {
    const ret = [];
    ret.forEach(item => item);
    // Add items from calendar itself
    const ci = this.state.calendarItems || [];
    const ciforeach = ci.forEach.bind(ci);
    ciforeach(function(calItem) {
      const {start, end, summary} = calItem;
      const id = calItemId(calItem);
      ret.push({
        id,
        start: new Date(start.dateTime),
        end: new Date(end.dateTime),
        desc: summary,
      })
    });

    // Add items from weekly weekBlocking
    this.getWeeklyBlockItems(ret);

    // Add in added events
    (this.state.addEvents || []).forEach(ai => {
      const {id, startTime: start, endTime: end, desc} = ai;
      ret.push({id, start, end, desc});
    })
    ret.sort((a,b) => (a.start<b.start ? -1 : (a.start>b.start) ? 1 : 0));
    return ret;
  }
  handleStartEdit = (value) => {
    this.setState({startTime: value, startAlarm: true});
  };
  handleAlarm = (evt) => {
    const {checked} = evt.target;
    console.log('====== handleAlarm', this.state.startAlarm, checked)
    this.setState({startAlarm: checked})
  };
  renderCalendar() {
    const items = [];
    const calendarItems = this.getCalendarItems();
    calendarItems.forEach(item => {
      const {id, desc, start, end, blocking} = item;
      const button = blocking ? null :
        <Button buttonStyle="small-finger white" id={`caloff-${id}`} onClick={this.onRemoveCal}>-</Button>;
      items.push(
        <div key={id} className='row calendar-item'>
          <div className='cell'>
            {button}
          </div>
          <div className='cell'>{desc}</div>
          <div className='cell'>{dateToTimeStr(start, true)}</div>
          <div className='cell'>{dateToTimeStr(end, true)}</div>
        </div>
      )
    })
    return (
      <div>
        <div className="section">calendar</div>
        {items}
      </div>
    )
  }

  render() {
    return (
      <div className='calendar-tasks'>
      <div>
        {this.renderCalendar()}
      </div>
      <div>
        {this.renderTaskLines()}
      </div>
      </div>
    )
  }
}

export default withContext(CalendarTasks);
