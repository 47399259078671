import React, {Component} from "react";
import LoginWidget from './LoginWidget';

class Login extends Component {
  render() {
    return (
      <div className='login page'>
        <LoginWidget />
      </div>
    )
  }
}

export default Login;
