import React, {Component} from 'react';
import formElement from '../util/formElement';
import './TimePicker.scss';

class TimePicker extends Component {
  render () {
    return (
      <div>tp</div>
    )
  }
}

export default formElement(TimePicker);
