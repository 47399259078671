import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, CheckBox, Form, Input, InputSizes} from '../../components';
import {getCompleted, getInstanceCount, findTask, getDailyPlan} from '../../util/taskHelper';
import {autoId} from '../../data/dataHelper';
import {registerProjectTreeListener} from '../../data/projectTree';
import WeeklyPlan from '../../data/store/weeklyPlan';
import Tasks from '../../data/store/tasks';
import {days, dayStrToArray} from '../../components/util/dayArray';
import AvailableTasks from '../weeklyPlanning/AvailableTasks';
import { getNewElem, getTaskCandidates } from '../weeklyPlanning/wpUtil';
import MoreInfoNeeded from '../weeklyPlanning/MoreInfoNeeded';
import InlineTodo from './InlineTodo';
import './DailyPlanning.scss';

class WeeklyTasks extends Component {
  static propTypes = {
    weeklyPlan: PropTypes.object.isRequired,
    wheelTree: PropTypes.object.isRequired,
    projects: PropTypes.array.isRequired,
    taskList: PropTypes.array.isRequired,
    day: PropTypes.number.isRequired,
    week: PropTypes.number.isRequired,
    objHash: PropTypes.object.isRequired,
    onUpdateWeeklyTasks: PropTypes.func.isRequired,
    onTasksAdded: PropTypes.func,
  }
  constructor(props) {
    super(props);
    this.state = {
      selected: {},
      editTimes: {},
      infoNeeded: [],
      inlineTodo: true,
      projectTree: undefined,
      projectTreeHash: undefined,
      taskList: undefined,
      taskHash: undefined
    }
    this.unregisterProjectTreeListener = registerProjectTreeListener((projectTree, projectTreeHash) => {
      this.setState({
        projectTree,
        projectTreeHash
      })
    })
  }
  componentDidMount() {
    const tasks = Tasks.getCollectionRef();
    if (this.unsubscribeTasks) this.unsubscribeTasks();
    this.unsubscribeTasks = tasks.onSnapshot((results) => {
      const taskList = [];
      const taskHash = {};
      results.forEach( docRef => {
        const doc = Object.assign({}, docRef.data());
        doc.id = docRef.id;
        taskList.push(doc);
        taskHash[doc.id] = doc;
      })
      this.setState({
        taskList,
        taskHash
      })
    })
  }
  componentWillUnmount() {
    if (this.unsubscribeTasks) this.unsubscribeTasks();
    if (this.unregisterProjectTreeListener) this.unregisterProjectTreeListener();
  }
  getTaskSelected(weeklyTask) {
    if(this.getDailyTasks().find(task => task.iof===weeklyTask.id)) {
      return null;  // means it is already selected.
    }
    let ret = this.state.selected[weeklyTask.id];
    const resolved = getCompleted(weeklyTask);
    if (ret === undefined) {
      const dayOfWeek = this.props.day - this.props.weeklyPlan.week;
      // This means it is being defaulted, so check to see if we have resolved
      if (weeklyTask.frequencyOn && weeklyTask.frequencyOn.length) {
        const dayArray = dayStrToArray(weeklyTask.frequencyOn);
        let countToDate = 0;
        for(let ix=0; ix<dayOfWeek && ix<days.length; ix++) {
          if (dayArray[days[ix]]) countToDate++;
        }
        if (countToDate===resolved) {
          return dayArray[days[dayOfWeek]];
        }
        return countToDate > resolved;  // true = Should have resolved more by now.
      } else {
        const count = getInstanceCount(weeklyTask);
        const remaining = count - resolved;
        const daysLeft = 7 - dayOfWeek;
        if (remaining && remaining / daysLeft >= .5) {
          // Knock one off...
          ret = true;
        }
      }
    }
    return ret;
  }
  handleClickTask = (evt) => {
    const {id, checked} = evt.target;
    let editTimes = undefined

    const selected = Object.assign({}, this.state.selected);
    selected[id] = checked;

    if (checked) {
      const task = findTask(this.props.weeklyPlan.tasks, id);
      const resolved = getCompleted(task);
      const count = getInstanceCount(task);
      const remaining = resolved > count ? 0 : count - resolved;
      const daysLeft = 7 - this.props.day + this.props.weeklyPlan.week;
      if (remaining > daysLeft) {
        // Not enough days left, so let them do more than one
        editTimes = {
          editTimes: {...this.state.editTimes}
        }
        editTimes.editTimes[id] = `${Math.floor( remaining / daysLeft + .5)}`;
      }
    }
    this.setState({
      ...editTimes,
      selected
    });
  };
  getDailyTasks(day = this.props.day) {
    return getDailyPlan(this.props.weeklyPlan, day).tasks || []
  }
  addTasks(ids) {
    const newElems = [];
    const infoNeeded = [...this.state.infoNeeded];
    let needInfo = false;
    ids.forEach(compoundId => {
      const [elemId, source] = compoundId.split('.');
      const newElem = this.getNewElem(elemId, source);
      if (newElem) {
        newElems.push(newElem);
      } else {
        // Not enough info for a task, has to go into the 'get more info' alrea.
        infoNeeded.push({
          id: elemId,
          source,
          durationUnit: 'mins',
          frequency: 1,
          frequencyUnit: 'week'
        });
        needInfo = true;
      }
    })
    if (needInfo) {
      this.setState({ infoNeeded });
    }
    this.addNewTasks(newElems);
  }
  handleAddWeeklyTask = (evt) => {
    const infoNeeded = [...this.state.infoNeeded];
    const { objHash, onUpdateWeeklyTasks, weeklyPlan: { tasks = [] } } = this.props;
    const { target } = evt;
    const { id: tid } = target;
    const [ id, source ] = tid.split('.');
    const newElem = getNewElem(objHash, id, source);
    if (newElem) {
      const newTasks = [...tasks, newElem];
      onUpdateWeeklyTasks && onUpdateWeeklyTasks(newTasks);
    } else {
      // Not enough info for a task, has to go into the 'get more info' alrea.
      infoNeeded.push({
        id,
        source,
        durationUnit: 'mins',
        frequency: 1,
        frequencyUnit: 'week'
      });
      this.setState({ infoNeeded });
    }
    console.log('handleAddWeeklyTask', id);
  }
  handleAddTaskToPlan = (id) => {
    // Let's fake clicking the given task
    if (id) {
      const evt = { target: { id, checked: true } };
      this.handleClickTask(evt);
    }
  }
  renderFullTaskList() {
    const { weeklyPlan, wheelTree, projects, taskList } = this.props;
    const { infoNeeded=[] } = this.state;
    const { tasks } = weeklyPlan;
    const taskCandidates = getTaskCandidates(tasks, wheelTree, projects, taskList, infoNeeded);

    return (
      <>
        <div className='link show-more' onClick={this.handleToggleShowMore}>show less...</div>
        <InlineTodo addTaskToPlan={this.handleAddTaskToPlan}/>
        <AvailableTasks taskCandidates={taskCandidates} onAddTask={this.handleAddWeeklyTask} />
      </>
    )
  }
  handleEditTimes = (evt) => {
    const {id, value} = evt.target;
    if (value === "0") {
      const selected = {...this.state.selected};
      selected[id] = false;
      this.setState({selected})
    } else {
      const editTimes = {...this.state.editTimes};
      editTimes[id] = value;
      this.setState({editTimes})
    }
  }
  getDocForTask(task) {
    const {id, source} = task;
    switch (source) {
      case 'objectives': return this.props.objHash[id].doc;
      case 'projects': if(this.state.projectTreeHash) return this.state.projectTreeHash[id]; break;
      case 'taskList': // legacy
      case 'todoList': if(this.state.taskHash) return this.state.taskHash[id]; break;
      default: return {
        name: '(unknown)'
      };
    }
    return {
      name: '(loading)'
    };
  }
  renderWeeklyTaskLines() {
    const ret = [];
    (this.props.weeklyPlan.tasks || []).forEach(task => {
      const {id} = task;
      const resolved = getCompleted(task);
      const count = getInstanceCount(task);
      const remaining = resolved > count ? 0 : count - resolved;
      const daysLeft = 7 - this.props.day + this.props.weeklyPlan.week;
      if (remaining) {
        // There is still at least one instance to work on
        const {name} = this.getDocForTask(task);
        let selected = this.getTaskSelected(task);
        let disabled;
        let editTimes;
        if (selected === null) {
          selected = true;
          disabled = true;
        }
        let multiples = '';
        if (remaining > 1) {
          multiples = ` (${remaining} remaining)`;
        }
        if (selected && remaining > daysLeft) {
          // This means there are not enough days to handle all the tasks, so let them
          // add more than one per day.
          editTimes = this.state.editTimes[id];
          multiples = '';
        }
        ret.push(
          <div key={task.id}>
            <div className="cell">
              <CheckBox id={id} value={selected} disabled={disabled} onChange={this.handleClickTask} alignSet={true}/>
            </div>
            <div className="cell check-label">
              <label htmlFor={id}>{name+multiples}</label>
            </div>
            {editTimes!==undefined ?
              <div className="cell">
                &nbsp;(<Input id={id} size={InputSizes.SMALL1} onChange={this.handleEditTimes} value={editTimes} />
                &nbsp;of {remaining} times)
              </div>
            :
              null
            }
          </div>
        )
      }
    })
    return ret;
  }
  handleAddTasks = (evt) => {
    const {day, onTasksAdded} = this.props;
    const newTasks = this.getDailyTasks(day).slice();
    (this.props.weeklyPlan.tasks || []).forEach(task => {
      let selected = this.getTaskSelected(task);
      if (selected) {
        let editTimes = parseInt(this.state.editTimes[task.id]) || 1;
        for( let ix=0; ix<editTimes; ix++) {
          const dailyTask = {
            id: autoId(),
            iof: task.id,
          }
          newTasks.push(dailyTask);
        }
      }
    })
    const newDailyPlan = Object.assign({}, getDailyPlan(this.props.weeklyPlan, this.props.day));
    newDailyPlan.tasks = newTasks;
    const newWeeklyPlan = Object.assign({}, this.props.weeklyPlan);
    newWeeklyPlan.days[day] = newDailyPlan
    WeeklyPlan.putDocument(this.props.week, newWeeklyPlan);
    onTasksAdded && onTasksAdded();
  };
  handleToggleShowMore = () => {
    const showAllTasks = !this.state.showAllTasks;
    this.setState({
      showAllTasks,
    })
  }
  handleRemoveMoreInfo = (evt) => {
    const { id } = evt.target;
    const infoNeeded = [...this.state.infoNeeded];
    const ix = infoNeeded.findIndex(obj => obj.id === id);
    if (ix >= 0) {
      infoNeeded.splice(ix, 1);
      this.setState({ infoNeeded })
    }
  }
  addNewTasks(newTasks) {
    const { onUpdateWeeklyTasks, weeklyPlan: { tasks = [] } } = this.props;
    if (newTasks.length > 0) {
      onUpdateWeeklyTasks(tasks.concat(newTasks));
    }
  }
  updateMoreInfo = (infoNeeded, newTasks = []) => {
    this.setState({ infoNeeded })
    this.addNewTasks(newTasks);
  }
  handleAddMoreInfo = () => {
    // Clean up numbers.
    const infoNeeded = [];
    const tasks = [];
    this.state.infoNeeded.forEach(element => {
      const { id, source, frequencyUnit, } = element;
      const frequency = element.frequencyUnit === 'day' ? 1 : parseInt(element.frequency);
      const duration = parseInt(element.duration || '0');
      const durationUnit = duration ? element.durationUnit : 'none';
      const frequencyOn = '';
      const count = frequency * (frequencyUnit === 'week' ? 1 : 7);
      if (frequency) {
        const newTask = {
          id,
          source,
          count,
          occurances: [],
          duration,
          durationUnit,
          frequencyOn
        }
        tasks.push(newTask)
      } else {
        infoNeeded.push(element);
      }
    })
    this.updateMoreInfo(infoNeeded, tasks);
  }
  renderWeeklyTasks() {
    const { weeklyPlan, wheelTree, projects, taskList: todoList } = this.props;
    const { infoNeeded } = this.state;
    const { tasks } = weeklyPlan;
    const taskCandidates = getTaskCandidates(tasks, wheelTree, projects, todoList, infoNeeded)
    let getAPlanWarning = null;
    if (!this.props.weeklyPlan.weekBlocking) {
      getAPlanWarning = (
        <div>Before daily planning, create a <a href="/in/weekly">weekly plan</a></div>
      )
    }
    return (
      <>
        <MoreInfoNeeded
          weeklyPlan={weeklyPlan}
          taskCandidates={taskCandidates}
          infoNeeded={infoNeeded}
          onUpdateMoreInfo={this.updateMoreInfo}
          onAddMoreInfo={this.handleAddMoreInfo}
          onRemoveMoreInfo={this.handleRemoveMoreInfo}
        />
        <Form onSubmit={this.handleAddTasks}>
          {getAPlanWarning}
          {this.renderWeeklyTaskLines()}
          {this.state.showAllTasks ?
            this.renderFullTaskList()
            :
            <div className='link show-more' onClick={this.handleToggleShowMore}>show more...</div>
          }
          <div key='_add'><Button id='_next' buttonStyle="medium">Next</Button></div>
        </Form>
      </>
    )
  }
  render() {
    if (this.props.weeklyPlan._waitingForData || !this.props.objHash) {
      return <div>loading...</div>
    }
    return (
      <div className='dp-weekly-tasks'>
        <div className='section'>Choose tasks for today</div>
        {this.renderWeeklyTasks()}
      </div>
    )
  }
}

export default WeeklyTasks;
