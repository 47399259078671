import React, {Component} from 'react';
import {dateToTimeMillis, MILLIS_IN_DAY, dayToDate, dateToDay} from '../../util/dateHelper';
import { Button } from '../';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {ReactComponent as ClockSvg} from './clock.svg';

const MILLIS_IN_HOUR = MILLIS_IN_DAY/24;
const CLOCK_RADIUS = 70;

const StyledClock = styled.div`
  svg {
    pointer-events: none;
  }
  #HoursHand {
    transform: translate(50px,50px) rotate(${props => props.hrsHRot}deg);
  }
  #HoursKnob {
    transform: translate(50px,50px) rotate(${props => props.hrsRot}deg);
  }
  #Minutes {
    transform: translate(50px,50px) rotate(${props => props.minsRot}deg);
  }
`;

class InputClock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gotHours: false,
      gotMinutes: false,
      hours: 0,
      minutes: 0,
    }
  }
  getIsPm() {
    const hours = this.getHours();
    return hours>=12;
  }
  getHours() {
    const {value} = this.props;
    const millis = dateToTimeMillis(value);
    return Math.floor(millis / MILLIS_IN_HOUR+.00001);
  }
  getMinutes() {
    const {value} = this.props;
    const millis = dateToTimeMillis(value);
    const hours = this.getHours();
    const minutes = Math.floor(millis / 1000 / 60 + .00001);
    return minutes - hours * 60;
  }
  handleFocus = (evt) => {
    this.props.onFocus(evt);
  }
  handleClick = (evt) => {
    const {gotHours: stateGotHours, gotMinutes: stateGotMinutes} = this.state;
    const amPm = this.getIsPm() ? 12 : 0;
    const stateHours = this.getHours();
    const stateMinutes = this.getMinutes();
    let hours = stateHours;
    let minutes = stateMinutes;
    const rect = evt.target.getBoundingClientRect();
    const x = evt.clientX - (rect.right + rect.left)/2;
    const y = evt.clientY - (rect.top + rect.bottom)/2;
    const d = Math.sqrt(x*x+y*y);
    var theta = Math.atan2(y, x) * 180 / Math.PI;
    const INNER_RADIUS = .4 * CLOCK_RADIUS;
    const MID_RADIUS = .64 * CLOCK_RADIUS;
    const OUTER_RADIUS = .92 * CLOCK_RADIUS
    const clickedHours = d>INNER_RADIUS && d<MID_RADIUS;
    const clickedMinutes = d>MID_RADIUS && d<OUTER_RADIUS;

    let gotMinutes = stateGotMinutes || clickedMinutes;
    let gotHours = stateGotHours || clickedHours || clickedMinutes;
    hours = clickedHours ? Math.floor((theta / 360 * 12) + .5) + 3 + amPm : hours;
    hours += hours < 0 ? 12 : 0;
    minutes = clickedMinutes ? Math.floor((theta / 360 * 60 / 5) + .5)*5 + 15: minutes;
    minutes += minutes < 0 ? 60 : 0;
    this.setState({
      gotHours,
      gotMinutes,
      hours,
      minutes
    })
    const {id, onChange, value} = this.props;
    if (onChange) {
      onChange({
        target: {
          id,
          value: `${hours}:${minutes}`,
        }
      })
    }
  }
  handleAmPm = (toPm) => {
    const {id, onChange} = this.props;
    let hours = this.getHours();
    let minutes = this.getMinutes();
    const isPm = this.getIsPm();
    if (isPm) {
      if (!toPm) {
        hours -= 12;
      }
    } else {
      if (toPm) {
        hours += 12;
      }
    }
    onChange && onChange({
      target: {
        id,
        value: `${hours}:${minutes}`,
      }
    })
  }
  handleAmPmToggle = () => {
    // const {id, onChange} = this.props;
    // let hours = this.getHours();
    // let minutes = this.getMinutes();
    // const isPm = this.getIsPm();
    // hours += isPm ? -12 : 12;
    // onChange && onChange({
    //   target: {
    //     id,
    //     value: `${hours}:${minutes}`,
    //   }
    // })
    this.handleAmPm(!this.isPm());
  }
  handleAm = () => {
    this.handleAmPm(false);
  }
  handlePm = () => {
    this.handleAmPm(true);
  }
  render () {
    const {value, align, onFocus, onBlur,onClick,...otherProps} = this.props;
    let hours = this.getHours();
    const clockAlign = align==='right' ? 'right' : 'left';
    const style = {
      [clockAlign]: '0px',
    }
    const minsRot = (this.getMinutes() || 0) * 360 / 60;
    const hrsRot = (this.getHours() || 0) * 360 / 12;
    const hrsHRot = hrsRot + minsRot / 12;
    const amClass = hours<12 ? 'blue' : 'white';
    const pmClass = hours>=12 ? 'blue' : 'white';
    return (
      <div {...otherProps} className='input-clock' style={style} onFocus={this.handleFocus} onBlur={onBlur}>
        <StyledClock hrsRot={hrsRot} hrsHRot={hrsHRot} minsRot={minsRot} onClick={this.handleClick}>
          <ClockSvg/>
        </StyledClock>
        <div>
        <Button buttonStyle={`smallest ${amClass}`} onClick={this.handleAm}>AM</Button>
        <Button buttonStyle={`smallest ${pmClass}`} onClick={this.handlePm}>PM</Button>
        </div>
      </div>
    )
  }
}

export default InputClock;
