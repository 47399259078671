import React, {Component} from 'react';
import './Button.scss';
import PropTypes from 'prop-types';
import formElement from '../util/formElement';

class Button extends Component {
  static propTypes = {
    onClick: PropTypes.func,       // Must supply either onClick or formContext
    formContext: PropTypes.object, // Must supply either onClick or formContext
    buttonStyle: PropTypes.string
  }
  handleClick = (evt) => {
    if (!this.props.onClick) {
      // No on-click, treat as a submit
      if (this.props.formContext) {
        this.props.formContext.submit();
      }
    } else {
      this.props.onClick(evt);
    }
  }
  render () {
    const props = {
      className: 'button',
      id: this.props.id
    };
    if (this.props.buttonStyle) {
      props.className += ` ${this.props.buttonStyle}`;
    }
    return (
      <div onClick={this.handleClick} {...props}>
        <div className="vertical-center no-click">
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default formElement(Button);
