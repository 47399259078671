import React from 'react';
import {Button} from '../index';
import {formatDay, getToday} from '../../util/dateHelper';
import PropTypes from 'prop-types';
import './DayButtons.scss';

const propTypes = {
  day: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string,
}

export default function DayButtons(props) {
  const {onChange, title, day = getToday()} = props;

  const handleIncDay = () => {
    if (onChange) {
      onChange(day+1);
    }
  }
  const handleDecDay = () => {
    if (onChange) {
      onChange(day-1);
    }
  }
  const getTitle = () => {
    const dayStr = formatDay(day);
    if (!title) {
      return dayStr;
    }
    const parts = title.split('$');
    if (parts.length>1) {
      return parts.join(dayStr);
    }
    return title;
  }

  return (
    <div className='day-buttons'>
        {getTitle()}
        <Button buttonStyle='txt' onClick={handleIncDay}>+</Button>
        <Button buttonStyle='txt' onClick={handleDecDay}>-</Button>
    </div>
  )
}

DayButtons.propTypes = propTypes;
