import React, {Component} from 'react';
import './YearlyPlanning.scss';

class YearlyPlanning extends Component {
  render() {
    return (
      <div>
        <div>YearlyPlanning</div>
        <ul>
          <li>Review past year (summary of year)</li>
          <li>Assess values and objectives</li>
          <li>set goals for coming year</li>
        </ul>
      </div>
    )
  }
}

export default YearlyPlanning;
