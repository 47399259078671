import React, {Component,} from 'react';
import {Button, WeekBlocker} from '../../components';
import {formatDay} from '../../util/dateHelper';
import PropTypes from 'prop-types';
import './WeeklyPlanning.scss';

class WeeklyBlocking extends Component {
  static propTypes = {
    week: PropTypes.number.isRequired,
    data: PropTypes.object.isRequired,
    handleConfirm: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    showConfirm: PropTypes.bool
  }
  constructor(props) {
    super(props);
    this.state = {
      editState: 'read'
    }
  }
  getEditState() {
    if (!this.props.data || this.props.data._waitingForData) {
      return 'loading'
    }
    return this.state.editState;
  }
  handleEditWb = () => {
    if (this.state.editState === 'edit') {
      // Cancel case
      this.setState({
        editState: 'read'
      })
    } else {
      this.setState({
        editState: 'edit'
      })
    }
  }
  handleConfirm = () => {
    this.setState({
      editState: 'read'
    })
    this.props.handleConfirm();
  }
  renderWeekBlocker() {
    if (this.getEditState() === 'loading') {
      return <div>loading...</div>
    }
    return (
      <div className='weekly-blocking'>
        <div className='section'>1. Confirm schedule for the week starting {formatDay(this.props.week)}</div>
        <WeekBlocker data={this.props.data} onChange={this.props.onChange} editState={this.state.editState}/>
        {this.getEditState() === 'edit' ?
          <Button buttonStyle="medium" onClick={this.handleEditWb}>Cancel</Button>
        : (this.props.showConfirm ?
          <Button buttonStyle="medium" onClick={this.handleEditWb}>Edit</Button>
        :
          <div className="link" onClick={this.handleEditWb}>Edit</div>
        )
        }
        {this.props.showConfirm ?
          <Button buttonStyle="medium" onClick={this.handleConfirm}>Confirm</Button>
        : (this.getEditState() === 'edit' ?
            <Button buttonStyle="medium" onClick={this.handleConfirm}>Done</Button>
          :
            null
          )
        }
      </div>
    )
  }
  render() {
    return (
      <div className='weekly-blocking'>
        {this.renderWeekBlocker()}
      </div>
    )
  }
}

export default WeeklyBlocking;
