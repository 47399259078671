import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {getKeyUniqueToSession} from '../../util/utils';
import formElement from '../util/formElement';
import './CheckBox.scss';

const DEF_IMG_SIZE = 24;

class CheckBox extends Component {
  static propTypes = {
    id: PropTypes.string,
    value: PropTypes.bool,
    label: PropTypes.string,
    onChange: PropTypes.func,
    isRequired: PropTypes.bool,
    formContext: PropTypes.object, // Provided automatically via formElement import.
    size: PropTypes.string,                    // One of InputSizes above; default Medium
    checkedImg: PropTypes.string,
    uncheckedImg: PropTypes.string,
    goNative: PropTypes.bool,
  }

  constructor(props) {
    super(props);
    let {id} = this.props;
    const {
      goNative,
      onClick,
      imgSize,
      label,
      checkedImg = !goNative && 'checkbox-on.svg',
      uncheckedImg = !goNative && 'checkbox-off.svg',
      value
    } = this.props;
    const showImageBox = ((value && checkedImg) || (!value && uncheckedImg));

    if (!id && (checkedImg || uncheckedImg || label)) {
      id = getKeyUniqueToSession();
    }
    this.state = {
      id,
      checkedImg,
      uncheckedImg,
      showImageBox
    }
  }

  handleChange = (evt) => {
    const value = evt.target.checked;
    this.checkValidity(value);
    if (this.props.onChange) {
      this.props.onChange(evt);
    }
  }

  checkValidity(value) {
    if (!this.props.formContext) {
      return;
    }
    this.error = null;
    if (this.props.checkValidity) {
      this.error = this.props.checkValidity(value);
    }
    if ((!this.error) && this.props.isRequired && !value) {
      this.error = 'required';
    }
    this.props.formContext.validity.setValid(this.props.id, this.error);
  }

  getError() {
    if (!this.props.formContext) {
      return undefined;
    }
    const id = this.props.id;
      this.checkValidity(this.props.value);
    return this.props.formContext.validity.getError(id);
  }

  handleClick = (evt) => {
    console.log('======= checkbox click')
    const { onClick, value } = this.props;
    if (onClick) {
      onClick(evt);
    }
  }

  render() {
    let label = null;
    const value = Boolean(this.props.value);
    const {id, checkedImg, uncheckedImg, showImageBox} = this.state;
    const {goNative, onClick, imgSize} = this.props;
    const size = (imgSize || !goNative) ? {width: imgSize || DEF_IMG_SIZE, height: imgSize || DEF_IMG_SIZE} : undefined;
    const error = this.getError();
    const showError = (Boolean(this.props.value) || (this.props.formContext && this.props.formContext.showInvalid)) && error;
    let image = null;
    if (showImageBox) {
      if (value) {
        image = <img src={`/images/${checkedImg}`} onClick={onClick || this.handleClickLabel} {...size}/>
      } else {
        image = <img src={`/images/${uncheckedImg}`} onClick={onClick || this.handleClickLabel} {...size}/>
      }
    }
    if (image || this.props.label) {
      if (!id) throw new Error('must supply id if using labels');
      const errorMessage = showError ? ` (${this.getError()})` : '';
      label = (
        <label htmlFor={id} onClick={this.handleClick || this.handleClickLabel}>
          {image ?
            <><span>{image}</span><span className='imgtext'>{this.props.label}{errorMessage}</span></>
          :
            this.props.label + errorMessage
          }
        </label>
      );
    }
    const errorClass = showError ? ' error' : '';
    const disabledClass = this.props.disabled ? 'disabled' : '';

    const props = {
      id,
      onChange:this.handleChange,
      checked: value,
      onBlur: this.props.onBlur,
      onFocus: this.props.onFocus,
      disabled: this.props.disabled,
      onClick,
    }
    if(showImageBox) {
      props.style = {display:'none'};
      props.onClick = undefined;
    }

    return (
      <span className={`checkbox${errorClass} ${disabledClass} ${this.props.size || ''}`} style={this.props.style}>
        <input type='checkbox' {...props} />
        {label}
      </span>
    )
  }
}

export default formElement(CheckBox);
