import React from 'react';
import {Button} from '../';
import './DayPicker.scss';
import {dayArrayToStr,dayStrToArray} from '../util/dayArray';

export default function DayPicker(props) {
  const {onChange, value=''} = props;
  const dayArray = dayStrToArray(value);
  const handleClick = (evt) => {
    const {id} = evt.target;
    dayArray[id] = !dayArray[id];
    if (onChange) {
      onChange({
        target: {
          id: props.id,
          value: dayArrayToStr(dayArray)
        }
      });
    }
  };
  function TinyButton(props) {
    const {id=''} = props;
    const label = id.charAt(0);
    const style = dayArray[id] ? 'tiny grey' : 'tiny white';
    return <Button buttonStyle={style} id={id} onClick={handleClick}>{label}</Button>
  }
  return (
    <div className='day-picker'>
      <TinyButton id='Mo' />
      <TinyButton id='Tu' />
      <TinyButton id='We' />
      <TinyButton id='Th' />
      <TinyButton id='Fr' />
      <TinyButton id='Sa' />
      <TinyButton id='Su' />
      {value}
    </div>
  )
}
