import React, {Component} from 'react';

// currently only dropdown is really registering (and only because there is a superfluous isRequired tracker
// in dropdown).  instead, all components should register and all components should unregister (at least if
// they are required).  Need to add a new numeric validity tracker or something and then remove required from
// dropdown. then make sure all the plumbing works.
class ValidityTracker {
  constructor() {
    this.validity = {};
    this.gvfunc = {};
  }
  setValid(name, isValid) {
    this.validity[name] = isValid;
  }
  registerGetValidityError(name, f) {
    this.gvfunc[name] = f;
  }
  unregisterGetValidityError(name, f) {
    delete this.gvfunc[name];
    delete this.validity[name];
  }
  isValid() {
    let valid = true;
    Object.keys(this.validity).forEach((key) => {
      if (this.validity[key]) {
        console.log(`Warning: Field ${key} is invalid`);
        valid = false;
      }
    })
    if (valid) {
      Object.keys(this.gvfunc).forEach((key) => {
        if (this.gvfunc[key] && this.gvfunc[key]()) {
          valid = false;
        }
      })
    }
    return valid;
  }
  getError(name) {
    return this.validity[name] || (this.gvfunc[name] && this.gvfunc[name]());
  }
}

export const FormContext = React.createContext();

class Form extends Component {
  constructor() {
    super();
    this.validity = new ValidityTracker();
    this.state = {
      showInvalid: false
    }
  }
  handleSubmit = () => {
    let formValidate = this.props.formValidate ? this.props.formValidate() : null;
    if (formValidate) {
      Object.keys(formValidate).forEach(v => {
        // This is a little broken.  No guarantee they fix form problem by changing v...
        this.validity.setValid(v, formValidate[v]);
      })
    }
    if (this.validity.isValid()) {
      if (this.props.onSubmit) {
        this.props.onSubmit();
      }
    } else {
      this.setState({
        showInvalid: true
      })
    }
  }
  render() {
    return (
      <FormContext.Provider value={{
        showInvalid: this.state.showInvalid,
        validity: this.validity,
        submit: this.handleSubmit
      }}>
        <div className='form'>
          {this.props.children}
        </div>
      </FormContext.Provider>
    )
  }
}

export default Form;
