export const FREQUENCY = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  QUARTER: 'quarter',
  YEAR: 'year'
}
export const ROUTES = {
  LANDING: "/",
  HOME: "/in",
  SIGN_IN:   "/in",
  VALUE: "/in/value",
  VALUES: "/in/values",
  OBJECTIVE: "/in/objective",
  OBJECTIVES: "/in/objectives",
  PROJECT: "/in/project",
  PROJECTS: "/in/projects",
  TODO: "/in/todo",
  TODOS: "/in/todos",
  SETTINGS: "/in/settings",
  CALENDAR: "/in/calendar",
  APPOINTMENT: "/in/appointment",
  NOTE: "/in/note",
  NOTES: "/in/notes",
  DAILYDOS: "/in/dailydos",
  DAILY: "/in/daily",
  WEEKLY: "/in/weekly",
  MONTHLY: "/in/monthly",
  YEARLY: "/in/yearly",
  FORGOT_PASSWORD: "/pwreset",
  PEOPLE: "/in/people",
  PERSON: "/in/person",
  EVENT: "/in/event"
}
