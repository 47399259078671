import React, {Component} from "react";
import {Form, DropDown, Input, Button} from '../../components';
import {InputSizes} from '../../components/input/Input';
import {getURLParameter} from '../../util/urlHelper';
import Goal from './Goal';
import ObjectivePlanning from './ObjectivePlanning';
import {ObjectiveTypeDropDown, ObjectiveDropDown /*, CommonTaskTypes*/} from '../../components';
import {CommonTaskTypes} from '../../data/store/commonTaskTemplate';
import commonTaskConfig from './CommonTaskConfig';
import PropTypes from 'prop-types';

class CommonTaskObject extends Component {
  static propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
    defaultType: PropTypes.string.isRequired,  // CommonTaskTypes.ACCOMPLISHMENT
    store: PropTypes.object.isRequired,  // Objectives
    returnRoute: PropTypes.string.isRequired // ROUTES.OBJECTIVES
  }
  constructor(props) {
    super(props);

    const {id} = this.props.match.params;
    this.state = {
      objectivesLoaded: !id,
      error: "",
      doc: this.getDocWithDefaults({
        parent: getURLParameter('p'),
        objType: this.props.defaultType
      })
    }

    if (Boolean(id)) {
      this.props.store.getDocument(id).then(docRef => {
        const doc = this.getDocWithDefaults(docRef.data());
        this.setState({
          objectivesLoaded: true,
          doc
        })
      })
    }
  }

  updateDoc(update) {
    const docCopy = {...this.state.doc}
    Object.assign(docCopy, update);
    this.setState({
      doc: docCopy,
      error: null
    })
  }

  handleChange = (evt) => {
    return this.updateDoc({[evt.target.id]: evt.target.value});
  }

  handleSubmit = () => {
    const {id} = this.props.match.params;
    // Save it, and return it as a promise
    const doc = this.state.doc;
    return this.props.store.putDocument(id, doc).then(docRef => {
      this.props.history.push(this.props.returnRoute)
    }).catch(error => {
      this.setState({error:error.message})
    })
  }

  handleDelete = (evt) => {
    const {id} = this.props.match.params;
    this.props.store.removeDocument(id);
    this.props.history.push(this.props.returnRoute);
  }

  handleCancel = (evt) => {
    this.props.history.push(this.props.returnRoute);
  }

  getDocWithDefaults(doc) {
    const config = commonTaskConfig(doc.objType);
    if (!doc.parent && config.allowOrphan) {
      doc.parent = 'none';
    }
    return doc;
  }
  ensureDefaultUpdates = (updateDoc) => {
    const {name, objType, parent, status='active' /* , desc, goal, plan*/} = this.state.doc;
    const config = commonTaskConfig(objType);
    if (config.showParent && !parent) {

    }
    if (config.showType && !objType) {

    }
    if (config.showName && name === undefined) {
      // updateDoc.name = "";
    }
    if (!status) {
      // updateDoc.status =
    }
    if (config.showGoal) {

    }
    if (config.showPlan) {

    }

  }
  convertToTask = () => {
    const docUpdate = {
      objType: CommonTaskTypes.TODO
    }
    if (this.state.doc.parent === 'none') {
      docUpdate.parent = null;
    }
    return this.updateDoc(docUpdate);
  }
  convertToObjective = () => {
    const docUpdate = {
      objType: CommonTaskTypes.ACCOMPLISHMENT
    }
    if (this.state.doc.parent === 'none') {
      docUpdate.parent = null;
    }
    return this.updateDoc(docUpdate);
  }
  render() {
    const {name,desc, objType, parent, goal, plan, status} = this.state.doc;
    let statusValue;
    const {id} = this.props.match.params;
    const config = commonTaskConfig(objType);
    const statusChoices = [
      {label:'Active', value:'active'},
      {label:'Inactive', value:'inactive'}
    ];
    if (config.showUnprocessedStatus) {
      statusChoices.push({label:'Unprocessed', value: 'unprocessed'});
      statusValue = status || 'unprocessed';
    } else {
      statusValue = status || 'active';
    }
    return (
      <div className="common-task-object">
        <Form onSubmit={this.handleSubmit}>
          <div className='submit-err'>
            {this.state.error}
          </div>
          {config.showParent ?
            <div className='form-elem'>
              <ObjectiveDropDown
                id='parent'
                label='Parent Objective'
                onChange={this.handleChange}
                value={parent}
                allowNone={config.allowOrphan}
                isRequired={true}
              />
            </div>
          :
            null
          }
          {config.showType ?
            <div className='form-elem'>
              <ObjectiveTypeDropDown
              id='objType'
              label='Objective Type'
              onChange={this.handleChange}
              value={objType}
              types={config.types}
              isRequired={true}
            />
            </div>
          :
            null
          }
          {config.showName ?
            <div className='form-elem'>
              <Input
                id='name'
                label='Name'
                value={name}
                isRequired={true}
                onChange={this.handleChange}
              />
            </div>
          :
            null
          }
          <div className='form-elem'>
            <Input
              id='desc'
              label='Description'
              value={desc}
              size={InputSizes.LARGE}
              onChange={this.handleChange}
            />
          </div>
          <div className='form-elem'>
            <DropDown
              id='status'
              label='Status'
              value={statusValue}
              choices={statusChoices}
              onChange={this.handleChange}
            />
          </div>
          {config.showGoal ?
            <Goal id="goal" type={objType} value={goal} onChange={this.handleChange} isRequired={true}/>
          :
            null
          }
          {config.showPlan ?
            <ObjectivePlanning type={objType} value={plan} onChange={this.handleChange} />
          :
            null
          }
          {config.showConvert ?
          <div className='form-elem'>
            <div className="lone-label">Convert to...</div>
            <Button buttonStyle="small minor" onClick={this.convertToTask}>Todo</Button>
            <Button buttonStyle="small minor" onClick={this.convertToObjective}>Objective</Button>
          </div>
          :
            null
          }

          <div className='form-elem'>
            {Boolean(id) ?
              <Button onClick={this.handleDelete}>Delete</Button>
            :
              null
            }
            <Button onClick={this.handleCancel}>Cancel</Button>
            <Button>Save</Button>
          </div>
        </Form>
      </div>
    )
  }
}

export default CommonTaskObject;
