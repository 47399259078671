import React from 'react';
import CheckBox from '../checkBox/CheckBox';
import {Button} from '../index';
import './CheckSet.scss';

class CheckSet extends React.Component {
  handleClick = (evt) => {
    const {onChange} = this.props;
    const {id} = evt.target;
    const parts = id.split('.')
    onChange && onChange({
      target: {
        id: parts[0],
        value: parts[1],
      }
    })
  }
  renderChecks() {
    const ret=[];
    const {id, useImages, value, styled, useButtons} = this.props;
    let {idSet} = this.props;
    if (!idSet && useButtons) {
      idSet = useButtons;
    }
    idSet.forEach((sid, ix) => {
      const selProps = {};
      let type;
      if (useImages) {
        selProps.checkedImg = `${sid}.png`;
        selProps.uncheckedImg = `${sid}${useImages}.png`;
        type = "image";
      } else if (useButtons) {
        type = "buttons";
      } else {
        type = "checks";
      }
      if (styled) {
        selProps.style = styled;
      }

      if (type==="buttons") {
        const selected = value==sid ? 'grey' : 'white';
        const fullId = `${id}.${sid}`
        ret.push(<Button id={fullId} key={fullId} buttonStyle={`smedium ${selected}`} onClick={this.handleClick}>{useButtons[ix]}</Button>);
      } else {
        ret.push(<CheckBox key={sid} id={`${id}.${sid}`} value={value===sid} {...selProps} onChange={this.handleClick}/>);
      }
    })
    return ret;
  }
  render () {
    const {id, nowrap} = this.props;
    const noWrapClass = nowrap ? ' nowrap' : '';

    return (
      <div className={`check-set${noWrapClass}`}>
        {this.renderChecks()}
      </div>
    )
  }
}

export default CheckSet;
