import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

let db;
export function getDb() {
  if (!db) {
    db  = app.firestore();
  }
  return db;
};

let auth;
export function getAuth() {
  if (!auth) {
    auth = app.auth();
  }
  return auth;
}
export function resetAuth() {
  auth = undefined;
}

export function getUser() {
  return getAuth().currentUser;
}

export function getUserId() {
  const user = getUser();
  if (!user) {
    return undefined;
  }
  return user.uid;
}
