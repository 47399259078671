import React, {useState, useMemo} from 'react';
import GridBackground from './gridBackground';
import {dateToTimeDays} from '../../util/dateHelper';
import {withContext} from '../../util/context';
import Positioner from './Positioner';
import {columnator} from './columnator';
import styled from 'styled-components';
import './Calendar.scss';

const LINE_HEIGHT = 10;
const MARGIN_ALLOWANCE = 5;
const INITIAL_SCALE = 70; // scale is the height in pixels of one hour

const CalDiv = styled.div`
  position: absolute;
  z-index: 1000;
  top: ${props => props.rect.top}px;
  left: ${props => props.rect.left}px;
  width: ${props => props.rect.width}px;
  height: ${props => props.rect.height}px;
  font-size: ${LINE_HEIGHT}pt;
`;

const CalText = styled.div`
  height: 13px;
  overflow: hidden;
  background: white;
  width: ${props => props.width}px;
`;

const CalItem = (propsAndChildren) => {
  const {children, cursor, ...props} = propsAndChildren;
  const cursClass = cursor ? ' cursor' : '';
  const textWidth = props.rect.width - 10;
  return <CalDiv {...props}><div className={`cal-item${cursClass}`}><CalText width={textWidth}>{children}</CalText></div></CalDiv>
}

function Calendar(props) {
  const {items, day, onChangeDay, showHeading=true, cursor, posRef} = props;
  const [scale] = useState(INITIAL_SCALE);
  const positioner = useMemo(() => new Positioner({scale, showHeading}), [scale, showHeading]);
  if(posRef) posRef.pos = positioner;

  const [style] = useState('day');

  const renderItems = () => {
    const ret = [];
    const minMillis = (LINE_HEIGHT + MARGIN_ALLOWANCE) * 1000 * 60 * 60 / scale; 
    const columns = columnator(items, minMillis);
    items.forEach(item => {
      const {id, summary, start, end} = item;
      const startDate = new Date(start.dateTime);
      const endDate = new Date(end.dateTime);
      const startTime = dateToTimeDays(startDate);
      const endTime = dateToTimeDays(endDate);
      const day = 0;
      const rect = positioner.getRect(startTime*24, day, endTime*24, day, columns[id]);
      ret.push(
        <CalItem key={id} rect={rect}>{summary}</CalItem>
      )
    })
    if (cursor) {
      const rect = positioner.snap(cursor);
      console.log('-===== cal.rend', cursor, rect, scale);
      ret.push(<CalItem key='cursor' rect={rect} cursor={true}></CalItem>);
    }
    return (
      <div className='cal-items'>
        {ret}
      </div>
    )
  }
  return (
    <div className='weekly-calendar'>
      <GridBackground style={style} day={day} showHeading={showHeading} onChangeDay={onChangeDay} positioner={positioner}/>
      {renderItems()}
    </div>
  )
};

export default withContext(Calendar);
