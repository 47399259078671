import {CommonTaskTypes, commonTaskTemplate} from './commonTaskTemplate';

export const allowedTaskTypes = [
    CommonTaskTypes.TODO,  // First one is default
]
export const listName = 'tasks';

const Tasks = commonTaskTemplate(listName, allowedTaskTypes);

export default Tasks;
