import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import { Button } from "../../components";
import PeopleData from "../../data/store/people";
import { registerWheelTreeListener } from '../../data/wheelTree';
import { ROUTES } from "../../util/constants";
import './people.scss';
import { findPeople } from "./peopleUtil";

class People extends Component {
  constructor(props) {
    super(props);
    this.loading = true;
    this.state = {
      peopleList: [],
    }
  }

  componentDidMount() {
    const tasks = PeopleData.getCollectionRef();
    if (this.unsubscribe) this.unsubscribe();
    this.unsubscribe = tasks.onSnapshot((results) => {
      this.loading = false;
      const peopleList = [];
      results.forEach(docRef => {
        const doc = Object.assign({}, docRef.data());
        doc.id = docRef.id;
        peopleList.push(doc);
      })
      this.setState({
        peopleList
      })
      const { wheelTreeHash } = this.state;
      this.checkForTaskMatches(peopleList, wheelTreeHash);
    })
    this.unregisterWheelTree = registerWheelTreeListener((wheelTree, wheelTreeHash) => {
      this.setState({ wheelTree, wheelTreeHash });
      const { peopleList } = this.state;
      this.checkForTaskMatches(peopleList, wheelTreeHash);
    })
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
    if (this.unregisterWheelTree) this.unregisterWheelTree();
  }

  checkForTaskMatches(peopleList, wheelTreeHash) {
    const taskMatches = findPeople(peopleList, wheelTreeHash);
    if (taskMatches) {
      this.setState({taskMatches});
    }
  }

  handlePersonClick = (evt) => {
    const { history } = this.props;
    const { id } = evt.target;
    history.push(`${ROUTES.PERSON}/${id}`);
  }

  displayPeople() {
    const { peopleList, taskMatches } = this.state;
    return peopleList.map( (person) => {
      const { id, name } = person;
      const goal = taskMatches && taskMatches[id] ? '(g)' : '';
      return <div className="person-line" id={id} key={id} onClick={this.handlePersonClick}>{name} {goal}</div>
    })
  }

  render() {
    if (this.loading) {
      return <div>loading...</div>
    }
    return (
      <div className="people">
        <div className="heading">People</div>
        {this.displayPeople()}
        <div className="buttons">
          <Link to={ROUTES.PERSON}><Button buttonStyle="small">Add</Button></Link>
        </div>
      </div>
    )
  }
}

export default withRouter(People);
