import React, {Component} from "react";
import {Form, Input, Button} from '../../components';
import {InputSizes} from '../../components/input/Input';
import {ROUTES} from '../../util/constants';
import Values from '../../data/store/values';

class Value extends Component {
  constructor(props) {
    super(props);

    const {id} = this.props.match.params;
    if (Boolean(id)) {
      Values.getDocument(id).then(docRef => {
        const doc = docRef.data();
        this.setState({
          loaded: true,
          name: doc.name,
          desc: doc.desc
        })
      })
    }
    this.state = {
      loaded: !id,
      error: "",
      name: "",
      desc: ""
    }
  }

  returnToCaller() {
    const query = new URLSearchParams(this.props.location.search);
    const retUrl = query.get('ret')==="obj" ? ROUTES.OBJECTIVES : ROUTES.VALUES;
    this.props.history.push(retUrl);
  }
  handleChange = (evt) => {
    this.setState({
      [evt.target.id]: evt.target.value,
      error: null
    })
  }

  handleSubmit = () => {
    const {id} = this.props.match.params;
    // Save it, and return it as a promise
    const doc = {
      desc: this.state.desc,
      name: this.state.name
    }
    return Values.putDocument(id, doc).then(docRef => {
      this.returnToCaller()
    }).catch(error => {
      this.setState({error:error.message})
    })
  }

  handleDelete = (evt) => {
    const {id} = this.props.match.params;
    Values.removeDocument(id);
    this.returnToCaller();
  }

  handleCancel = (evt) => {
    this.returnToCaller();
  }

  render() {
    const {name,desc} = this.state;
    const {id} = this.props.match.params;
    if (!this.state.loaded) {
      return <div className="edit-value" />
    }
    return (
      <div className="edit-value">
        <Form onSubmit={this.handleSubmit}>
          <div className='submit-err'>
            {this.state.error}
          </div>
          <div className='form-elem'>
            <Input
              id='name'
              label='Core Value'
              value={name}
              isRequired={true}
              onChange={this.handleChange}
            />
          </div>
          <div className='form-elem'>
            <Input
              id='desc'
              label='Description'
              value={desc}
              size={InputSizes.LARGE}
              onChange={this.handleChange}
            />
          </div>
          <div className='form-elem'>
            {Boolean(id) ?
              <Button onClick={this.handleDelete}>Delete</Button>
            :
              null
            }
            <Button onClick={this.handleCancel}>Cancel</Button>
            <Button>Save</Button>
          </div>
        </Form>
      </div>
    )
  }
}

export default Value;
