let key=new Date().getTime();
export function getKeyUniqueToSession() {
  return key++;
}

export function shallowEquals(obj1, obj2) {
  return Object.keys(obj1).length === Object.keys(obj2).length &&
    Object.keys(obj1).every(key =>
      obj2.hasOwnProperty(key) && obj1[key] === obj2[key]
    );
}

export function errorAlert(error) {
    console.log(error);
    const msg = (error && (error.details || error.message || error.error)) || '';
    alert(`error: ${msg}`);
}

