export function getURLParameter(sParam)
{
    const paramStr = window.location.search.substring(1);
    const params = paramStr.split('&');
    for( let ix = 0; ix<params.length; ix++) {
      const parts = params[ix].split('=');
      if( parts[0] === sParam) {
        if (parts.length>0) {
          return parts[1];
        } else {
          return true;
        }
      }
    }
}
