import {allowedTaskTypes as objectiveTypes, listName as OBJECTIVES} from '../../data/store/objectives';
import {allowedTaskTypes as taskTypes, listName as TASKS} from '../../data/store/tasks';
import {allowedTaskTypes as noteTypes, listName as NOTES} from '../../data/store/notes';

const masterConfig = {
  [OBJECTIVES]: {
    types: objectiveTypes,
    showName: true,
    showGoal: true,
    showPlan: true,
    showParent: true
  },
  [TASKS]: {
    types: taskTypes,
    showParent: true,
    showName: true,
    showGoal: true,
    showPlan: true,
    allowOrphan: true
  },
  [NOTES]: {
    types: noteTypes,
    showName: true,
    allowOrphan: true,
    showConvert: true,
    showUnprocessedStatus: true
  }
}

const config = {
  initialized: false
}

function initMasterConfig() {
  if (config.initialized) {
    return;
  }

  const initType = (name, typeList) => {
    const typeConfig = masterConfig[name];
    typeList.forEach(type => {
      config[type] = typeConfig
      if (config[type].showType === undefined && typeList.length > 1) {
        config[type].showType = true;
      }
    })
  }
  config.initialized = true;

  initType(OBJECTIVES, objectiveTypes);
  initType(TASKS, taskTypes);
  initType(NOTES, noteTypes);
}

function getCommonTaskConfig(type) {
  initMasterConfig();
  return config[type];
}

export default getCommonTaskConfig;
