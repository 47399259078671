import React, {Component} from 'react';
import {WeekBlocker} from '../../components';
import SettingStore, {WEEKBLOCK_ID} from '../../data/store/settings';
import './Settings.scss';

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultWeeklyTimes: {
        _waitingForData: true
      }
    }
    this.loadData();
  }
  componentWillUnmount() {
    if (this.unsubscribeWeekBlocks) this.unsubscribeWeekBlocks();
  }
  loadData() {
    const weekBlocks = SettingStore.getDocumentRef(WEEKBLOCK_ID);
    if (this.unsubscribeWeekBlocks) this.unsubscribeWeekBlocks();
    this.unsubscribeWeekBlocks = weekBlocks.onSnapshot( docRef => {
      const doc = Object.assign({}, docRef.data());
      this.setState({
        defaultWeeklyTimes: doc
      })
    })
  }
  handleDefaultWeeklyTimeChange = (newDefaultWeeklyTimeValue) => {
    const newSettings = Object.assign({}, this.state.defaultWeeklyTimes, newDefaultWeeklyTimeValue);
    SettingStore.putDocument(WEEKBLOCK_ID, newSettings);
  }
  render () {
    return (
      <div className='settings'>
        Settings
        <div>Typical weekly hours to schedule</div>
        <WeekBlocker data={this.state.defaultWeeklyTimes} onChange={this.handleDefaultWeeklyTimeChange}/>
      </div>
    )
  }
}

export default Settings;
