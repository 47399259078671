import React, {Component} from 'react';
import DropDown from '..//dropdown/DropDown';

export const StatusTypes = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  INPROGRESS: 'inProgress',
  DONE: 'done'
}
class ObjectiveTypeDropDown extends Component {
  get includeDone() {
    return (this.props.includeDone === undefined) || this.props.includeDone;
  }
  get includeInProgress() {
    return (this.props.includeInProgress === undefined) || this.props.includeInProress;
  }
  getChoices() {
    const ret = [
      {
        value: StatusTypes.ACTIVE,
        label: 'Active'
      },
      {
        value: StatusTypes.INACTIVE,
        label: 'Inactive'
      }
    ];
    if (this.includeInProgress) {
      ret.push({
        value: StatusTypes.INPROGRESS,
        label: 'In  Progress'
      });
    }
    if (this.includeDone) {
      ret.push({
        value: StatusTypes.DONE,
        label: 'Complete'
      });
    }
    return ret;
  }
  render() {
    const {id, value, label, isRequired, onChange, defaultValue} = this.props;
    const props = {
      id,
      value,
      label,
      isRequired,
      onChange,
      defaultValue
    }
    return (
        <DropDown
          {...props}
          choices={this.getChoices()}
          isRequired={true}
        />
    )
  }
}

export default ObjectiveTypeDropDown;
