const HOME = 1;
const WORK = 2;
export const PeriodCovered = {
  HOME: `${HOME}`,
  WORK: `${WORK}`,
  ALL: `${HOME & WORK}`
}
export const BlockType = {
  WORK: 'w',
  SLEEP: 's',
  OTHERINC: 'oi',
  OTHEREXC: 'oe'
}
export const IncEx = {
  INCLUSIVE: 'inc',
  EXCLUSIVE: 'ex'
}

export function getCoveredChoices() {
  return [{
    label: 'choose one',
    value: ''
  },{
    label: 'Work Hours',
    value: PeriodCovered.WORK
  },{
    label: 'Non-work Hours',
    value: PeriodCovered.HOME
  },{
    label: 'All Hours',
    value: PeriodCovered.ALL
  }];
};

let typeHash;
export function getTypeHash(incEx) {
  if (!typeHash) {
    typeHash = {};
    const choices = getTypeChoices(incEx);
    choices.forEach(choice => {
      typeHash[choice.value] =  choice.label;
    })
  }
  return typeHash;
}

export function getIncExChoices() {
  return [{
    value: IncEx.EXCLUSIVE,
    label: 'I want to specify which times of the day to ignore (work/sleep)'
  },{
    value: IncEx.INCLUSIVE,
    label: 'I want to specify times of the day to schedule'
  }];
};

export function getTypeChoices(incEx) {
  const otherStyle = (incEx === IncEx.EXCLUSIVE) ? BlockType.OTHEREXC : BlockType.OTHERINC;
  return [{
      label: 'Work',
      value: BlockType.WORK
  },{
      label: 'Sleep',
      value: BlockType.SLEEP
  },{
      label: 'Other',
      value: otherStyle
  }]
};
