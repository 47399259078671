import Tasks from './store/tasks';
import WeeklyPlan from './store/weeklyPlan';
import {registerWheelTreeListener} from './wheelTree';
import {registerProjectTreeListener} from './projectTree';
import {getToday, firstOfWeek} from '../util/dateHelper';

export function fetchTasks(unsubscribeTasksIn, setState) {
  const tasks = Tasks.getCollectionRef();
  if (unsubscribeTasksIn) unsubscribeTasksIn();
  const unsubscribeTasksOut = tasks.onSnapshot((results) => {
    const taskList = [];
    const taskHash = {};
    results.forEach( docRef => {
      const doc = Object.assign({}, docRef.data());
      doc.id = docRef.id;
      taskList.push(doc);
      taskHash[doc.id] = doc;
    })
    setState({
      taskList,
      taskHash
    })
  }, (error) => {
    console.log(error);
    alert('error', error);
  })
  return unsubscribeTasksOut;
}

export function fetchWeeklyPlan(week, unsubscribeWeeklyPlanIn, setState) {
  const weeklyPlanRef = WeeklyPlan.getCollectionRef();
  if (unsubscribeWeeklyPlanIn) unsubscribeWeeklyPlanIn();
  const unsubscribeWeeklyPlanOut = weeklyPlanRef.where('week', ">=", week-7).onSnapshot( queryRef => {
    let lastWeek = {},
        thisWeek = {},
        nextWeek = {};
    if (!queryRef.empty) {
      queryRef.docs.forEach(docRef => {
        const doc = docRef.data();
        if (doc.week === week) {
          thisWeek = doc;
        } else if (doc.week === week - 7) {
          lastWeek = doc;
        } else if (doc.week === week + 7) {
          nextWeek = doc;
        }
      })
    }
    setState({
      lastWeeklyPlan: lastWeek,
      weeklyPlan: thisWeek,
      nextWeeklyPlan: nextWeek,
    });
  }, (error) => {
    console.log(error);
    alert('error', error);
  });
  return unsubscribeWeeklyPlanOut;
}

function unsusbscribeAll(dataState) {
  if (dataState.unsubscribeTasks) dataState.unsubscribeTasks();
  dataState.unsubscribeTasks = undefined;
}

export default class DataHelper {
  constructor({onChange}) {
    this.seq = 0;
    this.day = -1;
    this.onChange = onChange;
    this.taskList = [];  // fetchTasks
    this.taskHash = {};  // fetchTasks
    this.wheelTreeHash = {};
    this.projectTreeHash = {};
    this.weeklyPlan = {};  // fetchWeeklyPlan
    this.items = [];
    this.unregisterWheelTreeListener = registerWheelTreeListener((_wheelTree, wheelTreeHash) => {
      this.setState({wheelTreeHash});
    })
    this.unregisterProjectTreeListener = registerProjectTreeListener((_projectTree, projectTreeHash) => {
      this.setState({projectTreeHash});
    })
  }
  callOnChange() {
    if (this.onChange) {
      this.seq++;
      this.onChange(this.seq);
    }
  }
  createItems() {
    const items = [];
    this.taskList.forEach(task => {
      items.push({id: task.id, name: task.name, type: 't'});
    })
    if (Object.keys(this.wheelTreeHash).length>0 && this.weeklyPlan.tasks) {
      this.weeklyPlan.tasks.forEach(t => {
        const task = this.wheelTreeHash[t.id];
        if (task) {
          items.push({id: task.id, name: task.doc.name, type: 'w', doc: task.doc})
        }
      })
    }
    if (Object.keys(this.projectTreeHash).length>0 && this.weeklyPlan.tasks) {
      this.weeklyPlan.tasks.forEach(t => {
        const task = this.projectTreeHash[t.id];
        if (task) {
          items.push({id: task.id, name: task.doc.name, type: 'p', doc: task.doc})
        }
      })
    }
    this.items = items;
  }
  setState = (data) => {
    Object.assign(this, data);
    this.createItems();
    this.callOnChange();
  }
  setDay(day) {
    if (this.day !== day) {
      if (this.unsubscribeTasks) this.unsubscribeTasks();
      this.unsubscribeTasks = fetchTasks(this.unsubscribeTasks, this.setState);
      this.day = day;
    }
    const week = firstOfWeek(day);
    if (this.week !== week) {
      if (this.unsubscribeWeeklyPlan) this.unsubscribeWeeklyPlan();
      this.unsubscribeWeeklyPlan = fetchWeeklyPlan(week, this.unsubscribeWeeklyPlan, this.setState);
      this.week = week;
    }
  }
  unsubscribeAll() {
    if (this.unsubscribeTasks) this.unsubscribeTasks();
    if (this.unsubscribeWeeklyPlan) this.unsubscribeWeeklyPlan();
    if (this.unregisterWheelTreeListener) this.unregisterWheelTreeListener();
    if (this.unregisterProjectTreeListener) this.unregisterProjectTreeListener();
  }
}

// https://github.com/invertase/react-native-firebase/blob/62715f894e559e75cf4761581938f04c16b48e74/src/utils/index.js#L357
/**
 * Generate a firestore auto id for use with collection/document .add()
 * @return {string}
 */
export function autoId(): string {
  let autoId = '';
  const AUTO_ID_CHARS =
    // 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    'abcdefghijklmnopqrstuvwxyz0123456789'; // google calendar allows more limited set

  for (let i = 0; i < 20; i++) {
    autoId += AUTO_ID_CHARS.charAt(
      Math.floor(Math.random() * AUTO_ID_CHARS.length)
    );
  }
  return autoId;
}
