import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Button} from '../../components';
import './WeeklyPlanning.scss';

class WeeklyProjects extends Component {
  static propTypes = {
     onAddTask: PropTypes.func.isRequired,
     projects: PropTypes.array.isRequired
  }
  renderProjects() {
    const ret = [];

    this.props.projects.forEach(project => {
      const {id, name} = project;
      const idPlus = `${id}.projects`;
      ret.push(
        <div key={id} className="row">
          <div className="cell"><Button buttonStyle="small-finger white" id={idPlus} onClick={this.props.onAddTask}>+</Button></div>
          <div className="cell task-desc">{name}</div>
        </div>
      )
    })
    return (
      <Fragment>
        <div className='title'>Projects</div>
        {ret}
      </Fragment>
    )
  }

  render () {
    if (this.props.projects && this.props.projects.length > 0) {
      return (
        <div className='weekly-projects'>
          {this.renderProjects()}
        </div>
      )
    }
    return null;
  }
}

export default WeeklyProjects;
