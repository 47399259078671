import {CommonTaskTypes, commonTaskTemplate} from './commonTaskTemplate';

export const allowedTaskTypes = [
    CommonTaskTypes.NOTE  // First one is default
]
export const listName = 'notes';

const Notes = commonTaskTemplate(listName, allowedTaskTypes);

export default Notes;
