/* eslint-disable react/jsx-pascal-case */
import React, {Component} from 'react';
import {Button} from '../../components';
import './CommonTaskObject.scss';

function _TaskList(props) {
  const getTasks = () => {
    return (props.value || {}).tasks || [];
  }
  const renderTasks = () => {
    if (getTasks.length === 0) {
      return (
        <div>
          no tasks
        </div>
      )
    }
  }
  return (
    <div className='task-list'>
      <div className='form-elem'>
        <label>Task List</label>
        {renderTasks()}
      </div>
      <Button >New task</Button>
    </div>
  )
}
class ObjectiveTasks extends Component {
  render() {
    return (
      <div className='object-tasks'>
        <_TaskList />
      </div>
    )
  }
}

export default ObjectiveTasks;
