import React, {Component} from 'react';
import DropDown from '../dropdown/DropDown';
import {registerWheelTreeListener} from '../../data/wheelTree';
import './ObjectiveDropDown.scss';

class TextSummary extends Component {
  constructor(props) {
    super(props);
    if (this.props.formContext) {
      this.props.formContext.validity.registerGetValidityError(this.props.id, this.getValidityError);
    }
  }

  componentWillUnmount() {
    if (this.unregisterWheelTree) this.unregisterWheelTree();
  }

  getValidityError = () => {
    // This might double up the drop down that conditionally shows up.  oh well.
    if (this.props.isRequired && !this.props.value) {
      return "required";
    }
    return false;
  }
  render() {
    return (
      <div className="obj-dropdown">Falls under: {this.props.name} <span className='link' onClick={this.props.onChange}>change...</span></div>
    )
  }
}
class ObjectiveDropDown extends Component {
  constructor (props) {
    super(props);
    this.unregisterWheelTree = registerWheelTreeListener(wheelTree => {
      this.setState({wheelTree});
    })
    this.state = {
      wheelTree: {},
      open: false
    }
  }

  getCurrent() {
    if (!this.state.wheelTree) {
      return {};
    }
    let _low;
    _low = function(wheelTree, id) {
      if (wheelTree.id === id) {
        return wheelTree;
      }
      let ret = undefined;
      if (wheelTree.children) {
        wheelTree.children.forEach(child => {
          ret = ret || _low(child, id);
        })
      }
      return ret;
    }
    return _low(this.state.wheelTree, this.props.value);
  }

  handleChangeBtn = (evt) => {
    this.setState({
      open: true
    })
  }

  renderOption(node, choices, level=0) {
    let nbsp='';
    for(let ix=0; ix<level; ix++) nbsp += '\xa0';
    if (node.id) {
      choices.push({
        value: node.id,
        label: `${nbsp}${node.doc.name}`
      })
    }
    if (node.children) {
      node.children.forEach(child => {
        this.renderOption(child, choices, level+1 )
      })
    }
  }
  handleDropDownChange = (evt) => {
    this.props.onChange(evt);
    this.setState({
      open: false
    })
  }
  renderDropDown() {
    const {id, value, label, isRequired, allowNone} = this.props;
    const props = {
      id,
      value,
      label,
      isRequired
    }

    const choices = [];
    if (isRequired && !value) {
      choices.push({
        value,
        label: 'Choose one'
      })
    }
    if (allowNone) {
      choices.push({
        value: 'none',
        label: 'None'
      })
    }
    this.renderOption(this.state.wheelTree, choices);
    return (
      <div>
        <DropDown
          {...props}
          choices={choices}
          onChange={this.handleDropDownChange}
        />
      </div>
    )
  }

  render() {
    const {id, isRequired, value, formContext} = this.props;
    const current = this.getCurrent() || {};
    const doc = current.doc || {name: '(none)'};
    if (this.state.open || (this.props.isRequired && !this.props.value)) {
      return <div className="obj-dropdown">{this.renderDropDown()}</div>
    }
    return (
      <div className="obj-dropdown">
        <TextSummary
          id={`${id}-t`}
          name={doc.name}
          onChange={this.handleChangeBtn}
          isRequired = {isRequired}
          value = {value}
          formContext = {formContext}  // should probably be wrapping...
        />
      </div>
    )
  }
}

export default ObjectiveDropDown;
