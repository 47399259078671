import React, {Component} from 'react';
import Input, {InputSizes} from '../../components/input/Input';
import formElement from '../util/formElement';
import './DayRangePicker.scss';

const validDayStrings = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday"
]
const INVALID = -1;
const INDETERMINATE = -2;

class DayRangePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value
    }
  }
  isValidDay(str) {
    if (str === "") {
      return INDETERMINATE;
    }
    str = str.toLowerCase();
    let match = INVALID;
    validDayStrings.forEach(day => {
      if (day.indexOf(str) === 0) {
        // partial match.
        if (match===INVALID) {
          match = day;
        } else {
          // Second match means not enough chars yet.
          match = INDETERMINATE;
        }
      }
    })
    return match;
  }
  checkValidity = (value = '') => {
    let error = null;
    const parts = value.split('-');
    if (parts.length>2) {
      error = "Invalid day string"
    }
    parts.forEach(part => {
      const match = this.isValidDay(part);
      if (match === INVALID) {
        error = "Unrecognized day: " + part
      }
      if (match === INDETERMINATE) {
        error = "Must enter day";
      }
    })
    return error;
  }
  errorCheck = (value) => {
    let error = this.checkValidity(value);
    if (!error && this.props.isRequired && !value) {
      error = 'required';
    }
    this.error = error;
  }

  handleChange = (evt) => {
    const value = evt.target.value;
    this.errorCheck(value);
    this.setState({
      value
    })
    console.log(`handleChange state: ${value}/${this.error}`)
  }
  handleBlur = (evt) => {
    this.hasFocus = false;
    this.errorCheck(this.state.value)
    if (!this.error) {
      this.props.onChange({
        target: {
          id: this.props.id,
          value: this.state.value
        }
      });
    }
    console.log(`handleBlur onChange: ${this.state.value} ${this.error}`)
  }
  handleFocus = (evt) => {
    this.hasFocus = true;
    if (!this.error) {
      this.setState({
        value: this.props.value
      });
    }
  }
  render() {
    const value = ((this.hasFocus || this.error) ? this.state.value : this.props.value) || '';
    console.log(`rendere:${this.hasFocus}/${this.error} p:${this.props.value} s:${this.state.value} => ${value}`)
    return (
      <span className="day-range-picker">
        <Input id='days'
          value={value}
          size={InputSizes.SMALL2}
          isRequired={this.props.isRequired}
          placeholder="Dy-Dy"
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          checkValidity={this.checkValidity}
          onChange={this.handleChange}/>
      </span>
    )
  }

};

export default formElement(DayRangePicker);
