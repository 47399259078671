import React from 'react';
import './Popup.scss';

class Popup extends React.Component {
  render() {
    const {children, show} = this.props;
    if (!show) {
      return null;
    }
    return (
      <div className='popup'>
        <div className='mask' />
        <div className='modal-content'>
          <div className='close'>X</div>
          {children}
        </div>
      </div>
    )
  }
}

export default Popup;
