import React, {Component} from 'react';

export const DragContext = React.createContext({});

class DragAndDrop extends Component {
  constructor (props) {
    super(props);
    this.droppables = [];
    this.draggables = [];
  }
  findNearest(rect, dragId) {
    let vertical;
    let horizontal;
    const {x,y} = rect;
    const midX = (rect.left + rect.right) / 2;
    const midY = (rect.top + rect.bottom) / 2;
    this.draggables.forEach(draggable => {
      const {robj} = draggable;
      if (robj.props.dragId !== dragId) {
        const ref = robj.ref.current;
        const rect = ref.getBoundingClientRect();
        if (midX >= rect.left && midX <= rect.right) {
          let dist = y - rect.top;
          if (dist > 0 && (!vertical || vertical.dist > dist) ) {
            vertical = {
              dragId: robj.props.dragId,
              dist
            }
          }
        }
        if (midY >= rect.top && midY <= rect.bottom) {
          let dist = x - rect.left;
          if (dist > 0 && (!horizontal || horizontal.dist > dist) ) {
            vertical = {
              dragId: robj.props.dragId,
              dist
            }
          }
        }
      }
    })
    return {vertical, horizontal};
  }
  _loopDroppables(rect, dragId, fnName) {
    const x = (rect.left + rect.right) / 2;
    const y = (rect.top + rect.bottom) / 2;
    this.droppables.forEach(droppable => {
      const {robj} = droppable;
      const fn = robj[fnName];
      if (fn) {
        const ref = robj.ref.current;
        const bounding = ref.getBoundingClientRect();
        if (x >= bounding.left && x <= bounding.right && y >= bounding.top && y <= bounding.bottom) {
          fn(rect, dragId, bounding);
        } else {
          fn(null, dragId, bounding);  // notifies that a drag is happening not on you; an fake dragOff that way...
        }
      }
    })
  }
  onDrag = (rect, dragId) => {
    this._loopDroppables(rect, dragId, 'onDrag');
  }
  onDragEnd = (rect, dragId) => {
    this._loopDroppables(rect, dragId, 'onDragEnd');
  }
  registerDroppable = (robj) => {
    this.droppables.push({robj});
    return function unregister() {
      const ix = this.droppables.findIndex(droppable => droppable.robj===robj);
      if (ix>=0) {
        this.droppables.splice(ix,1);
      }
    }.bind(this);
  }
  registerDraggable = (robj) => {
    this.draggables.push({robj});
    return function unregister() {
      const ix = this.draggables.findIndex(draggable => draggable.robj===robj);
      if (ix>=0) {
        this.draggables.splice(ix,1);
      }
    }.bind(this);
  }
  render () {
    return (
      <DragContext.Provider value={this}>
        {this.props.children}
      </DragContext.Provider>
    )
  }
}

export default DragAndDrop;
