// import {DateTimeFormat} from 'intl';
import {addDays, format} from 'date-fns';

export const MILLIS_IN_DAY = 1000 * 60 * 60 *24;
const FOW_OFFSET = 3;  // 3 = Monday, 2 = Sunday.

export function dateToDay(date) {
  // Date object will be a number for UTC time. We need to subtract the current
  // offset in order to convert that to what UTC was at the same time as it is now
  // locally (because late in the evening, it's today here but tomorrow in UTC)
  const offsetDays = date.getTimezoneOffset() / 60 / 24;
  return Math.floor((date) / MILLIS_IN_DAY - offsetDays);
}

// Converts day to local date
export function dayToDate(day, timeMillis = 0) {
  const offsetMillis = (new Date()).getTimezoneOffset() * 60 * 1000 + timeMillis;
  return new Date(day * MILLIS_IN_DAY + offsetMillis);
}

export function getToday() {
  return dateToDay(new Date());
}

export function getDayTime(dateTime = new Date()) {
  const offsetDays = dateTime.getTimezoneOffset() / 60 / 24;
  const dayTime = dateTime / MILLIS_IN_DAY - offsetDays;  // Date plus time, in local time
  return dayTime;
}

export function getTime(dateTime) {
  const dayTime = getDayTime(dateTime);
  return dayTime - Math.floor(dayTime);
}

export function dateToTimeMillis(date) {
    const truncated = dayToDate(dateToDay(date));
    return date - truncated;
}
export function dateToTimeDays(date) {
  return dateToTimeMillis(date) / 1000 / 60 / 60 / 24;
}

export function firstOfWeek(day) {
  return Math.floor( (day + FOW_OFFSET) / 7) * 7 - FOW_OFFSET;
}

export function formatDay(day) {
  const date = dayToDate(day);
  return date.toLocaleString('en-US', {
    weekday: 'short',
    month: 'numeric',
    day: 'numeric'
  });
}

export function formatDate(date, timeZone) {
  return formatDay(dateToDay(date));
}

const timeFormatter = new Intl.DateTimeFormat('default', {hour: 'numeric', minute: 'numeric'});
const milTimeFormatter =  new Intl.DateTimeFormat('default', {hour: 'numeric', minute: 'numeric', hour12: false});
export function dateToTimeStr(date, isMil=false) {
  if(isMil) {
    return milTimeFormatter.format(date);
  }
  return timeFormatter.format(date);
}

export function parseDate(str) {
  // wicked, wicked function.  not respecting formats
  const [moStr, dayStr, yrStr] = str.split('/');
  const mo = parseInt(moStr);
  const day = parseInt(dayStr);
  let year = parseInt(yrStr);
  if (year<100) {
    // using short form, just assume it's in this century
    const century = Math.floor(new Date().getFullYear() / 100) * 100;
    year += century;
  }
  return new Date(year, mo - 1, day)
}

export function parseTime(str) {
  // parses a string of hh:mm:ss format into a number of milliseconds)
  // NOTE: assumes valid format!
  const parts = str.split(":")
  const h = parseInt(parts[0]);
  const m = parts.length > 1 ? parseInt(parts[1]) : 0;
  const s = parts.length > 2 ? parseInt(parts[2]) : 0;
  return (((h * 60) + m ) * 60 + s) * 1000;
}

export function timeInputsToMinutes(qty, unit) {
  switch(unit) {
    case 'mins': return 1 * qty;
    case 'hrs': return 60 * qty;
    case 'days': return 60 *24 * qty;
    default: return 1 * qty;
  }
}

export function addMinutes(date, minutes) {
  return new Date(date.getTime() + minutes * 60 * 1000);
}

// Given a iso date str, returns a local date w/ time (for same zulu time)
export function localDateFromDateStr(str) {
  return new Date(str);
}

// Sets the time in a date using a string in hh:mm(:ss) format. NOT affected by time zones.
export function setTime(date, timeStr) {
  const ret = new Date(date);
  const [HH, mm, ss=0] = timeStr.split(':');
  ret.setHours(HH);
  ret.setMinutes(mm);
  ret.setSeconds(ss);
  return ret;
}

function paddedTime(millis) {
    const sign = millis < 0 ? -1 : 1;
    const totMins = Math.floor(sign*millis/60000);
    const hours = Math.floor(totMins/60);
    const mins = totMins - hours * 60;
    return (sign < 0 ? '-' : '+') +
           (hours < 10 ? '0' : '') + hours +
           ':' +
           (mins < 10 ? '0' : '') + mins;
}
// get iso string (optionally in a different time zone, same zulu time)
export function getISOString(date) {
  const offset = -date.getTimezoneOffset() * 60000;  // convert to millis
  return format(date, `yyyy-MM-dd'T'HH:mm:ss${paddedTime(offset)}`);
}

const days = ["Mo", "Tu", "we", "Th", "Fr", "Sa", "Su"];
// Converts string like "Mo-Fr" to an array [1,1,1,1,1,0,0]
export function getDaysFromDayList(dayList) {
  const ret = {};
  const parts = dayList.split(",");
  parts.forEach(part => {
    const [first, last] = part.split("-");
    let start = days.indexOf(first)
    let end = last ? days.indexOf(last) : start;
    if (start>=0 && end>=0) {
      while(start <= end) {
        ret[start] = 1;
        start++;
      }
    }
  })
  return ret;
}
export function getDayOfWeek(day) {
  throw new Error('not implemented')
  // const date = dayToDate(day);
  // const dayOfWeek = (date.getDay() + 6) % 7;  // We have monday as zero based...
}
export function dayInDayList(day, dayList) {
  return getDaysFromDayList(dayList)[getDayOfWeek(day)];
}

export {addDays};
