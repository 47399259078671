/* eslint-disable react/jsx-pascal-case */
import React, {Component, Fragment} from 'react';
import {Input, InputSizes} from '../../components';
import {CommonTaskTypes} from '../../data/store/commonTaskTemplate';
import ObjectiveTasks from './ObjectiveTasks';
import './CommonTaskObject.scss';

function _EstimatedHours(props) {
  const estHours = props.value;
  const {onChange} = props;
  const noun = {
    [CommonTaskTypes.ACCOMPLISHMENT]: "accomplishment",
    [CommonTaskTypes.TRAIT]: "trait",
    [CommonTaskTypes.EXPERIENCE]: "experience"

  }[props.type];
  const desc = `\u00A0 Estimated hours this ${noun} will take)`
  return (
    <Fragment>
      <div className='form-elem'>
        <Input
          id='estHours'
          label='Estimated Hours'
          size={InputSizes.SMALL2}
          onChange={onChange}
          value={estHours}
        /> {desc} (optional)
      </div>
    </Fragment>
  )
}

class ObjectivePlanning extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPlan: false
    }
  }
  handleChange = (evt) => {
    const {id, value} = evt.target;
    const planCopy = Object.assign({}, this.props.value);
    planCopy[id] = value;
    if (this.props.onChange) {
      const fakeEvent = {
        target: {
          id: 'plan',
          value: planCopy
        }
      }
      this.props.onChange(fakeEvent);
    }
  }
  toggleShowPlan = (evt) => {
    this.setState({
      showPlan: !this.state.showPlan
    })
  }
  render() {
    const {value: plan = {}, type} = this.props;
    const hasPlan = Boolean(this.props.plan) || this.state.showPlan;
    const planAllowed = this.props.type !== CommonTaskTypes.PRACTICE;
    if (!planAllowed) {
      return null;
    }
    if (hasPlan) {
      return (
        <div className='objective-planning'>
          <_EstimatedHours value={plan.estHours} type={type} onChange={this.handleChange}/>
          <ObjectiveTasks />
        </div>
      )
    } else {
      return (
        <div className='form-elem'>
          <span className='link' onClick={this.toggleShowPlan}>Add Plan</span>
        </div>
      )
    }
  }
}

export default ObjectivePlanning;
