import React, {Component} from 'react';
import DragAndDrop, {DragContext}  from './DragContext';
import Draggable from './Draggable';

class Droppable extends Component {
  static contextType = DragContext;

  constructor(props) {
    super(props);
    this._ref = React.createRef()
  }

  get ref() {
    return this._ref;
  }

  componentDidMount() {
    if(this.context && this.context.registerDroppable) {
      this.unregister = this.context.registerDroppable(this, this.props.onDrag, this.props.onDragEnd);
    }
  }
  onDrag = (rect, id, bounding) => {
    this.props.onDrag && this.props.onDrag(rect, id, bounding);
  }
  onDragEnd = (rect, id, bounding) => {
    this.props.onDragEnd && this.props.onDragEnd(rect, id, bounding);
  }
  componentWillUnmount() {
    if (this.unregister) this.unregister(this);
  }
  render() {
    // TODO: this is wrong.  We really need a ref for the child...
    return (
      <div ref={this.ref} className='droppable'>
        {this.props.children}
      </div>
    )
  }
}

export {
  DragContext,
  DragAndDrop,
  Draggable,
  Droppable
};
