import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './pages-public/app/App';
import * as serviceWorker from './util/serviceWorker';
import ReactContext from './util/context';
import FrancoContext from './util/FrancoContext';

ReactDOM.render(
  <ReactContext.Provider value={new FrancoContext()}>
    <App />
  </ReactContext.Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
