import React from 'react';
import PropTypes from 'prop-types';
import './Input.scss';
import formElement from '../util/formElement';
import Validatable from '../Validatable';

export const InputSizes = {
  SMALL: "sm2",
  SMALL1: "sm1",
  SMALL2: "sm2",
  SMALL3: "sm3",
  MEDIUM: "me2",
  MEDIUM1: "me1",
  MEDIUM2: "me2",
  MEDIUM3: "me3",
  LARGE: "lg2",
  LARGE1: "lg1",
  LARGE2: "lg2",
  LARGE3: "lg3"
}

class Input extends Validatable {
  static propTypes = {
    id: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    tabindex: PropTypes.string,
    onChange: PropTypes.func,
    isRequired: PropTypes.bool,
    formContext: PropTypes.object.isRequired, // Provided automatically via formElement import.
    size: PropTypes.string                    // One of InputSizes above; default Medium
  }
  handleChange = (evt) => {
    const value = evt.target.value;
    this.checkValidity(value);
    if (this.props.onChange) {
      this.props.onChange(evt);
    }
  }
  render() {
    const { label, errorClass } = this.getErrorInfo();

    const props = {
      id: this.props.id,
      onChange:this.handleChange,
      value: this.props.value || '',
      placeholder: this.props.placeholder || this.props.placeHolder,
      onBlur: this.props.onBlur,
      onFocus: this.props.onFocus,
      tabIndex: this.props.tabindex,
    }
    const isTextArea = this.props.size === InputSizes.LARGE;

    return (
      <span className={`input${errorClass} ${this.props.size || ''}`}>
        {label}
        {isTextArea ?
          <textarea rows="4" cols="40" {...props} />
        :
          <input {...props} />
        }
      </span>
    )
  }
}

export default formElement(Input);
