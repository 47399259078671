import React, {Component} from 'react';
import Header from './Header';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import Home from '../../pages/home/Home';
import Values from '../../pages/values/Values';
import Value from '../../pages/values/Value';
import Objectives from '../../pages/objectives/Objectives';
import Objective from '../../pages/objectives/Objective';
import Todo from '../../pages/todos/Todo';
import Todos from '../../pages/todos/Todos';
import Settings from '../../pages/settings/Settings';
import DailyDos from '../../pages/dailyDos/DailyDos';
import DailyPlanning from '../../pages/dailyPlanning/DailyPlanning';
import WeeklyPlanning from '../../pages/weeklyPlanning/WeeklyPlanning';
import MonthlyPlanning from '../../pages/monthlyPlanning/MonthlyPlanning';
import YearlyPlanning from '../../pages/yearlyPlanning/YearlyPlanning';
import People from '../../pages/people/People';
import Person from '../../pages/people/Person';
import Projects from '../../pages/projects/Projects';
import Project from '../../pages/projects/Project';
import Note from '../../pages/notes/Note';
import Notes from '../../pages/notes/Notes';
import Appointment from '../../pages/appointment/Appointment';
import Calendar from '../../pages/calendar/Calendar';
import Event from '../../pages/events/Event';
import Main from '../main/Main';
import DnDExample from '../../util/dnd/example';
import {ROUTES} from '../../util/constants';
import {withContext} from '../../util/context';
import {PrivateRoute} from '../../util/PrivateRoute';
import './App.scss';
import './global.scss';


class App extends Component {
  constructor(props) {
    super(props);
    // Initially just set to current value.  This will get updated when onAithStateChanged gets updated below
    const  authUser = this.props.context.firebaseWrapper.auth.currentUser;
    if (authUser) {
      this.props.context.authUser = authUser;
    }
  }
  componentDidMount() {
    this.listener = this.props.context.firebaseWrapper.auth.onAuthStateChanged( authUser => {
      this.props.context.authUser = authUser || null;
      this.forceUpdate();
    });
  }
  componentWillUnmount() {
    this.listener();
  }
  renderContent() {
    return (
        <div>
          <PrivateRoute path={`${ROUTES.NOTE}/:id?`} component={Note} />
          <PrivateRoute path={ROUTES.NOTES} component={Notes} />
          <PrivateRoute path={ROUTES.APPOINTMENT} component={Appointment} />
          <PrivateRoute path={ROUTES.CALENDAR} component={Calendar} />
          <PrivateRoute path={`${ROUTES.EVENT}/:id?`} component={Event} />
          <PrivateRoute path={ROUTES.OBJECTIVES} component={Objectives} />
          <PrivateRoute exact path={`${ROUTES.OBJECTIVE}/:id?`} component={Objective} />
          <PrivateRoute path={ROUTES.PROJECTS} component={Projects} />
          <PrivateRoute exact path={`${ROUTES.PROJECT}/:id?`} component={Project} />
          <PrivateRoute path={ROUTES.VALUES} component={Values} />
          <PrivateRoute exact path={`${ROUTES.VALUE}/:id?`} component={Value} />
          <PrivateRoute path={`${ROUTES.TODO}/:id?`} component={Todo} />
          <PrivateRoute path={ROUTES.TODOS} component={Todos} />
          <PrivateRoute exact path={ROUTES.SETTINGS} component={Settings} />
          <PrivateRoute exact path={ROUTES.DAILYDOS} component={DailyDos} />
          <PrivateRoute path={`${ROUTES.DAILY}/:day?`} component={DailyPlanning} />
          <PrivateRoute path={ROUTES.WEEKLY} component={WeeklyPlanning} />
          <PrivateRoute path={ROUTES.MONTHLY} component={MonthlyPlanning} />
          <PrivateRoute path={ROUTES.YEARLY} component={YearlyPlanning} />
          <PrivateRoute path={ROUTES.PEOPLE} component={People} />
          <PrivateRoute path={`${ROUTES.PERSON}/:id?`} component={Person} />
          <PrivateRoute exact path={"/dnd"} component={DnDExample} />
          <PrivateRoute exact path={"/in"} component={Home} />
          <Route exact path={"/"} component={Main} />
        </div>
    )
  }

  render() {
    if (this.props.context.authUser === undefined) {
      // Render nothing till we hear from authentication
      return <div />
    }
    return (
      <div className="App">

        <Router>
          <Header />
          <div className="header-spacer" />
          <div className="App-content">
            {this.renderContent()}
          </div>
        </Router>
      </div>
    );
  }
}

export default withContext(App);
