import React, {Component} from "react";
import {Link} from 'react-router-dom';
import {registerWheelTreeListener} from '../../data/wheelTree';
import {ROUTES} from '../../util/constants';
import './Objectives.scss';

class ObjectivesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wheelTree: undefined
    }
    this.unregisterWheelTree = registerWheelTreeListener(wheelTree => {
      this.setState({wheelTree});
    })
  }

  componentWillUnmount() {
    if (this.unregisterWheelTree) this.unregisterWheelTree();
  }
  findId(target) {
    while(target && target!==document) {
      if (target.id) {
        return target.id;
      }
      target = target.parentNode;
    }
    return '';
  }
  handleObjectiveClick = (evt) => {
    evt.stopPropagation();
    let id = this.findId(evt.target);
    this.props.history.push(`${ROUTES.OBJECTIVE}/${id}`)
  }
  handleValueClick = (evt) => {
    evt.stopPropagation();
    let id = this.findId(evt.target);
    this.props.history.push(`${ROUTES.VALUE}/${id}?ret=obj`)
  }
  handleAddClick = (evt) => {
    evt.stopPropagation();
    let id = this.findId(evt.target);
    this.props.history.push(`${ROUTES.OBJECTIVE}?p=${id}`)
  }

  getValue(id) {
    return this.state.valueList[id] || {};
  }

  renderObjectives(level, wheelTree, ret, parentInactive) {
    const {doc, children, id} = wheelTree;
    if (level) {
      //----- line for an objective ----
      const name = doc.name + ((doc.goal && doc.goal.hasGoal) ? ' (g)' : '');
      const inactive = parentInactive || (doc.status === 'inactive');
      const inactiveClass = inactive ? ' inactive' : '';
      ret.push(
        <div className={`row wheelTree-${level}${inactiveClass}`} id={id} key={id} onClick={this.handleObjectiveClick}>
          <div className='cell name'><span className='link'>{name}</span></div>
          <div className='add-link' onClick={this.handleAddClick}>
            <span className='link'>Add Objective</span>
          </div>
        </div>
      )
      if (children && children.length > 0) {
        children.forEach(child => {
          this.renderObjectives(level+1, child, ret, inactive);
        })
      }
    } else {
      //---- line for a value (and value related section stuff)
      ret.push(
        <div className='row spacer' key={`spcr-${id}`}>
          <div className='cell' />
        </div>
      )
      ret.push(
        <div className='row value' id={id} key={id} onClick={this.handleValueClick}>
          <div className='cell'><span className='link'>{doc.name}</span></div>
          <div className='add-link' onClick={this.handleAddClick}>
            <span className='link'>Add Objective</span>
          </div>
        </div>
      )
      if (children && children.length > 0) {
        children.forEach(child => {
          this.renderObjectives(level+1, child, ret);
        })
      }
    }

  }
  renderWheelTree() {
    const ret = [];
    if (!this.state.wheelTree) {
      return <div />
    }
    this.state.wheelTree.children.forEach(child => {
      this.renderObjectives(0, child, ret);
    })
    return ret;
  }

  render() {
    return (
      <div className='table'>
        {this.renderWheelTree()}
        {this.state.wheelTree ?
          <div className='row spacer' key='spcr-footer'>
            <div className='cell' />
          </div>
        :
          null
        }
        {this.state.wheelTree ?
          <div className='row footer' key='footer'>
            <div className='cell'>
              <Link to={`${ROUTES.VALUE}?ret=obj`}>Add Core Value</Link>
            </div>
            <div className='cell'></div>
            <div className='cell'></div>
          </div>
        :
          null
        }
      </div>
    )
  }
}

export default ObjectivesPage;
