import React, {Component} from "react";
import {ROUTES} from '../../util/constants';
import {Link} from 'react-router-dom';
import {Button} from '../../components';

class Home extends Component {
  render() {
    return (
      <div>
        <div className="heading">
          Quick Links
        </div>
        <div>
          <Link to={ROUTES.DAILYDOS}>
            <Button buttonStyle='finger blue'>Daily Do's</Button>
          </Link>
          <Link to={ROUTES.CALENDAR}>
            <Button buttonStyle='finger blue'>Calendar</Button>
          </Link>
          <Link to={ROUTES.TODO}>
            <Button buttonStyle='finger blue'>Add To Do</Button>
          </Link>
          <Link to={ROUTES.NOTE}>
            <Button buttonStyle='finger blue'>Add Note</Button>
          </Link>
        </div>
        <div className="heading">
          Planning
        </div>
        <div>
          <Link to={ROUTES.DAILY}>
            <Button buttonStyle='finger blue'>Daily Planning</Button>
          </Link>
          <Link to={ROUTES.WEEKLY}>
            <Button buttonStyle='finger blue'>Weekly Planning</Button>
          </Link>
          <Link to={ROUTES.MONTHLY}>
            <Button buttonStyle='finger blue'>Monthly Planning</Button>
          </Link>
          <Link to={ROUTES.YEARLY}>
            <Button buttonStyle='finger blue'>Yearly Planning</Button>
          </Link>
        </div>
        <div>
          <div className="heading">
            Wheel
          </div>
          <Link to={ROUTES.VALUES}>
            <Button buttonStyle='finger blue'>Values</Button>
          </Link>
          <Link to={ROUTES.OBJECTIVES}>
            <Button buttonStyle='finger blue'>Objectives</Button>
          </Link>
        </div>
        <div>
          <div className="heading">
            More
          </div>
          <Link to={ROUTES.PROJECTS}>
            <Button buttonStyle='finger blue'>Projects</Button>
          </Link>
          <Link to={ROUTES.TODOS}>
            <Button buttonStyle='finger blue'>To Dos</Button>
          </Link>
          <Link to={ROUTES.NOTES}>
            <Button buttonStyle='finger blue'>Notes</Button>
          </Link>
          <Link to={ROUTES.PEOPLE}>
            <Button buttonStyle='finger blue'>People</Button>
          </Link>
          <Link to={ROUTES.SETTINGS}>
            <Button buttonStyle='finger blue'>Settings</Button>
          </Link>
        </div>
      </div>
    )
  }
}

export default Home;
