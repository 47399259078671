import React, {Component} from "react";
import {Link} from 'react-router-dom';
import Notes from '../../data/store/notes';
import {CommonTaskTypes} from '../../data/store/commonTaskTemplate';
import {Button, Popup} from '../../components';
import {ROUTES} from '../../util/constants';
import './DailyPlanning.scss';

class TaskPopup extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }
  handleToday = () => {
    this.props.onChange && this.props.onChange('today');
  }
  handleThisWeek = () => {
    this.props.onChange && this.props.onChange('thisWeek');
  }
  handleLater = () => {
    this.props.onChange && this.props.onChange('later');
  }
  handleClose = () => {
    this.props.onChange && this.props.onChange(undefined);
  }
  render() {
    const {forId} = this.props;
    if (!forId) {
      return null;
    }
    return (
      <Popup id='taskPopup' onClose={this.handleClose} show={true}>
         <div>NOT FINISHED.  will just add task for later no matter what you click</div>
         <div>See handleTaskPopup below</div>
         <div>And fix popup (make popup, plus add close button)</div>
         Create task for {forId}...
         <div>
           <Button buttonStyle="medium" onClick={this.handleToday}>today</Button>
           <Button buttonStyle="medium" onClick={this.handleThisWeek}>this week</Button>
           <Button buttonStyle="medium" onClick={this.handleLater}>later</Button>
         </div>
      </Popup>
    )
  }
}

class DPNotes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSaved: false,
      makeTask: undefined,
      laterized: {},
    }
  }

  handleTaskClick = (evt) => {
    const id = evt.target.id.split('.')[1];
    // this.setState({ makeTask: id });
    const taskUrl = `${ROUTES.NOTE}/${id}?r=${ROUTES.DAILY}`;
    this.props.history.push(taskUrl);
  }

  handleSaveClick = (evt) => {
    const id = evt.target.id.split('.')[1];
    const doc = this.props.noteList.find(note => note.id===id);
    if (doc) {
      doc.status = 'active';
      Notes.putDocument(id, doc);
    }
    this.checkNotesComplete();
  }

  handleDoneClick = (evt) => {
    const id = evt.target.id.split('.')[1];
    const doc = this.props.noteList.find(note => note.id===id);
    if (doc) {
      doc.status = 'inactive';
      Notes.putDocument(id, doc);
    }
    this.checkNotesComplete();
  }

  handleLaterClick = (evt) => {
    const { laterized } = this.state;
    const id = evt.target.id.split('.')[1];
    const doc = this.props.noteList.find(note => note.id===id);
    if (doc) {
      doc.status = 'unprocessed';
      Notes.putDocument(id, doc);
    }
    const newLat = {...laterized};
    newLat[id] = true;
    this.setState({laterized: newLat});
    this.checkNotesComplete();
  }

  handleNext = (evt) => {
    const { onNotesDone } = this.props;
    onNotesDone && onNotesDone();
  }

  checkNotesComplete() {
    let complete = true;
    (this.props.noteList || []).forEach(doc => {
      if (!doc.status) {
        complete = false;
      }
    });
    if (complete) {
      // TODO: Go to next tab
    }
  }

  renderUnprocessedNotes() {
    const { laterized } = this.state;
    const notes = [];
    (this.props.noteList || []).forEach(doc => {
      const { id, desc } = doc;
      if ((!doc.status || doc.status==='unprocessed') && !laterized[id]) {
        notes.push(
          <div key={id}>
            <div className='note' id={id}>
              <div className='note-buttons cell'>
                <Button buttonStyle="smallest" id={`task.${id}`} onClick={this.handleTaskClick}>Task</Button>
                <Button buttonStyle="smallest" id={`note.${id}`} onClick={this.handleSaveClick}>Save</Button>
                <Button buttonStyle="smallest" id={`del.${id}`} onClick={this.handleDoneClick}>Done</Button>
                <Button buttonStyle="smallest" id={`later.${id}`} onClick={this.handleLaterClick}>Later</Button>
              </div>
              <div className='note-desc cell' onClick={this.handleNoteClick}>{desc}</div>
            </div>
          </div>
        )
      }
    })
    if (!notes.length) {
      return (
        <>
          <div className='unprocessed'>All notes have been processed</div>
          <div key='_add'><Button id='_notesDone' buttonStyle="medium" onClick={this.handleNext}>Next</Button></div>
        </>
      )
    }

    return (
      <div className='unprocessed'>
        <div className='head'>
          What do you want to do with the following new notes? Turn to task, save as a long-term note, mark as done, or put it off till tomorrow?
        </div>
        {notes}
      </div>
    );
  }
  renderProcessedNotes() {
    const {showSaved} = this.state;
    if (!showSaved) {
      return <div className='head'><Link>show saved</Link></div>
    }
  }
  handleTaskPopup = (value) => {
    if (value) {
      const id = this.state.makeTask;
      const doc = this.props.noteList.find(note => note.id===id);
      if (doc) {
        doc.objType = CommonTaskTypes.TODO;
        doc.name = doc.desc;
        doc.parent = 'none';
        Notes.putDocument(id, doc);
      }
    }

    this.setState({makeTask: undefined});
  }

  render() {
    const {makeTask} = this.state;
    return (
      <div className='daily-notes'>
        {this.renderUnprocessedNotes()}
        {this.renderProcessedNotes()}
        <TaskPopup forId={makeTask} onChange={this.handleTaskPopup}/>
      </div>
    )
  }
}

export default DPNotes;
