import Projects from './store/projects';

let unsubscribeProjects;

let initialized;
let notificationComplete;
let projectTree;
let nodeHash;
let projectList;
let listeners = [];

function processHash(node) {
  node.children && node.children.forEach(child => {
    nodeHash[child.id] = child;
    if (child.children) {
      processHash(child)
    }
  })
}

function createProjectTree() {
  nodeHash = {};
  projectTree = {
    children: []
  };

  if (projectList) {
    projectList.forEach(ref => {
      const {doc, id} = ref;
      doc.id = id;
      projectTree.children.push(doc)
    })
    processHash(projectTree);
    listeners.forEach(listener => {
      listener(projectTree, nodeHash);
    })
    notificationComplete = true;
  }
}

function initialize() {
  nodeHash = {};

  unsubscribeProjects = Projects.query().onSnapshot( queryRef => {
    projectList = [];
    const docs = queryRef.docs;
    docs.forEach(docRef => {
      const doc = Object.assign({}, docRef.data());
      projectList.push({
        id: docRef.id,
        doc
      });
    })
    createProjectTree();
  }, (error) => {
    console.log(error);
    alert('error', error);
  })

  initialized = true;
}

function denitialize() {
  unsubscribeProjects();
  initialized = false;
  notificationComplete = false;
}

export function registerProjectTreeListener(func) {
  listeners.push(func);

  if (!initialized) {
    initialize();
  }
  if (notificationComplete) {
    // There is not going to be a load event to trigger notification.  So do initial notification here.
    Promise.resolve().then(() => {
      // Doing this inside a promise/then allows this to be called from a constructor.
      func(projectTree, nodeHash);
    })
  }

  // Return an unsubscribe method
  return () => {
    const newListeners = listeners.filter(L => L!==func);
    listeners = newListeners;
    if (listeners.length === 0) {
      denitialize();
    }
  }
}
