import React, {Component} from 'react';
import './MonthlyPlanning.scss';

class MonthlyPlanning extends Component {
  render() {
    return (
      <div>
        <div>Monthly Planning</div>
        <ul>
          <li>Review progress on annual goals</li>
          <li>Block the coming month</li>
          <li>Select objectives / set goals for coming month</li>
          <li>Set date for monthly planning for next month</li>
        </ul>
      </div>
    )
  }
}

export default MonthlyPlanning;
