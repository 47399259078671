import React, {useState, useRef} from 'react';
import {DragAndDrop, Draggable, Droppable} from '../dnd';
import styled, {css} from 'styled-components';

const Red = css`
  background-color: #eeCCff;
`;
const Positioned = css`
  top: ${props => props.pos.top}px;
  left: ${props => props.pos.left}px;
`;

const SquareDiv = styled.div`
  width: 100px;
  height: 100px;
`;
const SquareRedDiv = styled(SquareDiv)`
  ${Red}
`;
const DivBoard = styled.div`
  position: relative;
  width: 100%;
  height: 700px;
  background-color: #aaFFEE;
`;
const Target = styled.svg`
  ${Positioned}
  width: 200px;
  height: 300px;
`;
const PositionedDiv = styled.div`
  ${Positioned}
  position: relative;
`;

export default function Example(props) {
  const boardRef = useRef();
  const [redPos, setRed] = useState({top: 10, left: 10});
  const [targetPos, _setTarget] = useState({top: 200,left: 200});
  const [shadow, setShadow] = useState(null);
  const [_dragging, setDragging] = useState();
  const handleDragStart = (evt) => {

  }
  const handleDragEnd = (evt) => {
    setDragging();
  }
  const onDrag = (evt) => {
    console.log('drag', evt);
  }
  const onDrop = (evt) => {
    const {left, top} = evt;
    const boardRect = boardRef.current.getBoundingClientRect();
    setRed({left: left-boardRect.left, top: top-boardRect.top});
  }

  return (
    <DragAndDrop>
      <Droppable  onDrag={onDrag} onDragEnd={onDrop}>
        <DivBoard ref={boardRef}>
          <PositionedDiv pos={redPos}>
            {shadow}
            <Draggable dragId='1' setShadow={setShadow} id='xyz' onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
              <SquareRedDiv setShadow={setShadow}>
                Example
              </SquareRedDiv>
            </Draggable>
          </PositionedDiv>
            <Target pos={targetPos}>
              <circle cx="200" cy="200" r="40" stroke="black" stroke-width="3" fill="red" />
            </Target>
        </DivBoard>
      </Droppable>
    </DragAndDrop>
  )
}
