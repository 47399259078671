import FirebaseWrapper from './FirebaseWrapper';
import GapiWrapper from './gapiWrapper';

class FrancoContext {
  constructor() {
    this.firebaseWrapper = new FirebaseWrapper();
    this.gapiWrapper = new GapiWrapper();
    this.authUser = undefined;
    this.userListeners = [];
  }

  setUser(user) {
    this.authUser = user;
  }

  getUser() {
    return this.authUser;
  }
};

export default FrancoContext;
