import React, {Fragment} from 'react';
import {Input, InputSizes, DropDown, DayPicker} from '../../components';
import './CommonTaskObject.scss';

const frequencyChoices = [
  {
    value: 'day',
    label: 'Day'
  },
  {
    value: 'week',
    label: 'Week'
  },
  {
    value: 'month',
    label: 'Month'
  },
  {
    value: 'year',
    label: 'Year'
  }
]

function _On(props) {
  const {value, onChange} = props;
  const {frequencyUnit, frequencyOn=''} = value;
  let onElem = null;
  switch(frequencyUnit) {
    case 'week': {
      onElem = <DayPicker id='frequencyOn' value={frequencyOn} onChange={onChange}/>
      break;
    }
    default: {
      break;
    }
  }
  return (
    <div className='form-elem'>
      On {onElem}
    </div>
  )
}

export default function Recurrance(props) {
  const {value} = props;
  const {frequency, frequencyUnit} = value;
  return (
    <Fragment>
      <div className='form-elem'>
        <Input
          id='frequency'
          label='Frequency'
          onChange={props.onChange}
          size={InputSizes.SMALL}
          value={frequency}
        />
        times per
        <DropDown
          id='frequencyUnit'
          choices={frequencyChoices}
          onChange={props.onChange}
          value={frequencyUnit}
        />
      </div>
      <_On id='on' onChange={props.onChange} value={value}/>
    </Fragment>
  )
}
