import React, {Component, Fragment} from 'react';
import WeeklyPlan from '../../data/store/weeklyPlan';
import ProjectsData from '../../data/store/projects';
import Tasks from '../../data/store/tasks';
import Notes from '../../data/store/notes';
import DPNotes from './DPNotes';
import ChooseTasks from './ChooseTasks';
import WeeklyTasks from './WeeklyTasks';
import DailyTasks from './DailyTasks';
import ResolveTasks from './ResolveTasks';
import CalendarTasks from './CalendarTasks';
import CalendarDay from '../calendar/CalendarDay';
import {Button, Toggle, CheckSet} from '../../components';
import {withContext} from '../../util/context';
import {startOfDay, addDays} from 'date-fns';
import {formatDay, getToday, getTime, dayToDate, firstOfWeek, timeInputsToMinutes} from '../../util/dateHelper';
import {getStatusOfDay, getDailyPlan} from '../../util/taskHelper';
import {registerWheelTreeListener} from '../../data/wheelTree';
import {registerProjectTreeListener} from '../../data/projectTree';
import './DailyPlanning.scss';

class DailyPlanning extends Component {
  constructor(props) {
    super(props);
    let day = this.props.day;
    if (!day) {
      day = getToday();
    }
    this.state = {
      page: 0,
      day: undefined,
      week: undefined,
      weeklyPlan: {
        _waitingForData: true
      },
      wheelTree: undefined,
      wheelTreeHash: undefined,
      taskHash: undefined,
      taskList: undefined,
      noteList: undefined,
      hasNotes: false,
      caldendarItems: undefined,
      resolveYesterday: false,
      showCalendar: false,
    }
    this.fetchCalendarData(day);
    this.loadData(day);
    this.unregisterWheelTree = registerWheelTreeListener((wheelTree, wheelTreeHash) => {
      this.setState({wheelTree, wheelTreeHash});
    })
    this.unregisterProjectTreeListener = registerProjectTreeListener((projectTree, projectTreeHash) => {
      this.setState({projectTree, projectTreeHash})
    })
  }
  componentDidMount() {
    const tasks = Tasks.getCollectionRef();
    if (this.unsubscribeTasks) this.unsubscribeTasks();
    this.unsubscribeTasks = tasks.onSnapshot((results) => {
      const taskList = [];
      const taskHash = {};
      results.forEach( docRef => {
        const doc = Object.assign({}, docRef.data());
        doc.id = docRef.id;
        taskList.push(doc);
        taskHash[doc.id] = doc;
      })
      this.setState({
        taskList,
        taskHash
      })
    })

    const notes = Notes.getCollectionRef();
    if (this.unsubscribe) this.unsubscribe();
    this.unsubscribe = notes.onSnapshot((results) => {
      const noteList = [];
      let hasNotes = false;
      results.forEach( docRef => {
        const doc = Object.assign({}, docRef.data());
        doc.id = docRef.id;
        if (!doc.status || doc.status === 'unprocessed') {
          hasNotes = true;
        }
        noteList.push(doc);
      })
      this.setState({
        noteList,
        hasNotes
      })
    })
  }
  componentWillUnmount() {
    if (this.unsubscribeWeeklyPlan) this.unsubscribeWeeklyPlan();
    if (this.unregisterWheelTree) this.unregisterWheelTree();
    if (this.unregisterProjectTreeListener) this.unregisterProjectTreeListener();
    if (this.unsubscribeTasks) this.unsubscribeTasks();
  }
  fetchCalendarData(day) {
    const begin = startOfDay(dayToDate(day));
    const end = addDays(begin, 1);
    this.props.context.gapiWrapper.listUpcomingEvents(begin, end).then(this.handleCalendarResults, (error) => {
      console.log('FetchCalendarDataError', error);
      alert('FetchCalendarDataError: '+error.status+' '+error.statusText);
    });
  }
  handleCalendarResults = (results) => {
    const caldendarItems = results.result.items;
    this.setState({caldendarItems});
  }
  loadData(day) {
    const week = firstOfWeek(day);
    const weeklyPlanRef = WeeklyPlan.getCollectionRef();
    if (this.unsubscribeWeeklyPlan) this.unsubscribeWeeklyPlan();
    this.unsubscribeWeeklyPlan = weeklyPlanRef.where('week', ">=", week-7).onSnapshot( queryRef => {
      let lastWeek = {},
          thisWeek = {},
          nextWeek = {};
      if (!queryRef.empty) {
        queryRef.docs.forEach(docRef => {
          const doc = docRef.data();
          if (doc.week === week) {
            thisWeek = doc;
          } else if (doc.week === week - 7) {
            lastWeek = doc;
          } else if (doc.week === week + 7) {
            nextWeek = doc;
          }
        })
      }
      if (this.state.day) {
        day = this.state.day;
      } else {
        if (day===getToday()) {
          // day was defaulted, see if we need to start with yesterday
          const yesterday = getDailyPlan(this._getWeeklyPlanForDayLow(day-1, thisWeek, lastWeek, nextWeek), day-1);
          if (yesterday.status === 'none' && yesterday.tasks) {
            // Yesterday we entered tasks but we did not complete them.
            if (day === week) {
              // hmmm. first day, so we are about to roll back to previous week...
              nextWeek = thisWeek;
              thisWeek = lastWeek;
              lastWeek = undefined;
            }
            day = day-1;
          } else {
            // If we finished today, start at tomorrow
            const today = getDailyPlan(this._getWeeklyPlanForDayLow(day, thisWeek, lastWeek, nextWeek), day);
            if (today.status==='done') {
              day = day+1;
            }
          }
        }
      }
      // TODO: detect case where we've finished the day at it's after 6pm, so we
      //       should update the day to show tomorrow
      this.setState({
        lastWeeklyPlan: lastWeek,
        weeklyPlan: thisWeek,
        nextWeeklyPlan: nextWeek,
        day,
        week,
      });
    });
    if (this.unsubscribeProjecs) this.unsubscribeProjecs();
    this.unsubscribeProjecs = ProjectsData.query().onSnapshot(queryRef => {
      const projects = [];
      const docs = queryRef.docs;
      docs.forEach(docRef => {
        const { id } = docRef;
        const doc = Object.assign({}, docRef.data());
        projects.push({ id, doc })
      })
      this.setState({ projects });
    })
  }
  renderResolveTasks() {
    const day = this.state.day - 1;
    const weeklyPlan = this.getWeeklyPlanForDay(day);
    return (
      <ResolveTasks
        weeklyPlan={weeklyPlan}
        day={day}
        week={this.state.week}
        objHash={this.state.wheelTreeHash}
        taskHash={this.state.taskHash}
      />
    )
  }
  handleAddEvent = (event) => {
    console.log('add event', event);
  }
  getDesc(id) {
    if (this.state.wheelTreeHash) {
      const wheelItem = this.state.wheelTreeHash[id];
      if (wheelItem) {
        const {name, desc} = wheelItem.doc;
        return {name, desc};
      }
    }
    if (this.state.projectTreeHash) {
      const projectItem = this.state.projectTreeHash[id];
      if (projectItem) {
        const {name, desc} = projectItem;
        return {name, desc};
      }
    }
    if (this.state.notes) {

    }
    if (this.state.taskList) {
      const task = this.state.taskList.find(t => t.id===id);
      if (task) {
        const {name, desc} = task;
        return {name, desc};
      }
    }
    return {};
  }
  getEventInfo = (id) => {
    const {name, desc = ""} = this.getDesc(id);
    if (name) {
      const weeklyPlan = this.getWeeklyPlanForDay(this.state.day);
      const task = weeklyPlan.tasks.find(t => t.id === id);
      if (task) {
        return {
          name,
          desc,
          duration: timeInputsToMinutes(task.duration, task.durationUnit)
        }
      }
      return {
        name,
        desc,
        duration: 30
      }
    }
    return null;
  }
  renderCalendar() {
    const {history} = this.props;
    const weeklyBlocking = this.state.weeklyPlan.weekBlocking || {}
    return <CalendarTasks
              items={this.state.caldendarItems}
              weeklyBlocking={weeklyBlocking}
              day={this.state.day}
              onAddEvent={this.handleAddEvent}
              getEventInfo={this.getEventInfo}
              history = {history}
            />
  }
  _getWeeklyPlanForDayLow(day, weeklyPlan={}, lastWeeklyPlan={}, nextWeeklyPlan={}) {
    let ret;
    const thisWeek = weeklyPlan.week ? weeklyPlan.week
                    : (lastWeeklyPlan.week ? lastWeeklyPlan.week + 7
                       : nextWeeklyPlan.week || day);
    if (day<thisWeek) {
      ret = lastWeeklyPlan;
    } else if (day>=thisWeek+7) {
      ret = nextWeeklyPlan;
    } else {
      ret = weeklyPlan;
    }
    return ret || {};
  }
  getWeeklyPlanForDay(day) {
    return this._getWeeklyPlanForDayLow(day, this.state.weeklyPlan, this.state.lastWeeklyPlan, this.state.nextWeeklyPlan);
  }
  getDailyPlan(day = this.state.day) {
    const weeklyPlan = this.getWeeklyPlanForDay(day);
    return getDailyPlan(weeklyPlan, day);
  }
  getStatusOfDay(day = this.state.day) {
    return getStatusOfDay(this.getWeeklyPlanForDay(day), day);
  }
  newDay = (day) => {
    const week = firstOfWeek(day);
    this.setState({day, week});
    if (week !== this.state.week) {
      this.loadData(day);
    }
    this.fetchCalendarData(day);
  }
  handleIncDay = () => {
    this.newDay(this.state.day + 1);
  }
  handleDecDay = () => {
    this.newDay(this.state.day - 1);
  }
  handleChangeShowPlanning = (value) => {
    this.setState({showPlanning: value});
  }
  handleNotesDone = () => {
    this.setState({page: 'choose'})
  }
  handleTasksAdded = () => {
    this.setState({page:'calendar'})
  }
  handleTasksResolved = (day) => {
    const dailyStatus = this.getStatusOfDay(day);
    const nextStatus = this.getStatusOfDay(day+1);
    if (dailyStatus==='done' && (nextStatus==='none' || !nextStatus)) {
      this.handleIncDay();
    }
  }
  handleUpdateWeeklyTasks = (tasks) => {
    console.log('==== handleUpdateWeelyTasks', tasks);
    const emptyDoc = { week: this.state.week, days: {} }
    const weeklyPlan = Object.assign(emptyDoc, this.state.weeklyPlan, {tasks});
    WeeklyPlan.putDocument(this.state.week, weeklyPlan);
    this.setState({
      weeklyPlan: {
        _waitingForData: true
      }
    });
  }
  handleSetPage = (evt) => {
    this.setState({page: evt.target.value});
  }
  render() {
    const {showPlanning, day, wheelTree, weeklyPlan, showCalendar, noteList, hasNotes} = this.state;
    const isToday = day===getToday();
    const dateStr = formatDay(day);
    if (!wheelTree || weeklyPlan._waitingForData || !noteList) {
      return (
        <div className='daily-planning'>
          <div>Daily Planning for {dateStr}</div>
          loading...
        </div>
      )
    }

    let {page} = this.state;
    let options = []; // ['choose', 'calendar', 'resolve']
    const dailyPlan = this.getDailyPlan(day);
    const dailyStatus = this.getStatusOfDay();

    let getAPlanWarning = null;
    const dpHeader = (
      <div>
        Daily Planning for {dateStr}
        <Button buttonStyle='txt' onClick={this.handleIncDay}>+</Button>
        <Button buttonStyle='txt' onClick={this.handleDecDay}>-</Button>
      </div>
    );
    if (!weeklyPlan.weekBlocking) {
      return (
        <>
          {dpHeader}
          <div>Before daily planning, create a <a href="/in/weekly">weekly plan</a></div>
        </>
      )
    }

    // const showPlanningForToday = (showPlanning===undefined && getTime() < .75) || showPlanning;
    switch(dailyStatus ) {
      case 'done': {
        options = ['resolve'];
        page = 'resolve';
        break;
      }
      case 'none': {
        options = ['notes', 'choose'];
        if (dailyPlan.tasks) {
          options.push('calendar');
        }
        if (!page) {
          if(hasNotes) {
            page = 'notes';
          } else {
            page = 'choose';
          }
        }
        break;
      }
      case 'open': {
        options = ['notes', 'choose', 'calendar', 'resolve'];
        if (!page) {
          if ( (isToday && getTime() > .75) || day < getToday()) {
            page = 'resolve';
          } else {
            if (hasNotes) {
              page = 'notes';
            } else {
              page = 'choose';
            }
          }
        }
      }
    }

    let contents;
    const {week, wheelTreeHash, taskHash, projects, taskList} = this.state;
    // eslint-disable-next-line default-case
    switch(page) {
      case 'notes': {
        const {history} = this.props;
        const weeklyPlan = this.getWeeklyPlanForDay(day);
        contents = <DPNotes
          weeklyPlan={weeklyPlan}
          day={day}
          week={week}
          wheelTreeHash={wheelTreeHash}
          taskHash={taskHash}
          onNotesDone={this.handleNotesDone}
          history={history}
          noteList={noteList}
        />;
        break;
      }
      case 'choose': {
        const weeklyPlan = this.getWeeklyPlanForDay(day);
        contents = <ChooseTasks
          weeklyPlan={weeklyPlan}
          projects={projects}
          taskList={taskList}
          day={day}
          week={week}
          wheelTree={wheelTree}
          wheelTreeHash={wheelTreeHash}
          taskHash={taskHash}
          onTasksAdded={this.handleTasksAdded}
          onUpdateWeeklyTasks={this.handleUpdateWeeklyTasks}
        />;
       break;
      }
      case 'calendar': {
        const {weeklyPlan, caldendarItems} = this.state;
        const {history} = this.props;
        const weeklyBlocking = weeklyPlan.weekBlocking || {}
        contents = <CalendarTasks
                  items={caldendarItems}
                  weeklyBlocking={weeklyBlocking}
                  weeklyPlan={weeklyPlan}
                  day={day}
                  week={week}
                  objHash={wheelTreeHash}
                  taskHash={taskHash}
                  onAddEvent={this.handleAddEvent}
                  getEventInfo={this.getEventInfo}
                  history = {history}
                />
       break;
      }
      case 'resolve': {
        const weeklyPlan = this.getWeeklyPlanForDay(day);
        contents = <ResolveTasks
          weeklyPlan={weeklyPlan}
          day={day}
          week={this.state.week}
          objHash={this.state.wheelTreeHash}
          taskHash={this.state.taskHash}
          onTasksResolved={this.handleTasksResolved}
        />;
        break;
      }
    }
    return (
      <div className='daily-planning'>
        {dpHeader}
        <div>
          <CheckSet useButtons={options} value={page} onChange={this.handleSetPage}/>
        </div>
        {contents}
      </div>
    )
  }
}

export default withContext(DailyPlanning);
