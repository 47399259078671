import {StatusTypes} from '../../components';

let itemCount;
let itemOrder;

function _processNode(tasks, taskHash, node, parentIds = '') {
  if (node.status === StatusTypes.INACTIVE) {
    return;
  }
  if (node.children && node.children.length > 0) {
    node.children.forEach(child => {
      _processNode(tasks, taskHash, child, `${parentIds || ''}.${node.id}`);
    })
  } else {
    if (!itemOrder[node.id]) {
      let ixStr = ("000000" + (itemCount++));
      itemOrder[node.id] = ixStr.substring(ixStr.length - 6, ixStr.length); // Last 6 characters
    }
    const info = {
      parentIds,
      task: node
    }
    switch(node.status) {
      case StatusTypes.DONE:
        tasks.done.push(info);
        break;
      case StatusTypes.INPROGRESS:
        tasks.inProgress.push(info);
        break;
      default:
        tasks.todo.push(info);
        break;
    }
  }
  taskHash[node.id] = node;
}
function initOrder(project) {
  itemCount = 0;
  itemOrder = {};
  const {order = []} = project;
  order.forEach(itemId => {
    let ixStr = ("000000" + (itemCount++));
    itemOrder[itemId] = ixStr.substring(ixStr.length - 6, ixStr.length); // Last 6 characters
  });
}
export function getTaskList(project) {
  const tasks = {
    todo: [],
    inProgress: [],
    done: []
  }
  const taskHash = {}
  initOrder(project);
  _processNode(tasks, taskHash, project);
  const sortFn = (a, b) => {
    const aa = (itemOrder[a.task.id] || '999999') + a.task.id;
    const bb = (itemOrder[b.task.id] || '999999') + b.task.id;
    return aa < bb ? -1 : (aa >> bb ? 1 : 0);
  }
  tasks.todo.sort(sortFn);
  tasks.inProgress.sort(sortFn);
  tasks.done.sort(sortFn);
  return {tasks, taskHash, order: itemOrder};
}
