/* eslint-disable react/jsx-pascal-case */
import React, {Component, Fragment} from 'react';
import {Button, Input, InputSizes, DropDown} from '../../components';
import {CommonTaskTypes} from '../../data/store/commonTaskTemplate';
import Recurrance from './Recurrance';

import './CommonTaskObject.scss';

const timeFrameChoices = [
  {
    value: 'none',
    label: 'No duration'
  },
  {
    value: 'mins',
    label: 'Minutes'
  }
];

function _DatedBody(props) {
  function handleChange(evt) {
    const {id, value} = evt.target;
    const newGoal = Object.assign({}, props.value || {});
    newGoal[id] = value;
    props.onChange(newGoal);
  }

  const {goalDate} = props.value;
  const verb = {
    [CommonTaskTypes.ACCOMPLISHMENT]: "accomplish",
    [CommonTaskTypes.TRAIT]: "acheive trait",
    [CommonTaskTypes.EXPERIENCE]: "have experience"

  }[props.type];
  const desc = `\u00A0 (date to ${verb} by)`
  return (
    <Fragment>
      <div className='form-elem'>
        <Input
          id='goalDate'
          label='Goal Date'
          size={InputSizes.MEDIUM}
          onChange={handleChange}
          value={goalDate}
          isRequired={props.isRequired}
        /> {desc}
      </div>
    </Fragment>
  )
}

function _PracticeBody(props) {
  function handleChange(evt) {
    const {id, value} = evt.target;
    const newGoal = Object.assign({}, props.value || {});
    newGoal[id] = value;
    props.onChange(newGoal);
  }

  const {duration, durationUnit = 'mins'} = props.value;
  const isNone = durationUnit === 'none';
  const ddLabel = isNone ? 'Duration' : undefined;

  return (
    <Fragment>
      <div className='form-elem'>
        {isNone ?
          null
        :
          <Input
            id='duration'
            label='Duration'
            onChange={handleChange}
            size={InputSizes.SMALL}
            value={duration}
          />
        }
        <DropDown
          id='durationUnit'
          label={ddLabel}
          choices={timeFrameChoices}
          onChange={handleChange}
          value={durationUnit}
        />
      </div>
      <Recurrance value={props.value} onChange={handleChange} />
    </Fragment>
  )
}

function _Body (props) {
  if (props.type === CommonTaskTypes.PRACTICE) {
    return <_PracticeBody {...props}/>
  }
  return <_DatedBody {...props}/>
}

class Goal extends Component {
  handleChange = (goal) => {
    if (this.props.onChange) {
      const evt = {
        target: {
          id: this.props.id,
          value: goal
        }
      }
      this.props.onChange(evt);
    }
  }
  toggleShowGoal = () => {
    const newGoal = Object.assign({}, this.props.value || {});
    newGoal.hasGoal = !newGoal.hasGoal;
    this.handleChange(newGoal);
  }

  render() {
    const value = this.props.value || {}
    if (value.hasGoal) {
      return (
        <Fragment>
          <_Body type={this.props.type} onChange={this.handleChange} value={value}/>
          <div className='form-elem'>
            <Button buttonStyle='small blue' onClick={this.toggleShowGoal}>Remove Goal</Button>
          </div>
        </Fragment>
      )
    }

    return (
      <div className='form-elem'>
        <span className='link' onClick={this.toggleShowGoal}>Add Goal</span>
      </div>
    )
  }
}

export default Goal;
