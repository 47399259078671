import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Input, InputSizes} from '../index';
import InputClock from './InputClock';
import formElement from '../util/formElement';
import {dateToTimeStr, dayToDate, dateToDay, parseTime} from '../../util/dateHelper';
import './InputTime.scss';

class InputTime extends Component {
  constructor (props) {
    super(props);
    const day = dateToDay(props.value);
    this.state = {
      focus: false,
      clockFocus: false,
      clickClock: false,  // needed because of timing. text blur comes before clock click
      day,
    }
  }
  handleFocus = (evt) => {
    this.setState({
      focus: true,
    })
  }

  handleBlur = (evt) => {
    const handler = () => {
      this.setState({
        focus: false,
      })
    }
    setTimeout(handler, 0);
  }

  handleClockFocus = (evt) => {
    this.setState({
      clockFocus: true,
      clickClock: false,
    })
  }

  handleClockBlur = (evt) => {
    this.setState({
      clockFocus: false,
    })
  }

  handleClickClock = (evt) => {
    this.setState({
      clickClock: true,
    })
  }
  handleChange = (evt) => {
    const {day} = this.state;
    const {value: propVal, onChange} = this.props;
    const {value} = evt.target;
    const timeMillis = parseTime(value);
    const date = dayToDate(day, timeMillis);
    onChange && onChange(date);
  }
  render () {
    const {props} = this;
    const {value, onChange, alignClock, ...otherProps} = props;
    const {focus, clockFocus, clickClock} = this.state;
    const valueStr = dateToTimeStr(value, true);
    return (
      <span className="input-time-container">
        <div className="time-input"
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          tabIndex="19"
          {...otherProps}
          onChange = {this.handleChange}>
            {valueStr}
        </div>
        {focus || clockFocus || clickClock ?
          <InputClock
            align={alignClock}
            value={value}
            onChange={this.handleChange}
            onFocus={this.handleClockFocus}
            onBlur={this.handleClockBlur}
            tabIndex="20"
            onClick={this.handleClickClock}
          />
        :
          null
        }
      </span>
    )
  }
}

export default InputTime;
