import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Form, Button, Input, InputSizes} from '../../components';
import './Projects.scss';

class ProjectInfo extends Component {
  static propTypes = {
    edit: PropTypes.object,
    project: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onRequestEdit: PropTypes.func
  }
  renderReadInfo() {
    const editProps = this.props.onRequestEdit ?
      {
        className: 'can-edit',
        onClick: this.props.onRequestEdit
      }
      : null;
    return (
      <Fragment>
        <div className='form-elem'>
          <span className="input">
            <label>Name</label>
            <span {...editProps}>{this.props.project.name}</span>
          </span>
        </div>
      </Fragment>
    )
  }
  handleChange = (evt) => {
    const {id, value} = evt.target;
    if (this.props.onChange) {
      this.props.onChange({[id]: value});
    }
  }
  renderEditInfo() {
    const {name, desc} = this.props.edit;
    return (
      <Form onSubmit={this.props.onSubmit}>
        <div className='form-elem'>
          <Input label='Name' id='name' onChange={this.handleChange} value={name} />
        </div>
        <div className='form-elem'>
          <Input
            id='desc'
            label='Description'
            value={desc}
            size={InputSizes.LARGE}
            onChange={this.handleChange}
          />
        </div>
        <div className='form-elem'>
          <Button onClick={this.props.onCancel} buttonStyle="medium grey">Cancel</Button>
          <Button buttonStyle="medium">Save</Button>
        </div>
      </Form>
    )
  }
  render() {
    const body = this.props.edit ? this.renderEditInfo() : this.renderReadInfo();
    return (
      <div className="project-info">
        {body}
      </div>
    )
  }
}

export default ProjectInfo;
