import React, {Component} from 'react';
import {withContext} from '../../util/context';
import {editObject} from '../../util/editObject';
import {localDateFromDateStr, setTime, dateToTimeStr, addDays, getISOString} from '../../util/dateHelper';
import {Form, Input, Button, DatePicker} from '../../components';
import {InputSizes} from '../../components/input/Input';
import PropTypes from 'prop-types';
import './events.scss';

class EditEvent extends Component {
  static propTypes = {
    value: PropTypes.object.isRequired
  }

  handleUpdate = (evt) => {
    const {id, value} = evt.target;
    if (this.props.onChange) {
      this.props.onChange({[id]: value});
    }
  }
  handleSubmit = () => {
    this.props.onSubmit();
  }
  handleCancel = (evt) => {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  render() {
    const value = this.props.editValue;
    const {dow, startDate, startTime, endTime, summary, description} = value || {};
    return (
      <div className='edit-event'>
        <Form onSubmit={this.handleSubmit}>
          edit event
          <div className='form-elem'>
            <DatePicker
              id="startDate"
              label="Date"
              value={startDate}
              onChange={this.handleUpdate}
            /> {dow}
          </div>
          <div className='form-elem'>
            <Input
              id='startTime'
              label="Start"
              value={startTime}
              size={InputSizes.SMALL3}
              placeHolder="hh:mm"
              onChange={this.handleUpdate}
            />
            <span> - </span>
            <Input
              id='endTime'
              label="End"
              value={endTime}
              size={InputSizes.SMALL3}
              placeHolder="hh:mm"
              onChange={this.handleUpdate}
            />
          </div>
          <div className='form-elem'>
            <Input
              id="summary"
              label="Summary"
              value={summary}
              size={InputSizes.MEDIUM3}
              onChange={this.handleUpdate}
            />
          </div>
          <div className='form-elem'>
            <Input
              id="description"
              label="Description"
              value={description}
              size={InputSizes.LARGE}
              onChange={this.handleUpdate}
            />
          </div>
          <Button onClick={this.handleCancel}>Cancel</Button>
          <Button>Submit</Button>
        </Form>
      </div>
    )
  }
}

const editToEvent = (edit, originalEvent) => {
  const {
    summary,
    description,
    startDate,
    startTime,
    endTime
  } = edit;
  const newStart = getISOString(setTime(startDate, startTime));
  let newEnd = getISOString(setTime(startDate, endTime));
  if (newStart > newEnd) {
    newEnd = addDays(newEnd, 1);
  }
  const event = Object.assign({}, originalEvent, {/*start, end*/ summary, description});
  Object.assign(originalEvent.start, {dateTime: newStart})
  Object.assign(originalEvent.end, {dateTime: newEnd})
  return event;
}

const eventToEdit = (event) => {
  const {id, start, end, summary, description} = event;
  const startDate = localDateFromDateStr(start.dateTime);
  // const startStr = startDate.toLocaleString('en-US', {
  //   weekday: 'short',
  //   month: 'numeric',
  //   day: 'numeric',
  //   year: 'numeric'
  // });

  const startTime = dateToTimeStr(startDate, true);
  const endTime = dateToTimeStr(new Date(end.dateTime), true);
  return {
    id,
    summary,
    description,
    startDate,
    startTime,
    endTime
  };
}

export default withContext(editObject(EditEvent, eventToEdit, editToEvent));
