import React, {Component, Fragment} from 'react';
import {Button, CheckSet} from '../../components';
import {formatDay} from '../../util/dateHelper';
import {getInstancesRemaining, getStatusOfDay, getDailyPlan, getInstanceId, getInstanceOf} from '../../util/taskHelper';
import WeeklyPlan from '../../data/store/weeklyPlan';
import {registerProjectTreeListener} from '../../data/projectTree';
import AllCommonTasks from '../../data/store/allCommonTasks';
import './DailyPlanning.scss';

class ResolveTasks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonState: this.getButtonState()
    }
    this.unregisterProjectTreeListener = registerProjectTreeListener((projectTree, projectTreeHash) => {
      this.setState({
        projectTree,
        projectTreeHash
      })
    })
  }
  componentWillUnmount() {
    if (this.unregisterProjectTreeListener) this.unregisterProjectTreeListener();
  }
  getButtonState(weeklyPlan = this.props.weeklyPlan, day = this.props.day) {
    const dailyTasks = this.getDailyTasks();
    const buttonState = {};
    dailyTasks.forEach(task => {
      const {resolution} = task;
      const idinst = getInstanceId(task);
      buttonState[idinst] = resolution;
    })
    return buttonState;
  }
  getDocForTask(task) {
    const {id, iof} = task;
    // Just try them all...
    return (this.props.objHash[iof] && this.props.objHash[iof].doc)
      || (this.state.projectTreeHash && this.state.projectTreeHash[iof])
      || (this.props.taskHash && this.props.taskHash[iof])
      || (this.props.objHash[id] && this.props.objHash[id].doc)
      || (this.state.projectTreeHash && this.state.projectTreeHash[id])
      || (this.props.taskHash && this.props.taskHash[id])
      || {
        name: '(unknown)'
      };
  }
  getWeeklyTasks() {
    return this.props.weeklyPlan.tasks || [];
  }
  getDailyTasks(day = this.props.day) {
    return getDailyPlan(this.props.weeklyPlan, day).tasks || []
  }
  handleTaskButton = (evt) => {
    const buttonState = Object.assign({}, this.state.buttonState);
    const [button, id] = evt.target.id.split("-");
    buttonState[id] = button;
    this.setState({buttonState});
  }
  handleClick = (evt) => {
    const {id, value} = evt.target;
    const buttonState = Object.assign({}, this.state.buttonState);
    buttonState[id] = value;
    this.setState({buttonState});
  }
  handleDone = (evt) => {
    const {day, onTasksResolved} = this.props;
    this.handleWeeklyPlan(day);
    this.updateObjectives(day);
    onTasksResolved && onTasksResolved(day);
  }
  updateObjectives (day) {
    const dailyTasks = this.getDailyTasks(day);
    dailyTasks.forEach( dailyTask => {
      const iof = getInstanceOf(dailyTask);
      const resolution =  this.state.buttonState[getInstanceId(dailyTask)];
      if (resolution==='done') {
        const doc = (this.props.objHash[iof] && this.props.objHash[iof].doc);
        if (doc) {
          doc.lastCompleted = day;
          AllCommonTasks.putDocument(iof, doc);
        }
      }
    })
  }
  handleWeeklyPlan(day) {
    const newDailyTasks = this.getDailyTasks(day).slice();
    const newWeeklyTasks = this.props.weeklyPlan.tasks.slice();

    newDailyTasks.forEach((dailyTask, ix) => {
      // update resolution in daily task list
      const newDailyTask = Object.assign({}, dailyTask);
      const resolution =  this.state.buttonState[getInstanceId(dailyTask)];
      newDailyTask.resolution = resolution;
      newDailyTasks[ix] = newDailyTask;

      // update occurance in weekly task list
      const wtix = newWeeklyTasks.findIndex(weeklyTask => weeklyTask.id === getInstanceOf(dailyTask));
      if (wtix<0) {
        throw new Error('cant find weekly task')
      }
      const newWeeklyTask = Object.assign({}, newWeeklyTasks[wtix]);
      const occurances = [...newWeeklyTask.occurances];
      occurances.push({day,r:resolution});
      newWeeklyTask.occurances = occurances;
      newWeeklyTasks[wtix] = newWeeklyTask;
    })
    const newDailyPlan = Object.assign({}, getDailyPlan(this.props.weeklyPlan, this.props.day));
    newDailyPlan.tasks = newDailyTasks;
    newDailyPlan.status = 'done';
    const newWeeklyPlan = Object.assign({}, this.props.weeklyPlan);
    newWeeklyPlan.days[day] = newDailyPlan;
    newWeeklyPlan.tasks = newWeeklyTasks;
    WeeklyPlan.putDocument(this.props.weeklyPlan.week, newWeeklyPlan);
  }
  renderTasks() {
    const ret = [];
    let allChecked = true;
    const daysLeft = 7 - this.props.day + this.props.weeklyPlan.week;
    this.getDailyTasks().forEach(task => {
      const id = getInstanceId(task);
      const iof = getInstanceOf(task);
      const weeklyTask = this.getWeeklyTasks().find(wt => wt.id === iof);
      const {name} = this.getDocForTask(weeklyTask);
      const buttonState = this.state.buttonState[id];
      const closeClicked = buttonState === 'close' ? 'checked' : '';
      const doneClicked = buttonState === 'done' ? 'checked' : '';
      const forwardClicked = buttonState === 'forward' ? 'checked' : '';
      if (!buttonState) {
        allChecked = false;
      }

      const canDefer = (daysLeft > getInstancesRemaining(weeklyTask) || parseInt(weeklyTask.count) > 7);
      const idSet = ['done', 'close'];
      if (canDefer) idSet.push('forward');
      const checkStyle = {
        marginRight: '5px',
        border: '1px solid #DDD',
        borderRadius: '4px',
        padding: '2px',
      }
      ret.push(
        <div className='row' key={id}>
          <div className='cell top'>
            <CheckSet id={id} idSet={idSet} useImages='-gray' value={buttonState} onChange={this.handleClick} checkStyle={checkStyle} nowrap={true}/>
          </div>
          <div className='cell top'>
            <div key={id}>{name} {task.instance}</div>
          </div>
        </div>
      )
    })
    if (allChecked) {
      ret.push(<div><Button onClick={this.handleDone}>Done</Button></div>)
    }
    return ret;
  }
  renderCompletedTasks() {
    const ret = [];
    this.getDailyTasks().forEach(task => {
      const iof = getInstanceOf(task);
      const iid = getInstanceId(task);
      const instanceCount = task.instance || '';
      const weeklyTask = this.getWeeklyTasks().find(wt => wt.id === iof);
      const {name} = this.getDocForTask(weeklyTask);
      ret.push(
        <div className='row' key={iid}>
          <div className='cell'>
            <div key={iid}>{name} {task.instanceCount}</div>
          </div>
          <div className='cell'>
             {task.resolution}
          </div>
        </div>
      )
    })
    return ret;
  }
  render () {
    if (!this.props.objHash) {
      return (
        <div>loading...</div>
      )
    }
    const status = getStatusOfDay(this.props.weeklyPlan, this.props.day);
    return (
      <div className='resolve-tasks'>
        <div className="section">Tasks from {formatDay(this.props.day)}</div>
        {status === 'open' ?
          <Fragment>
            <div>How did your tasks go?</div>
            {this.renderTasks()}
          </Fragment>
        : (status === 'done' ?
          this.renderCompletedTasks()
        :
          <div>None</div>
        )}
      </div>
    )
  }
}

export default ResolveTasks;
