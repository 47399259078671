import { formatDay } from '../../util/dateHelper';
import { CommonTaskTypes } from '../../data/store/commonTaskTemplate';
import { FREQUENCY } from '../../util/constants';

function computeTaskHash(tasks) {
  const ret = {};
  if (tasks) {
    tasks.forEach(task => {
      ret[task.id] = task;
    })
  }
  return ret;
}

function getLastCompleted(doc) {
  let showLC = true;
  const { goal, lastCompleted } = doc;
  if (goal && !(
    goal.frequencyUnit === FREQUENCY.MONTH ||
    goal.frequencyUnit === FREQUENCY.QUARTER ||
    goal.frequencyUnit === FREQUENCY.YEAR)) {
    showLC = true;
  }
  if (showLC) {
    if (lastCompleted) {
      return `(last ${formatDay(lastCompleted)})`
    }
    return '(never completed)';
  }
  return '';
}

function processWheelTreeNode(node, lists, taskHash, infoNeeded) {
  const showAll = false;
  if (!node.doc || node.doc.status !== 'inactive') {
    if (node.doc && node.doc.objType && (showAll || !node.children || node.children.length === 0)) {
      const { id } = node;
      const { objType, goal, name } = node.doc;
      const lastCompleted = getLastCompleted(node.doc);
      const descriptor = { id, ...goal, name, lastCompleted, wheelTreeNode: node, goal };
      const thisInfoNeeded = infoNeeded.find(obj => obj.id === id);
      if (taskHash[id]) {
        const selected = Object.assign({ name }, taskHash[id]);
        lists.selected.push(selected);
      } else if (thisInfoNeeded) {
        descriptor.infoNeeded = thisInfoNeeded;
        lists.infoNeeded.push(descriptor);
      } else if (goal && objType === CommonTaskTypes.PRACTICE && goal.frequency && goal.frequencyUnit) {
        lists.practicesWGoal.push(descriptor);
      } else {
        lists.objectives.push(descriptor);
      }
    }
    if (node.children) {
      node.children.forEach(child => {
        processWheelTreeNode(child, lists, taskHash, infoNeeded);
      })
    }
  }
}

function processProjectList(projects, lists, taskHash, infoNeeded) {
  if (projects.forEach) {
    projects.forEach(project => {
      const { id, doc: { name } } = project;
      const descriptor = { id, name };
      const thisInfoNeeded = infoNeeded.find(obj => obj.id === id);
      if (taskHash[id]) {
        const selected = Object.assign({ name }, taskHash[id]);
        lists.selected.push(selected);
      } else if (thisInfoNeeded) {
        descriptor.infoNeeded = thisInfoNeeded;
        lists.infoNeeded.push(descriptor);
      } else {
        lists.projects.push(descriptor);
      }
    })
  }
}

function processTodos(todoList, lists, taskHash, infoNeeded) {
  if (todoList && todoList.forEach) {
    todoList.forEach(task => {
      const { id, name, status = 'active', lastCompleted, goal } = task;
      if (status === 'active' && !lastCompleted) {
        const descriptor = { id, name, goal }
        const thisInfoNeeded = infoNeeded.find(obj => obj.id === id);
        if (taskHash[id]) {
          const selected = Object.assign({ name }, taskHash[id]);
          lists.selected.push(selected);
        } else if (thisInfoNeeded) {
          descriptor.infoNeeded = thisInfoNeeded;
          lists.infoNeeded.push(descriptor);
        } else {
          lists.todoList.push(descriptor);
        }
      }
    })
  }
}

export function getTaskCandidates(tasks, wheelTree, projects, todoList, infoNeeded) {
  const lists = {
    selected: [],
    infoNeeded: [],
    practicesWGoal: [],
    todoList: [],
    objectives: [],
    projects: []
  }
  const taskHash = computeTaskHash(tasks);
  processWheelTreeNode(wheelTree || {}, lists, taskHash, infoNeeded);
  processProjectList(projects, lists, taskHash, infoNeeded);
  processTodos(todoList, lists, taskHash, infoNeeded);
  return lists;
}

export function getNewElem(wheelTreeHash, elemId, source) {
  let newElem;
  switch (source) {
    case 'objectives': {
      const objective = wheelTreeHash[elemId];
      if (objective && objective.doc && objective.doc.goal) {
        const { duration = 0, durationUnit = 'mins', frequency, frequencyUnit, frequencyOn = '' } = objective.doc.goal;
        const count = frequencyUnit === 'day' ? frequency * 7 : (frequencyUnit === 'week' ? frequency : 1);
        if (frequency && (duration || durationUnit === 'none')) {
          newElem = {
            id: elemId,
            source,
            count,
            occurances: [],
            duration,
            durationUnit,
            frequencyOn
          }
        }
      }
      break;
    }
    default: {
      break;
    }
  }
  return newElem;
}