import React, {Component} from "react";
import {Link} from 'react-router-dom';
import Tasks from '../../data/store/tasks';
import {ROUTES} from '../../util/constants';
import {CheckBox} from '../../components';
import './Todos.scss';

class TodosPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      taskList: [],
      showInactive: false,
    }
  }
  componentDidMount() {
    const tasks = Tasks.getCollectionRef();
    if (this.unsubscribe) this.unsubscribe();
    this.unsubscribe = tasks.onSnapshot((results) => {
      const taskList = [];
      results.forEach( docRef => {
        const doc = Object.assign({}, docRef.data());
        doc.id = docRef.id;
        taskList.push(doc);
      })
      this.setState({
        taskList
      })
    })
  }
  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }
  handleTaskClick = (evt) => {
    let id = evt.target.id || evt.target.parentNode.id;
    this.props.history.push(`${ROUTES.TODO}/${id}`)
  }

  renderTasks() {
    const {showInactive} = this.state;
    const handleClick = (evt) => {
      this.setState({showInactive: !showInactive});
      evt.stopPropagation();
    }
    const ret = [];
    this.state.taskList.forEach(doc => {
      const disabled = (doc.status === 'inactive' || doc.lastCompleted) ? ' disabled' : '';
      if (!disabled || showInactive) {
        ret.push(
          <div key={doc.id}>
            <div className={`task${disabled}`} id={doc.id} onClick={this.handleTaskClick}>{doc.name}</div>
          </div>
        )
      }
    })
    return (
      <>
      <div key='toggle-inactive'>
        <CheckBox label="show inactive" value={showInactive} onClick={handleClick}/>
      </div>
      {ret}
      </>
    )
  }

  render() {
    return (
      <div className='tasks'>
        <div className='row heading'>
          <div className='cell'>Name</div>
          <div className='cell'>Description</div>
        </div>
        {this.renderTasks()}
          <div className='row footer'>
            <div className='cell'></div>
            <div className='cell'>
              <Link to={ROUTES.TODO}>Add</Link>
            </div>
          </div>
      </div>
    )
  }
}

export default TodosPage;
