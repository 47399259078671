import React, {Component} from 'react';
import {CommonTaskObject} from '../../components';
import {ROUTES} from '../../util/constants';
import Objectives from '../../data/store/objectives';
import {CommonTaskTypes} from '../../data/store/commonTaskTemplate';

class Task extends Component {
  render() {
    return <CommonTaskObject
      {...this.props}
      defaultType={CommonTaskTypes.ACCOMPLISHMENT}
      store={Objectives}
      returnRoute={ROUTES.OBJECTIVES}
    />
  }
}

export default Task;
