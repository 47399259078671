import React, {Component} from 'react';
import './App.scss';
import {Link} from 'react-router-dom';
import {ROUTES} from '../../util/constants';
import {withContext} from '../../util/context';
import {resetAuth} from '../../util/fsGlobals';

class Header extends Component {
  signOut = () => {
    this.props.context.firebaseWrapper.signOut();
    resetAuth();
  }

  render() {
    const isSignedIn = Boolean(this.props.context.authUser);
    return (
      <header className="App-header">
        <div className="left">
          {
            isSignedIn ?
              <Link to={ROUTES.HOME}>
                <div className="option link">Home</div>
              </Link>
            :
              <Link to={ROUTES.LANDING}>
                <div className="option link">Home</div>
              </Link>
          }
        </div>
        <div className="right">
          {
            isSignedIn ?
              <div className="option link" onClick={this.signOut}>sign out</div>
            :
              <Link to={ROUTES.SIGN_IN}>
                <div className="option link">sign in</div>
              </Link>
          }
        </div>
      </header>
    );
  }
}

export default withContext(Header);
