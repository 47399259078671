import React, {Component, Fragment} from 'react';
import {Form, DropDown, Input, Button, RadioGroup, DayRangePicker} from '../../components';
import {InputSizes} from '../../components/input/Input';
import {getCoveredChoices, getIncExChoices, getTypeChoices, getTypeHash, PeriodCovered, BlockType, IncEx} from './weekBlockerConstants';
import './WeekBlocker.scss';

function _newBlock(props) {
  const {showType, showLineDesc, line, onChange, onSave, onDelete, onCancel, incEx, editId} = props;
  const handleChange = (evt) => {onChange(editId, evt)};
  const handleCancel = (evt) => {onCancel(editId, evt)};
  const handleSave = (evt) => {onSave(editId, evt)};
  const handleDelete = (evt) => {onDelete(editId, evt)};
  const saveLabel = editId ? 'Save' : 'Add';
  return (
    <Fragment>
      <div className="row">
        <div className="cell"><DayRangePicker id='days' value={line.days} onChange={handleChange}/></div>
        <div className="cell"><Input id='start' value={line.start} size={InputSizes.SMALL3} placeHolder="hh:mm" onChange={handleChange}/></div>
        <div className="cell"><Input id='end' value={line.end} size={InputSizes.SMALL3} placeHolder="hh:mm" onChange={handleChange}/></div>
        {showType ? <div className="cell"><DropDown id='type' choices={getTypeChoices(incEx)} value={line.type} onChange={handleChange}/></div> : null}
        {showType ? <div className="cell">
          {showLineDesc ? <Input id='desc' value={line.desc} size={InputSizes.MEDIUM1} onChange={handleChange} /> : null}
        </div> : null}
        <div className="cell">
          <div className="align-button">
            <Button onClick={handleCancel} buttonStyle="medium blue">Cancel</Button>
            <Button onClick={handleSave} buttonStyle="medium blue">{saveLabel}</Button>
            {editId ?
              <Button id={`d-${editId}`} onClick={handleDelete} buttonStyle="medium blue">Delete</Button>
            :
              null
            }
          </div>
        </div>
    </div>
    </Fragment>
  )
}

class WeekBlocker extends Component {
  constructor(props) {
    super(props);
    const newLine = this.getResetNewLine();
    this.state = {
      ...newLine,
      editState: 'read'
    };
  }
  getMode() {

  }
  getResetNewLine() {
    const {showType} = this.getShowHide();
    const type = showType ? ((this.props.data.incEx === IncEx.EXCLUSIVE) ? BlockType.OTHEREX : BlockType.OTHERINC) : BlockType.WORK;
    const ret = {
      showNewLine: false,
      newLine: {
        days: undefined,
        start: undefined,
        end: undefined,
        type,
        desc: ""
      },
      editLine: undefined
    }
    return ret;
  }
  getCoveredType() {
    const showIncEx = this.props.data.covered === PeriodCovered.HOME;
    return  showIncEx ? this.props.data.incEx : this.props.data.covered
  }
  getShowHide(line = {}) {

    const ret = {
      showType: this.props.data.covered === PeriodCovered.HOME,
      showLineDesc: line.type === BlockType.OTHERINC ||  line.type === BlockType.OTHEREXC,
      showIncEx: this.props.data.covered === PeriodCovered.HOME
    }
    // The following indicates which BlockTypes to show given 'covered' choices user made
    switch (this.getCoveredType()) {
      case PeriodCovered.WORK: {
        ret[BlockType.WORK] = true;
        break;
      }
      case PeriodCovered.ALL: {
        ret[BlockType.SLEEP] = true;
        break;
      }
      case IncEx.INCLUSIVE: {
      ret[BlockType.OTHERINC] = true;
        break;
      }
      case IncEx.EXCLUSIVE: {
        ret[BlockType.WORK] = true;
        ret[BlockType.SLEEP] = true;
        ret[BlockType.OTHEREXC] = true;
        break;
      }
      default: {
        break;
      }
    }
    return ret;
  }
  handleCoveredChange = (evt) => {
    this.updateData({
      covered: evt.target.value
    })
  }
  handleIncExChange = (evt) => {
    this.updateData({
      incEx: evt.target.value
    })
  }
  renderCovered() {
      const {showIncEx} = this.getShowHide();
      return (
        <Fragment>
          <DropDown
            id='covered'
            choices={getCoveredChoices()}
            style={{
              marginLeft: '10px'
            }}
            onChange={this.handleCoveredChange}
            value={this.props.data.covered || ''}
            isRequired={true}
          />
        {showIncEx ?
          <RadioGroup
            id='incEx'
            choices={getIncExChoices()}
            onChange={this.handleIncExChange}
            value={this.props.data.incEx}
          />
        :
          null
        }
       </Fragment>
      )
  }
  handleAddBlockRow = () => {
    this.setState({
      showNewLine: true
    })
  }
  updateData(newDataItems) {
    this.setState({editState: 'edit'});
    const newData = Object.assign({}, this.props.data, newDataItems);
    this.props.onChange(newData);
  }
  handleRowClick = (evt) => {
    const {target} = evt;
    const id = parseInt(target.id || target.parentElement.id);
    const line = this.props.data.blockList[id];
    const editLine = Object.assign({}, line);
    editLine.id = id;
    this.setState({
      editLine
    })
  }
  renderLines(editMode) {
    const lines = [];
    const editClass = editMode ? ' edit' : '';
    if (this.props.data.blockList) {
      this.props.data.blockList.forEach((line, ix) => {
        const showHide = this.getShowHide(line);
        if (showHide[line.type]) {
          if (this.state.editLine && this.state.editLine.id===ix) {
            lines.push(this.renderEditBlock());
          } else {
            const type = getTypeHash(this.props.data.incEx)[line.type] || '';
            lines.push(
              <div className={`row wb-row${editClass}`} onClick={this.handleRowClick} id={ix} key={`${line.days}${line.start}`}>
                <div className="cell">{line.days}</div>
                <div className="cell">{line.start}</div>
                <div className="cell">{line.end}</div>
                <div className="cell">{type}</div>
                <div className="cell">{line.desc}</div>
              </div>
            )
          }
        }
      })
    }
    return lines;
  }
  handleAdd = () => {
    const blockList = (this.props.data.blockList || []).slice();
    const newLine = Object.assign({}, this.state.newLine);
    if (this.props.data.covered === PeriodCovered.ALL) {
      newLine.type = BlockType.SLEEP;
    }
    if (this.props.data.covered === PeriodCovered.WORK) {
      newLine.type = BlockType.WORK;
    }
    blockList.push(newLine);
    this.updateData({blockList})
    this.setState(this.getResetNewLine());
  }
  handleSave = (editId) => {
    const blockList = (this.props.data.blockList || []).slice();
    const editLine = Object.assign({}, this.state.editLine);
    blockList.splice(editId,1,editLine);
    this.updateData({blockList})
    this.setState({editLine: undefined});
  }
  handleEditCancel = (editId) => {
    this.setState({editLine: undefined});
  }
  handleEditDelete = (editId) => {
    if (editId) {
      const blockList = (this.props.data.blockList || []).slice();
      blockList.splice(editId,1);
      this.updateData({blockList})
      this.setState({editLine: undefined});
    }
  }
  handleEditChange = (lineId, evt) => {
    const {id, value} = evt.target;
    const nameInState = lineId ? 'editLine' : 'newLine';
    const newLine = Object.assign({}, this.state[nameInState]);
    newLine[id] = value;
    this.setState({
      [nameInState]: newLine
    })
  }
  renderEditBlock() {
    const line = this.state.editLine;
    const {showType, showLineDesc} = this.getShowHide(line);
    const incEx = this.props.data.incEx;
    return <_newBlock
      showType={showType}
      showLineDesc={showLineDesc}
      line={line}
      incEx={incEx}
      editId={line.id}
      onChange={this.handleEditChange}
      onDelete={this.handleEditDelete}
      onCancel={this.handleEditCancel}
      onSave={this.handleSave}
    />
  }
  renderNewBlock() {
    const {showType, showLineDesc} = this.getShowHide(this.state.newLine);
    const line = this.state.newLine;
    const incEx = this.props.data.incEx;
    return <_newBlock
      showType={showType}
      showLineDesc={showLineDesc}
      line={line}
      incEx={incEx}
      onChange={this.handleEditChange}
      onSave={this.handleAdd}
    />
  }
  renderBlockLines(editMode = true) {
    const {showType, showIncEx} = this.getShowHide();
    const messageIx = showIncEx ? this.props.data.incEx : this.props.data.covered;
    if (!messageIx) {
      return null;
    }
    const prompt = {
      [PeriodCovered.WORK]: "Enter typical work hours.",
      [PeriodCovered.ALL]: "Enter typical sleep schedule.",
      [IncEx.EXCLUSIVE]: "Enter the hours you with to ignore for each day.",
      [IncEx.INCLUSIVE]: "Enter the hours you wish to schedule for each day."
    }[messageIx];
    return (
      <Form onSubmit={this.handleAdd}>
        <div className="heading">
          {prompt}
        </div>
        <div className="row heading">
          <div className="cell">Days</div>
          <div className="cell">Start</div>
          <div className="cell">End</div>
          {showType ? <div className="cell">Type</div> : null}
          {showType ? <div className="cell">Desc</div> : null}
        </div>
        {this.renderLines(editMode)}
        {editMode ? this.renderNewBlock() : null}
      </Form>
    )
  }
  handleDone = (evt) => {
    this.setState({
    editState: 'read'
    })
    evt.stopPropagation();
  }
  handleEdit = (evt) => {
    this.setState({
      editState: 'edit'
    })
    evt.stopPropagation();
  }
  getEditState() {
    if (this.props.editState) {
      return this.props.editState;
    }
    if (this.props.data._waitingForData) {
      return 'loading'
    }
    if (Object.keys(this.props.data).length === 0) {
      return 'edit';
    }
    return this.state.editState;
  }
  showEdit() {
    return (
      <Fragment>

        <div>
          What time do you want to schedule?
          {this.renderCovered()}
        </div>
        {this.renderBlockLines()}
        {this.props.editState ? null :
          <div className="button-row">
            <Button buttonStyle="medium" onClick={this.handleDone}>Done</Button>
          </div>
        }
      </Fragment>
    )
  }
  showReadOnly() {
      return (
        <div>
          {this.renderBlockLines(false)}
          {this.props.editState ? null :
            <div>
              <Button buttonStyle="medium" onClick={this.handleEdit}>Edit</Button>
            </div>
          }
        </div>
      )
  }
  render() {
    const editState = this.getEditState();
    if (editState === 'loading') {
      return (
        <div className='week-blocker'>
          loading...
        </div>
      )
    }
    return (
      <div className='week-blocker'>
            {editState === 'edit' ?
              this.showEdit()
            :
              this.showReadOnly()
            }

      </div>
    )
  }
}

export default WeekBlocker;
