import React, {Component} from 'react';
import ProjectBoardColumn from './ProjectBoardColumn';
import {DragAndDrop} from '../../util/dnd';
import {getTaskList} from './ProjectBoardUtil';
import {StatusTypes} from '../../components';
import './ProjectBoard.scss';

class ProjectBoard extends Component {
  // _removeFromList(list, dragId) {
  //   let rix = -1
  //   list.forEach((item, ix) => {
  //     if (dragId===item.task.id) {
  //       // Found it...
  //       rix = ix;
  //     }
  //   })
  //   if (rix >=0 ) {
  //     list.splice(rix,1);
  //     return true;
  //   }
  //   return true; // Keep looking
  // }
  updateOrder(project, order) {
    const ids = Object.keys(order);
    const sortFn = (a, b) => {
      const aa = (order[a] || '999999') + a;
      const bb = (order[b] || '999999') + b;
      return aa < bb ? -1 : (aa >> bb ? 1 : 0);
    }
    ids.sort(sortFn);
    project.order = ids;
  }
  handleMoveItem = (listId, dragId, afterId) => {
    console.log(listId, dragId, afterId);
    const {taskHash, order} = getTaskList(this.props.project);

    // Update status of given task
    const task = taskHash[dragId];
    switch(listId) {
      case 'todo': {
        task.status = StatusTypes.ACTIVE;
        break;
      }
      case 'inProgress': {
        task.status = StatusTypes.INPROGRESS;
        break;
      }
      case 'done': {
        task.status = StatusTypes.DONE;
        break;
      }
      default: {
        break;
      }
    }

    // update project order array.
    if (dragId && !afterId) {
      order[dragId] = '0000000';  // top of the list.
    } else
    if (order[dragId] && order[afterId]) {
      order[dragId] = order[afterId] + String.fromCharCode(255);  // This will cause dragId to be after afterId
    }
    this.updateOrder(this.props.project, order);
    // onUpdate project
    this.props.onUpdate(this.props.project);
  }
  // getId() {
  //   return this.props.project.id;
  // }
  // updateProject() {
  //   const id = this.getId()
  //   Projects.putDocument(id, this.props.project);
  // }

  renderTasks() {
    const {tasks: {todo, inProgress, done}, taskHash} = getTaskList(this.props.project)
    return (
      <DragAndDrop>
        <div className='row'>
          <ProjectBoardColumn title="TaDo" listId="todo" list={todo} taskHash={taskHash} onChange={this.handleMoveItem}/>
          <ProjectBoardColumn title="TaDoing" listId="inProgress" list={inProgress} taskHash={taskHash} onChange={this.handleMoveItem} />
          <ProjectBoardColumn title="TaDone" listId="done" list={done} taskHash={taskHash} onChange={this.handleMoveItem} />
        </div>
      </DragAndDrop>
    )
  }
  render() {
    return (
      <div className='project-board'>
        {this.renderTasks()}
      </div>
    )
  }
}

export default ProjectBoard;
