import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, StatusTypes} from '../../components';
import './Projects.scss';

class ProjectTasks extends Component {
  constructor(props) {
    super(props);
    this.state = {showInactive: {}};
  }
  static propTypes = {
    onEditTask: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired
  }
  handleClickTask = (evt) => {
    const {id} = evt.target;
    this.props.onEditTask({id})
  }
  handleClickAddTask = (evt) => {
    const {id} = evt.target;
    this.props.onEditTask({editTaskParentId: id})
  }
  handleAddClick = () => {
    this.props.onEditTask();
  }
  handleInactiveClick = (evt) => {
    const {id} = evt.target;
    const showInactive = {...this.state.showInactive}
    showInactive[id] = !showInactive[id];
    this.setState({showInactive})
  }
  renderTasks(ret = [], nodes = this.props.project.children || [], level = 1, inactive) {
    nodes.forEach(node => {
      const nodeInactive = node.status && (node.status === StatusTypes.INACTIVE);
      const nodeComplete = node.status && (node.status === StatusTypes.DONE);
      if (inactive) {
        console.log(inactive);
      }
      const inactiveClass = nodeComplete ? ' complete' : ((inactive || nodeInactive) ? ' inactive' : '');
      const bullet = (level === 1) ? <span className='bullet'>■</span> : null;
      ret.push(
        <div key={node.id} className='row'>
          <div className={`cell task level-${level}${inactiveClass}`} id={node.id} onClick={this.handleClickTask}>{bullet}{node.name}</div>
          {nodeInactive ? null : 
            <div><span className={`cell link${inactiveClass}`} id={node.id} onClick={this.handleClickAddTask}>Add Task</span></div>
          }
        </div>
      )
      if (node.children) {
        const {showInactive} = this.state;
        const hideInactive = nodeInactive && !showInactive[node.id];
        if (hideInactive) {
          ret.push(
            <div className='row'>
              <div className={`cell task-label level-${level + 1}${inactiveClass}`} onClick={this.handleInactiveClick} id={node.id}>(kids)</div>
              <div className={`cell link${inactiveClass}`} onClick={this.handleInactiveClick} id={node.id}>Show</div>
            </div>
          )
        } else {
          this.renderTasks(ret, node.children, level + 1, inactive || nodeInactive || nodeComplete);
        }
      }
    })
    return ret;
  }
  render() {
    return (
      <div className='project-tasks'>
        <div className="section">Tasks</div>
        {this.renderTasks()}
        <div><Button buttonStyle='small' onClick={this.handleAddClick}>New..</Button></div>
      </div>
    )
  }
}

export default ProjectTasks;
