import React, {Component} from 'react';
import {Form, Input, InputSizes, Button, DropDown, StatusDropDown, StatusTypes} from '../../components';
import {shallowEquals} from '../../util/utils';
import PropTypes from 'prop-types';
import './Projects.scss';

const typeChoices = [
  {
    value: "milestone",
    label: "Milestone"
  },
  {
    value: "task",
    label: "Task"
  }
];

class ProjectTask extends Component {
  static propTypes = {
    task: PropTypes.object.isRequired,
    onTaskUpdated: PropTypes.func.isRequired,
    onSaveTask: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
  }
  componentDidMount() {
    // Set defaults
    const {task} = this.props;
    const taskCopy = Object.assign({}, task);
    taskCopy.type = task.type || 'task';
    taskCopy.status = task.status || 'active';
    if (!shallowEquals(task,taskCopy)) {
      this.props.onTaskUpdated(taskCopy);
    }
  }
  handleTaskUpdated = (evt) => {
    const {id, value} = evt.target;
    if (this.props.onTaskUpdated) {
      this.props.onTaskUpdated({[id]: value});
    }
  }
  render() {
    const {type, name, desc, status} = this.props.task;
    return (
      <div className="project-task">
        <Form onSubmit={this.props.onSaveTask}>
          <div className="form-elem">
            <DropDown id="type" label="Type" value={type || 'task'} choices={typeChoices} onChange={this.handleTaskUpdated}/>
          </div>
          <div className="form-elem">
            <Input id="name" label="Name" value={name} onChange={this.handleTaskUpdated} />
          </div>
          <div className='form-elem'>
            <Input
              id='desc'
              label='Description'
              value={desc}
              size={InputSizes.LARGE}
              onChange={this.handleTaskUpdated}
            />
          </div>
          <div className="form-elem">
            <StatusDropDown id="status" label="Status" value={status} defaultValue={StatusTypes.ACTIVE} onChange={this.handleTaskUpdated} />
          </div>
          <div className="form-elem">
            <Button>Save</Button>
          </div>
        </Form>
      </div>
    )
  }
}

export default ProjectTask;
