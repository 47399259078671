
import React from 'react';
import {Route} from 'react-router-dom';
import Login from '../pages-public/login/Login';
import ReactContext from './context';

export const PrivateRoute = ({ component: Component, ...rest}) => (
  <ReactContext.Consumer>
    { context => (
      <Route {...rest} render={(props) => (
        Boolean(context.authUser) ?
          <Component {...props} />
        :
          <Login />
      )} />
    )}
  </ReactContext.Consumer>
)
