import React from 'react';

const ReactContext = React.createContext(null);

export const withContext = Component => props => (
  <ReactContext.Consumer>
    { context => {
      return <Component {...props} context={context}/>
    }}
  </ReactContext.Consumer>
)

export default ReactContext;
