import React, {Component} from 'react';
import './TaskList.scss';

class TaskList extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
    this.loadData();
  }
  loadData() {

  }
  renderTasks() {
    if (!this.state.tasks) {
      return <div>loading...</div>
    }
  }
  render() {
    return (
      <div className='task-list'>
        <div>Task list</div>
        {this.renderTasks()}
      </div>
    )
  }
}

export default TaskList;
