import React, {Component} from 'react';
import {startOfDay, addDays} from 'date-fns';
import {getToday, dayToDate, formatDay} from '../../util/dateHelper';
import {withContext} from '../../util/context';
import {Button} from '../../components';
import CalendarDayList from './CalendarDayList';
import CalendarDayGrid from './CalendarDayGrid';
import {DropDown} from '../../components';
import './Calendar.scss';

class CalendarDay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      day: this.props.date || getToday(),
      items: [],
      view: 'list'
    }
  }
  componentDidMount() {
    this.fetchData(this.state.day);
  }
  fetchData(day) {
    const begin = startOfDay(dayToDate(day));
    const end = addDays(begin, 1);
    this.props.context.gapiWrapper.listUpcomingEvents(begin, end).then(this.handleCalendarResults);
  }
  handleCalendarResults = (results) => {
    const items = results.result.items;
    this.setState({items});
    console.log(items);
  }

  newDay(day) {
    this.setState({
      day,
      items: []
    });
    this.fetchData(day)
  }
  handleIncDay = () => {
    const day = this.state.day + 1;
    this.newDay(day);
  }
  handleDecDay = () => {
    const day = this.state.day - 1;
    this.newDay(day);
  }
  handleChangeView = (evt) => {
    const view = evt.target.value;
    this.setState({view})
  }
  render() {
    // const {history, items, day, onAddEvent, weeklyBlocking, getEventInfo} = this.props;
    return (
      <div className='calendar-day'>
        <div className='calendar-day-title'>
          <div className='date'>
            <Button buttonStyle='txt' onClick={this.handleDecDay}>-</Button>
            {formatDay(this.state.day)}
            <Button buttonStyle='txt' onClick={this.handleIncDay}>+</Button>
          </div>
          <div className='pick-style'>
            View: <DropDown onChange={this.handleChangeView} value={this.state.view} choices={[
              {value: 'list', label: 'List'},
              {value: 'grid', label: 'Grid'}
            ]}/>
          </div>
        </div>
        <div className='cal-body'>
          {this.state.view === 'list' ?
            <CalendarDayList {...this.props} />
          :
            <CalendarDayGrid {...this.props} />
          }
        </div>
      </div>
    )
  }
}

export default withContext(CalendarDay);
