import React, {Component} from 'react';
import {getEvents} from './calenderHelper';
import PropTypes from 'prop-types';
import './Calendar.scss';

class CalendarDayGrid extends Component {
  static propTypes = {
      items: PropTypes.object,
      weeklyBlocking: PropTypes.object,
      day: PropTypes.number,
      onAddEvent: PropTypes.func,
      getEventInfo: PropTypes.func,
      history: PropTypes.object
  }
  constructor(props) {
    super(props);
    this.state = {
      dropTarget: {},
      addEvents: [],
      scale: 12,
    }
    this.eventsId = 10000;
  }
  renderGrid() {
    const ret = [];
    const hoursPerLine = 1;;
    for(let ix=0; ix<24; ix+= hoursPerLine) {
      ret.push(
        <div className='cal-grid-hour' key={ix} >
          <div className='time'>{ix}</div>
          <div className='events' />
        </div>
      )
    }
    return (
      <div className='cal-grid'>
        {ret}
      </div>
    )
  }
  getEvents() {
    return getEvents(this.props.day, this.props.items || [], this.props.weeklyBlocking, this.state.addEvents);
  }
  renderItems() {
    const ret = [];
    let events = this.getEvents();
    events.forEach(evt => {
      const {id, type, desc, startTime, endTime} = evt;
      ret.push (
        <div key={id}>
          {id}, {type}, {desc}, {JSON.stringify(startTime)}, {JSON.stringify(endTime)}
        </div>
      )
    })
    return (
      <div>
        {ret}
      </div>
    )
  }
  render () {
    return (
      <div className='cal-day-grid'>
        {this.renderGrid()}
        {this.renderItems()}
      </div>
    )
  }
}

export default CalendarDayGrid;
