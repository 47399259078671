import React, {Component} from 'react';
// import WeeklyCalendar   from '../../components/calendar/weeklyCalendar';
import Calendar   from '../../components/calendar/FrCalendar';
import {withContext} from '../../util/context';
import PropTypes from 'prop-types';
import './Calendar.scss';

class CalendarPage extends Component {
  static propTypes = {
    dayOnly: PropTypes.bool,
    type: PropTypes.string,    // day, week, month
  }
  constructor(props) {
    super(props);
    this.state = {
      date: new Date()
    }
  }
  render() {
    const {type, dayOnly} = this.props;
    let calType = type || 'week';
    if (dayOnly) {
      calType = 'day';
    }
    let calendar;
    switch (calType) {
      case 'week':
        calendar = <Calendar />
        break;
      default: // 'day'
        break;
    }
    return calendar;
  }
}

export default withContext(CalendarPage);
