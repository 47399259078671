import {getDb, getUserId} from '../../util/fsGlobals';
import firebase from 'firebase/app';

export function dataTemplate(collection) {
  const getCollectionRef = (collection) => {
    const path = `userData/${getUserId()}/${collection}`;
    return getDb().collection(path);
  }

  const getDocumentRef = (collection, id) => {
    const path = `userData/${getUserId()}/${collection}/${id}`;
    return getDb().doc(path);
  };

  return {
    getCollectionRef: () => {
      return getCollectionRef(collection)
    },
    getDocumentRef: (id) => {
      return getDocumentRef(collection, id)
    },
    getDocument: (id) => {
      return getDocumentRef(collection, id).get();
    },
    putDocument: (id, doc) => {
      if (id) {
        return getDocumentRef(collection, id).set(doc);
      } else {
        return getCollectionRef(collection).add(doc);
      }
    },
    removeDocument: (id) => {
      if (id) {
        return getDocumentRef(collection, id).delete();
      }
    }
  }
};

export function sharedDataTemplate(collection) {
  const getCollectionRef = () => {
    const path = `common-${collection}`;
    return getDb().collection(path);
  }

  const getDocumentRef = (id) => {
    const path = `common-${collection}/${id}`;
    return getDb().doc(path);
  };

  return {
    getCollectionRef: () => {
      return getCollectionRef()
    },
    query: () => {
      return getCollectionRef().where('uids', 'array-contains', getUserId());
    },
    queryById: (id) => {
      return getCollectionRef()
              .where('uids', 'array-contains', getUserId())
              .where(firebase.firestore.FieldPath.documentId(), "==", id);
    },
    putDocument: (id, doc) => {
      if (id) {
        return getDocumentRef(id).set(doc);
      } else {
        return getCollectionRef().add(doc);
      }
    }
    /*
    getDocument: (id) => {
      return getDocumentRef(collection, id).get();
    },
    removeDocument: (id) => {
      if (id) {
        return getDocumentRef(collection, id).delete();
      }
    }
    */
  }
};
