import React, { Component } from 'react';

class Validatable extends Component {
  constructor(props) {
    super(props);
    if (this.props.formContext) {
      this.props.formContext.validity.registerGetValidityError(this.props.id, this.validate);
    }
  }
  componentWillUnmount() {
    if (this.props.formContext) {
      this.props.formContext.validity.unregisterGetValidityError(this.props.id);
    }
  }

  checkValidity(value) {
    const { checkValidity, isRequired } = this.props;
    this.error = null;
    if (checkValidity) {
      this.error = checkValidity(value);
    }
    if ((!this.error) && isRequired && !value) {
      this.error = 'required';
    }
    if (checkValidity || isRequired) {
      this.props.formContext && this.props.formContext.validity.setValid(this.props.id, this.error);
    }
  }

  // This method can be overridden by a subclass to provide custom validation.
  // If error, return error message to show user. If valid return undefined
  validate = () => {
    if (this.props.isRequired && !this.props.value) {
      return "required";
    }
    return undefined;
  }

  getError() {
    if (!this.props.formContext) {
      return undefined;
    }
    const id = this.props.id;
    this.checkValidity(this.props.value);
    return this.props.formContext.validity.getError(id);
  }

  getErrorInfo() {
    let label = null;
    const error = this.getError();
    const showError = (Boolean(this.props.value) || (this.props.formContext && this.props.formContext.showInvalid)) && error;
    const errorMessage = showError ? `(${this.getError()})` : '';
    if (this.props.label) {
      label = <label htmlFor={this.props.id}>{this.props.label} {errorMessage}</label>
    }
    const errorClass = showError ? ' error' : '';
    return { label, errorClass, errorMessage, error };
  }

  render() {
    throw new Error('must implement render method for Validatable');
    return null;
  }
}

export default Validatable;
