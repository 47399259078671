import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './DropDown.scss';
import formElement from '../util/formElement';

export const InputSizes = {
  MEDIUM: "med",
  LARGE: "lrg"
}

class DropDown extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.string,
    label: PropTypes.string,
    choices: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    isRequired: PropTypes.bool,
    formContext: PropTypes.object, // Provided automatically via formElement import.
    size: PropTypes.string                    // One of InputSizes above; default Medium
  }
  constructor(props) {
    super(props);
    if (this.props.formContext) {
      this.props.formContext.validity.registerGetValidityError(this.props.id, this.getValidityError);
    }
  }
  componentWillUnmount() {
    if (this.props.formContext) {
      this.props.formContext.validity.unregisterGetValidityError(this.props.id);
    }
  }

  // This method can be overridden by a subclass to provide custom validation.
  // If error, return error message to show user. If valid return undefined
  getValidityError = () => {
    if (this.props.isRequired && !this.props.value) {
      return "required";
    }
    return undefined;
  }

  handleChange = (evt) => {
    // this.checkValidity(value);
    if (this.props.onChange) {
      this.props.onChange(evt);
    }
  }
  checkValidity(value) {
    this.error = null;
    if (this.props.isRequired && !value) {
      this.error = 'required';
    }
    if (this.props.formContext) {
      this.props.formContext.validity.setValid(this.props.id, this.error);
    }
  }
  getError() {
    if (!this.props.formContext) {
      return null;
    }
    const id = this.props.id;
    // this.checkValidity(this.props.value);
    return this.props.formContext.validity.getError(id);
  }
  renderChoices() {
    let haveDefault = false;
    const choices = [];
    this.props.choices.forEach(choice => {
      const props={};
      if (this.props.isRequired && (choice.value === '' || choice.value === undefined)) {
        props.disabled = true;
        props.defaultValue = true;
        haveDefault = true;
      }
      if (this.props.value === choice.value) {
        props.selected = true;
      }
      choices.push(<option key={choice.value} value={choice.value} {...props}>{choice.label}</option>);
    })
    if ((this.props.value === '' || this.props.value === undefined) && !haveDefault) {
      choices.unshift(<option key="-empty" value={this.props.value} label="" selected={true}></option>)
    }
    return choices;
  }
  render() {
    let label = null;
    const error = this.getError();
    const showError = (Boolean(this.props.value) || (this.props.formContext && this.props.formContext.showInvalid)) && error;
    if (this.props.label) {
      const errorMessage = showError ? ` (${this.getError()})` : '';
      label = <label htmlFor={this.props.id}>{this.props.label}{errorMessage}</label>
    }
    const errorClass = showError ? ' error' : '';
    const emptyClass = Boolean(this.props.value) ? '' : ' empty';

    const props = {
      id: this.props.id,
      onChange:this.handleChange,
      value: this.props.value,
      required: this.props.isRequired
    }
    if (props.value === undefined) {
      props.value = this.props.defaultValue || "";
    }
    if (this.props.style) {
      props.style = this.props.style;
    }

    return (
      <span className={`drop-down${errorClass}${emptyClass}`}>
        {label}
        <select {...props}>
          {this.renderChoices()}
        </select>
      </span>
    )
  }
}

export default formElement(DropDown);
