import React, {Component} from 'react';
import DropDown from '..//dropdown/DropDown';
import {CommonTaskTypes} from '../../data/store/commonTaskTemplate';

const labels = {
  [CommonTaskTypes.TRAIT]: 'Trait',
  [CommonTaskTypes.EXPERIENCE]: 'Experience',
  [CommonTaskTypes.ACCOMPLISHMENT]: 'Accomplishment',
  [CommonTaskTypes.PRACTICE]: 'Practice',
  [CommonTaskTypes.NOTE]: 'Note',
  [CommonTaskTypes.TODO]: 'Task',
  [CommonTaskTypes.TASK_RECURRING]: 'Recurring Task'
}

class ObjectiveTypeDropDown extends Component {
  getChoices() {
    const {types} = this.props;
    const ret = [];
    types.forEach(type => {
      ret.push({
        value: type,
        label: labels[type]
      })
    })
    return ret;
  }
  render() {
    const {id, value, label, isRequired, onChange} = this.props;
    const props = {
      id,
      value,
      label,
      isRequired,
      onChange
    }
    return (
        <DropDown
          {...props}
          choices={this.getChoices()}
          isRequired={true}
        />
    )
  }
}

export default ObjectiveTypeDropDown;
