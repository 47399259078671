import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Input, InputSizes, DropDown } from '../../components';

class MoreInfoNeeded extends React.Component {
  static propTypes = {
    weeklyPlan: PropTypes.object.isRequired,
    taskCandidates: PropTypes.object.isRequired,
    infoNeeded: PropTypes.array.isRequired,
    onUpdateMoreInfo: PropTypes.func.isRequired,
    onAddMoreInfo: PropTypes.func.isRequired,
    onRemoveMoreInfo: PropTypes.func.isRequired,
  }

  render() {
    return (
      <div>more info needed</div>
    )
  }

  handleAddMoreInfo = () => {
    const { onUpdateMoreInfo, infoNeeded } = this.props;

    // Clean up numbers.
    const newInfoNeeded = [];
    const tasks = [];
    infoNeeded.forEach(element => {
      const { id, source, frequencyUnit, } = element;
      const frequency = element.frequencyUnit === 'day' ? 1 : parseInt(element.frequency);
      const duration = parseInt(element.duration || '0');
      const durationUnit = duration ? element.durationUnit : 'none';
      const frequencyOn = '';
      const count = frequency * (frequencyUnit === 'week' ? 1 : 7);
      if (frequency) {
        const newTask = {
          id,
          source,
          count,
          occurances: [],
          duration,
          durationUnit,
          frequencyOn
        }
        tasks.push(newTask)
      } else {
        newInfoNeeded.push(element);
      }
    })
    onUpdateMoreInfo(newInfoNeeded, tasks);
  }

  handleMoreInfoChange = (evt) => {
    const { infoNeeded, onUpdateMoreInfo } = this.props;
    const { id, value } = evt.target;
    const [objId, fieldName] = id.split('.');
    const taskIx = infoNeeded.findIndex(obj => obj.id === objId)
    const task = { ...infoNeeded[taskIx] };
    task[fieldName] = value;
    infoNeeded[taskIx] = task;
    onUpdateMoreInfo(infoNeeded);
  }

  handleRemoveMoreInfo = (evt) => {
    const { onRemoveMoreInfo } = this.props;
    onRemoveMoreInfo(evt);
  }

  render() {
    const { taskCandidates, onAddMoreInfo } = this.props;

    if (taskCandidates.infoNeeded.length === 0) {
      return null;
    }
    const ret = [];
    taskCandidates.infoNeeded.forEach(element => {
      const { infoNeeded } = element;
      const timesPer = element.frequency === 1 ? 'time per' : 'times per';
      ret.push(
        <Fragment key={element.id}>
          <div className="row">
            <div className="cell"><Button buttonStyle="small-finger white" id={element.id} onClick={this.handleRemoveMoreInfo}>-</Button></div>
            <div className="cell task-desc">{element.name}</div>
          </div>
          <div className="row">
            <div className="cell"></div>
            <div className="cell">
              <Input
                id={`${element.id}.duration`}
                isRequired={true}
                label='Duration'
                value={infoNeeded.duration}
                size={InputSizes.SMALL1}
                onChange={this.handleMoreInfoChange}
              />
              <span className='btn-extra pad-10'>minutes</span>
              {
                infoNeeded.frequencyUnit === 'day' ?
                  <span>1</span>
                  :
                  <Input
                    id={`${element.id}.frequency`}
                    isRequired={true}
                    label='Recurrance'
                    value={infoNeeded.frequency}
                    size={InputSizes.SMALL1}
                    onChange={this.handleMoreInfoChange}
                  />
              }
              <span className='btn-extra pad-5'>{timesPer}</span>
              <DropDown
                id={`${element.id}.frequencyUnit`}
                value={infoNeeded.frequencyUnit} choices={[
                  { value: 'day', label: 'Day' },
                  { value: 'week', label: 'Week' }
                ]}
                onChange={this.handleMoreInfoChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="cell spacer" />
          </div>
        </Fragment>
      )
    })
    return (
      <Form onSubmit={onAddMoreInfo}>
        <div className='title small'>More Info Needed</div>
        {ret}
        <div className="row add-more-info">
          <div className="cell" />
          <div className="cell"><Button buttonStyle='small'>Add</Button></div>
        </div>
      </Form>
    )
  }
}

export default MoreInfoNeeded;
