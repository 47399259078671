import React, {Component} from 'react';

class ResolveTasks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      originalValue: this.props.value,
      editValue: this.props.objectToEdit(this.props.value)
    }
  }
  onChange = (value) => {
    const editValue = Object.assign({}, this.state.editValue, value);
    this.setState({editValue})
  };
  onSubmit = (id) => {
    const object = this.props.editToObject(this.state.editValue, this.state.originalValue);
    this.props.onSubmit(object);
  };
  render() {
    const Wrapped = this.props.wrapped;
    return <Wrapped {...this.props} onChange={this.onChange} onSubmit={this.onSubmit} onBlur={this.onBlur} editValue={this.state.editValue}/>
  }
}

export const editObject = (Wrapped, objectToEdit, editToObject) => props => {
  return <ResolveTasks wrapped={Wrapped} editToObject={editToObject} objectToEdit={objectToEdit} {...props}/>
}
