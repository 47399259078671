import React, {Component} from 'react';
import {withContext} from '../../util/context';
import {ROUTES} from '../../util/constants';
import EditEvent from './EditEvent';
import PropTypes from 'prop-types';

class Event extends Component {
  static propTypes = {
    value: PropTypes.object.isRequired
  }
  constructor(props) {
    super(props);
    const {id} = this.props.match.params;

    this.state = {
      event: {
        _loading: true
      }
    }
    this.fetchEvent(id);
  }

  fetchEvent(id) {
    this.props.context.gapiWrapper.getEvent(id).then(this.handleCalendarResults);
  }
  handleCalendarResults = (result) => {
    const event = result.result;
    this.setState({event})
  }
  handleCancel = (evt) => {
    this.props.history.push(ROUTES.DAILY)
  }
  handleSubmit = (event) => {
    this.props.context.gapiWrapper.updateEvent(event).then(result => {
      this.handleCancel();
    })
  }

  render() {
    if (this.state.event._loading) return <div>loading</div>;
    return (
      <div className='edit-event'>
        <EditEvent value={this.state.event} onCancel={this.handleCancel} onSubmit={this.handleSubmit}/>
      </div>
    )
  }
}

export default withContext(Event);
