export const HEADER = -1;
// In this module, "hour" is a value between 0>=hours<24 which will be translated
// to a pixel offset, and "day" is a value betwen 0>=day<7, also translated to pixels.

const COL_OFFSET = 10;
const HEADER_HEIGHT = 20;

export default class Positioner {
  constructor(props) {
    const {scale, showHeading=true, offsetX, offsetY} = props;  // These values are all in pixels
    this.scale = scale || 35;  // Scale is the height in pixels of one hour
    this.offsetX = offsetX || 0;
    this.offsetY = offsetY || 0;
    this.headerHeight = showHeading ? HEADER_HEIGHT :  0;
    this.hourHeight = scale;       // Height for one hour
    this.headerWidth = scale;
    this.dayWidth = 200;
    this.showHeading = showHeading;
  }

  snap(rect) {
    const {offsetX, offsetY, headerWidth, headerHeight, dayWidth, hourHeight} = this;
    const x = rect.width/2 + rect.left;
    const y = rect.top;
    const hour = Math.floor(4*(y - offsetY - headerHeight) / hourHeight)/4;  // 4 = 15 min increments,4*15=60
    const day = Math.floor((x - offsetX - headerWidth) / dayWidth);
    console.log(day, hour);
    return this.getRect(hour, day, hour+1);
  }
  getRect(hour, day, endHour, endDay, colEntry) {
    const { col = 0, width = 1 } = colEntry ?? {};
    if (endDay===undefined) endDay = day;
    if (endHour===undefined) endHour = hour + 1;
    const {offsetX, offsetY, headerWidth, headerHeight, dayWidth, hourHeight} = this;
    const ret = {};
    const colWidth = this.dayWidth / width;
    const colOffset = col * colWidth;

    if (hour===HEADER) {
      ret.top = offsetY;
      ret.bottom = ret.top + headerHeight - 1;
    } else {
      ret.top = offsetY + headerHeight + hour * hourHeight;
      ret.bottom = (offsetY + headerHeight + endHour * hourHeight) - 1;
    }

    if (day===HEADER) {
      ret.left = offsetX;
      ret.right = ret.left + headerWidth - 1;
    } else {
      ret.left = offsetX + headerWidth + day * dayWidth + colOffset;
      ret.right = ret.left + colWidth - 1;
    }
    ret.width = ret.right - ret.left + 1 - col * COL_OFFSET;
    ret.height = ret.bottom - ret.top + 1;
    return ret;
  }

  getBoundingRect(hours, days) {
    const {offsetX, offsetY, headerWidth, headerHeight, dayWidth, hourHeight} = this;
    const height = headerHeight + hours * hourHeight;
    const width = headerWidth + days * dayWidth;
    return {
      top: offsetY,
      bottom: offsetY + height - 1,
      left: offsetX,
      right: offsetX +width - 1,
      width,
      height,
    }
  }

  rectToHourDay(day, rect, boundingRect, snap=true) {
    const {headerWidth, headerHeight, dayWidth, hourHeight} = this;
    const startX = rect.left - boundingRect.left;
    const endX = rect.right - boundingRect.left;
    const startY = rect.top - boundingRect.top;
    const endY = rect.bottom - boundingRect.top;

    const x2day = (x) => x<headerWidth ? HEADER : Math.floor( (x-headerWidth) / dayWidth);
    const y2hour = (y) => y<headerHeight ? HEADER : Math.floor( (60*(y - headerHeight)) / hourHeight / 24)/60;

    const sd = x2day(startX);
    const sh = y2hour(startY);
    const ed = x2day(endX);
    const eh = y2hour(endY);
    return [day+sd+sh, day+ed+eh];
  }
}
