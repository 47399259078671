import React from 'react';

export default function Link(props) {
  const className = `link ${props.className}`;
  const {id, onClick} = props;
  const linkProps = {
    className,
    id,
    onClick
  }
  return (
    <span {...linkProps}>{props.children}</span>
  )
}
