const days = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];
function dayStrToArray (str) {
  const array = {};
  if (str.length) {
    const parts = str.split(',');
    parts.forEach(part => {
      const parts2 = part.trim().split('-');
      const start = days.findIndex(day => day===parts2[0] || day.charAt(0)===parts2[0]);
      const end = parts2.length>1 ? days.findIndex(day => day===parts2[1] || day.charAt(0)===parts2[1]) : start;
      for (let ix=start; ix<=end; ix++) {
        array[days[ix]]=true;
      }
    })
  }
  return array;
}
function dayArrayToStr (array) {
  let start;
  let end;
  let str='';
  const handleAdd = () => {
    if (start) {
      let bit;
      if (start===end) {
        bit=start;
      } else {
        bit = start+'-'+end
      }
      if (str.length) str += ',';
      str += bit;
      start = end = undefined;
    }
  }
  days.forEach(day => {
    if (array[day]) {
      if (!start) {
        start = day;
      }
      end = day;
    } else {
      handleAdd()
    }
  })
  handleAdd();
  return str;
}

export {
  days,
  dayArrayToStr,
  dayStrToArray
}
