import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Button } from '../../components';
import './WeeklyPlanning.scss';
import WeeklyProjects from './WeeklyProjects';

class AvailableTasks extends React.Component {
  static propTypes = {
    taskCandidates: PropTypes.object.isRequired,
    onAddTask: PropTypes.func.isRequired,
    suggested: PropTypes.object
  }

  renderUnselectedSection(name, source, elements, suggested, handleAddTask) {
    const ret = [];

    elements.forEach(element => {
      const idPlus = `${element.id}.${source}`;
      const isSuggested = !!suggested[element.id];
      ret.push(
        <div key={element.id} className="row">
          <div className="cell"><Button buttonStyle="small-finger white" id={idPlus} onClick={handleAddTask}>+</Button></div>
          <div className="cell task-desc">{element.name} {element.lastCompleted}</div>
          {isSuggested && <div className="cell suggested">Suggested</div>}
        </div>
      )
    })
    return (
      <Fragment>
        <div className='title'>{name}</div>
        {ret}
      </Fragment>
    )
  }

  render() {
    const { taskCandidates, onAddTask, suggested = {} } = this.props;
    const handleAddTask = onAddTask;
    const hasPractices = taskCandidates.practicesWGoal.length > 0;

    const hasTasks = taskCandidates.todoList.length > 0;
    return (
      <Fragment>
        {!hasPractices ? this.renderUnselectedSection('Objectives', 'objectives', taskCandidates.objectives, suggested, handleAddTask) : null}
        {hasPractices ? this.renderUnselectedSection('Practices', 'objectives', taskCandidates.practicesWGoal, suggested, handleAddTask) : null}
        {hasTasks ? this.renderUnselectedSection('To Dos', 'todoList', taskCandidates.todoList, suggested, handleAddTask) : null}
        {hasPractices ? this.renderUnselectedSection('Other Objectives', 'objectives', taskCandidates.objectives, suggested, handleAddTask) : null}
        <WeeklyProjects onAddTask={handleAddTask} projects={taskCandidates.projects} />
      </Fragment>
    )
  }
}

export default AvailableTasks;