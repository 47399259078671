import React, {Component} from 'react';
import {InputSizes} from '../index';
import {parse, format} from 'date-fns';

const FORMAT = 'MM/dd/yyyy';
let count = 0;

class DatePicker extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }
  componentDidMount() {
    const {current} = this.ref;
    current.value = format(this.props.value, FORMAT);
  }
  componentDidUpdate() {
    console.log('component did update')
    if (count<5) {
      const {current} = this.ref;
      current.value = format(this.props.value, FORMAT);
      count++;
    }
  }
  // handleChange = (evt) => {
  //   console.log(`handleChange ${evt.target.value}`)
  // }
  handleBlur = (evt) => {
    const {id, value} = evt.target;
    console.log(`handleBlur ${evt.target.value}`)
    if (this.props.onChange) {
      try {
        const propDate = this.props.value || new Date();
        let date = parse(value, FORMAT, propDate);
        if (isNaN(date.getTime()) && propDate) {
          date = propDate;
        }
        this.props.onChange({
          target:{
            id,
            value: date
          }
        });
      } catch(err) {

      };
    }
  }
  render() {
    const {id, label} = this.props;
    return (
      <input
        id={id}
        label={label}
        size={InputSizes.MEDIUM1}
        placeholder="mm/yy/yy"
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        ref={this.ref}
      />
    )
  }
}

export default DatePicker;
