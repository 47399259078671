import React, {Component} from 'react';
import Notes from '../../data/store/notes';
import {ROUTES} from '../../util/constants';
import {withContext} from '../../util/context';
import './DailyPlanning.scss'

class ProcessNotes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noteList: []
    }
  }
  componentDidMount() {
    const notes = Notes.getCollectionRef();
    if (this.unsubscribe) this.unsubscribe();
    this.unsubscribe = notes.onSnapshot((results) => {
      const noteList = [];
      results.forEach( docRef => {
        const doc = Object.assign({}, docRef.data());
        doc.id = docRef.id;
        noteList.push(doc);
      })
      this.setState({
        noteList
      })
    })
  }
  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }
  handleNoteClick = (evt) => {
    let id = evt.target.id || evt.target.parentNode.id;
    this.props.history.push(`${ROUTES.NOTE}/${id}`)
  }
  renderNotes() {
    const ret = [];
    this.state.noteList.forEach(note => {
      ret.push(
        <div id={note.id} key={note.id} onClick={this.handleNoteClick} className="note">{note.desc}</div>
      )
    })
    return ret;
  }
  render () {
    const {noteList} = this.state;
    const title = this.props.title || "Notes";
    return (
      <div>
        {noteList.length ?
          <div className="process-notes">
            <div className='section'>{title}</div>
            <div className="msg">This is a temporary hack; eventually all the processing will happen right here, but for now click on a note to process it</div>
            {this.renderNotes()}
          </div>
        :
          null
        }
      </div>
    )
  }
}

export default withContext(ProcessNotes);
