import React, {Component} from "react";
import {Link} from 'react-router-dom';
import Values from '../../data/store/values';
import {ROUTES} from '../../util/constants';
import './Values.scss';

class ValuesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valueList: []
    }
  }
  componentDidMount() {
    const values = Values.getCollectionRef();
    if (this.unsubscribe) this.unsubscribe();
    this.unsubscribe = values.onSnapshot((results) => {
      const valueList = [];
      results.forEach( docRef => {
        const doc = Object.assign({}, docRef.data());
        doc.id = docRef.id;
        valueList.push(doc);
      })
      this.setState({
        valueList
      })
    })
  }
  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }
  handleValueClick = (evt) => {
    let id = evt.target.id || evt.target.parentNode.id;
    this.props.history.push(`${ROUTES.VALUE}/${id}`)
  }

  renderValues() {
    const ret = [];
    this.state.valueList.forEach(doc => {
      ret.push(
        <div key={doc.id}>
          <div className='value' id={doc.id} onClick={this.handleValueClick}>{doc.name}</div>
          <div>{doc.desc}</div>
        </div>
      )
    })
    return ret;
  }

  render() {
    return (
      <div className='values'>
        <div className='row heading'>
          <div className='cell'>Name</div>
          <div className='cell'>Description</div>
        </div>
        {this.renderValues()}
          <div className='row footer'>
            <div className='cell'></div>
            <div className='cell'>
              <Link to={ROUTES.VALUE}>Add</Link>
            </div>
          </div>
      </div>
    )
  }
}

export default ValuesPage;
