import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {DragContext}  from './DragContext';
import styled from 'styled-components';
import './dnd.scss';

const SizedDiv = styled.div`
  width: ${props=>props.width};
  height: ${props=>props.height};
`;

class Draggable extends Component {
  static contextType = DragContext;
  static propTypes = {
    onDragStart: PropTypes.func,
    onDrag : PropTypes.func,
    onDragend: PropTypes.func,
    setShadow: PropTypes.func,
    id: PropTypes.string,
  }
  state = {
    isDragging: false,

    clientX: undefined,
    clientY: undefined
  };

  constructor(props) {
    super(props);
    this._ref = React.createRef();
  }

  get ref() {
    return this._ref;
  }

  componentDidMount() {
    if(this.context && this.context.registerDraggable) {
      this.unregister = this.context.registerDraggable(this);
    }
  }

  componentWillUnmount() {
    if (this.unregister) this.unregister(this);
    window.removeEventListener('mousemove', this.handleMouseMove);
    window.removeEventListener('mouseup', this.handleMouseUp);
  }

  handleMouseDown = (evt) => {
    const { clientX, clientY, target } = evt;
    window.addEventListener('mousemove', this.handleMouseMove);
    window.addEventListener('mouseup', this.handleMouseUp);

    const bounding = target.getBoundingClientRect();
    const offsetX = clientX - bounding.left;
    const offsetY = clientY - bounding.top;
    if (this.props.setShadow) {
      this.props.setShadow(<SizedDiv className='shadow' width={`${bounding.width}px`} height={`${bounding.height}px`} />)
    }

    const width = target.scrollWidth;
    const height = target.scrollHeight;

    const rect = {
      x: offsetX,
      left: offsetX,
      y: offsetY,
      top: offsetY,
      right: offsetX+width,
      bottom: offsetY+height,
      width,
      height
    }

    if (this.props.onDragStart) {
      this.props.onDragStart(rect, this.props.dragId);
    } else if (this.context.onDragStart) {
      this.context.onDragStart(rect, this.props.dragId);
    }

    this.setState({
      clientX,
      clientY,
      offsetX,
      offsetY,
      width,
      height,
      isDragging: true,
      rect,  // Not sure this belongs here, but without it, single clicking a draggable fails because no rect set in state.
    });
  }

  handleMouseMove = (evt) => {
    const { clientX, clientY } = evt;
    // console.log(`--- move --- ${clientX} ${clientY}`)
    const { isDragging } = this.state;

    if (!isDragging) {
      return;
    }

    // Use the center of the dragged object
    const x = clientX - this.state.offsetX;
    const y = clientY - this.state.offsetY;
    const {width, height} = this.state;
    const rect = {
      x,
      left: x,
      y,
      top: y,
      right: x+width,
      bottom: y+height,
      width,
      height
    }
    if (this.props.onDrag) {
      this.props.onDrag(rect, this.props.dragId);
    } else if (this.context.onDrag) {
      this.context.onDrag(rect, this.props.dragId);
    }

    this.setState(prevState => ({
      clientX: clientX,
      clientY: clientY,
      rect
    }));
    // evt.stopPropagation();
    evt.preventDefault();
  };

  handleMouseUp = () => {
    window.removeEventListener('mousemove', this.handleMouseMove);
    window.removeEventListener('mouseup', this.handleMouseUp);
    if (this.props.setShadow) {
      this.props.setShadow(null)
    }

    const {rect} = this.state;
    this.setState(
      {
        clientX: undefined,
        isDragging: false
      },
      () => {
        if (this.props.onDragEnd) {
          this.props.onDragEnd(rect, this.props.dragId);
        } else if (this.context.onDragEnd) {
          this.context.onDragEnd(rect, this.props.dragId);
        }
      }
    );
  };

  render() {
    const { children } = this.props;
    const { isDragging } = this.state;

    return (
      <Container
        onMouseDown={this.handleMouseDown}
        x={this.state.clientX - this.state.offsetX}
        y={this.state.clientY - this.state.offsetY}
        width={this.state.width}
        height={this.state.height}
        isDragging={isDragging}
        useRef={this.ref}
        usePlaceHolder={!this.props.setShadow}
        id={this.props.id}
      >
        {children}
      </Container>
    );
  }
}

const Container = ({x, y, width, height, isDragging, children, onMouseDown, useRef, showPlaceHolder, id}) => {
  let dragClass;
  let style = undefined;
  let placeHolderStyle = {
    dipslay: "none"
  }
  if (isDragging) {
    style = {
      top: y,
      left: x,
      width, height
    }
    dragClass = 'dragging';
    if (showPlaceHolder) {
      placeHolderStyle = {width, height}
    }
  } else {
    dragClass = 'static';
  }
  return (
    <div className='container' id={id}>
      <div style={style} onMouseDown={onMouseDown} className={`contained ${dragClass}`} ref={useRef}>
        {children}
      </div>
      <div style={placeHolderStyle} />
    </div>
  )
}

export default Draggable;
