import React, {Component} from 'react';
import {DragContext, Draggable, Droppable} from '../../util/dnd';
import './ProjectBoard.scss';

export default class ProjectBoardColumn extends Component {
  static contextType = DragContext;
  createTaskGroup = (taskGroup, name, id) => {
    if (!taskGroup || taskGroup.length === 0) {
      return null;
    }
    taskGroup.splice(-1,1);
    return (
      <div key={id} className='taskGroup'>
        {name ? <div className='tg-header'>{name}</div> : null}
        {taskGroup}
      </div>
    )
  };
  onDrag = (rect, dragId) => {
    if (!rect) {
      this.setState({
        inDrag: false
      })
    } else {
      const {vertical} = this.context.findNearest(rect, dragId);
      this.setState({
        nearestId: vertical && vertical.dragId,
        inDrag: true
      })
    }
  };
  onDragEnd = (rect, dragId) => {
    if (rect) {
      const {vertical} = this.context.findNearest(rect, dragId);
      if (this.props.onChange) {
        this.props.onChange(this.props.listId, dragId, vertical && vertical.dragId)
      }
    }
    this.setState({
      nearestId: undefined,
      inDrag: false
    })
  }
  render() {
    const {title, list, taskHash} = this.props;
    const tasks = [];
    let taskGroup = [];
    let lastParentIds = '';
    const getTitle = (parentIds) => {
      const notFirst = parentIds.split('.').slice(2, 99);
      return notFirst.reduce( (title, id) => {
        if (id.length) {
          if (title.length) {
            title += ': ';
          }
          title += (taskHash[id].name || '');
        }
        return title;
      }, '')
    }

    // If dragging and no nearestId, assume insert at top
    if (this.state && this.state.inDrag) {
      if(!this.state.nearestId) {
        tasks.push(
          <div key="none" className='spacer' />
        )
      }
    }
    if( list.length === 0) {
      tasks.push(
        <div key="empty" className='empty' />
      )
    }

    list.forEach(node => {
      const {task, parentIds} = node;
      if (lastParentIds!==parentIds) {
        const title = getTitle(lastParentIds);
        tasks.push(this.createTaskGroup(taskGroup, title, task.id));
        lastParentIds = parentIds
        taskGroup = [];
      }
      let lite = '';
      taskGroup.push(
        <Draggable key={task.id} dragId={task.id}>
          <div className={`task${lite}`}>
            <div className='task-body' id={task.id}>
              {task.name}
            </div>
          </div>
        </Draggable>
      )
      if (this.state && this.state.inDrag) {
        if(this.state.nearestId===task.id) {
          taskGroup.push(
            <div key={`${task.id}-s`} className='spacer' />
          )
        }
      }
      taskGroup.push(
        <div key={`${task.id}-d`} className='divider' />
      )
    })
    tasks.push(this.createTaskGroup(taskGroup, getTitle(lastParentIds), 'last'));
    return (
      <Droppable dropId={title} onDrag={this.onDrag} onDragEnd={this.onDragEnd}>
        <div className='cell pb-column'>
          <div className='title'>{title}</div>
          {tasks}
        </div>
      </Droppable>
    )
  }
}
