import React, {Component} from 'react';
import {CommonTaskObject} from '../../components';
import {ROUTES} from '../../util/constants';
import Notes from '../../data/store/notes';
import {CommonTaskTypes} from '../../data/store/commonTaskTemplate';

class Note extends Component {
  render() {
    const searchParams = new URLSearchParams(window.location.search);
    const retLoc = searchParams.get('r');
    const returnRoute = retLoc ?? ROUTES.NOTES;
    return <CommonTaskObject
      {...this.props}
      defaultType={CommonTaskTypes.NOTE}
      store={Notes}
      returnRoute={returnRoute}
    />
  }
}

export default Note;
