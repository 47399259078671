import React, {Component} from 'react';
import {Button} from '../../components';
import ProjectsData from '../../data/store/projects';
import {ROUTES} from '../../util/constants';
import './Projects.scss';

class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: undefined
    }
    this.loadData();
  }
  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }
  loadData() {
    if (this.unsubscribe) this.unsubscribe();
    this.unsubscribe = ProjectsData.query().onSnapshot( queryRef => {
      const projects = [];
      const docs = queryRef.docs;
      docs.forEach(docRef => {
        const {id} = docRef;
        const doc = Object.assign({}, docRef.data());
        projects.push({id, doc})
      })
      this.setState({projects});
    })
  }
  handleClickProject = (evt) => {
    let id = evt.target.id || evt.target.parentNode.id;
    this.props.history.push(`${ROUTES.PROJECT}/${id}`)
  }
  handleAddProject = (evt) => {
    this.props.history.push(`${ROUTES.PROJECT}`)
  }
  renderProjects() {
    const ret = [];
    (this.state.projects || []).forEach(project => {
      ret.push(<div key={project.id} id={project.id} className="project-link" onClick={this.handleClickProject}>{project.doc.name}</div>)
    })
    return ret;
  }
  render() {
    return (
      <div className='projects'>
        <div className="section">Projects</div>
        {this.renderProjects()}
        <Button onClick={this.handleAddProject}>Add...</Button>
      </div>
    )
  }
}

export default Projects;
