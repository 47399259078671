import React, { Component, Fragment } from 'react';
import { Form, Link, Button, Input, InputSizes, DropDown, CheckBox } from '../../components';
import { CommonTaskTypes } from '../../data/store/commonTaskTemplate';
// import SettingStore, {WEEKBLOCK_ID} from '../../data/store/settings';
import { dateToDay, formatDay, parseDate } from '../../util/dateHelper';
import { FREQUENCY } from '../../util/constants';
import WeeklyProjects from './WeeklyProjects';
import PropTypes from 'prop-types';
import './WeeklyPlanning.scss';
import { getTaskCandidates, getNewElem } from './wpUtil';
import AvailableTasks from './AvailableTasks';
import MoreInfoNeeded from './MoreInfoNeeded';

class Tasks extends Component {
  static propTypes = {
    week: PropTypes.number.isRequired,
    wheelTree: PropTypes.object,
    wheelTreeHash: PropTypes.object,
    weeklyPlan: PropTypes.object.isRequired,
    projects: PropTypes.array.isRequired,
    taskList: PropTypes.array.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onDone: PropTypes.func.isRequired,
  }
  constructor(props) {
    super(props);
    const { tasks } = props;
    const showSuggested = !tasks || tasks.length === 0
    this.state = {
      infoNeeded: [],
      showSuggested,
      suggested: {},
    }
  }
  handleDone = (evt) => {
    const { onDone } = this.props;
    onDone && onDone();
  }
  handleMoreInfoChange = (evt) => {
    const { id, value } = evt.target;
    const [objId, fieldName] = id.split('.');
    const taskIx = this.state.infoNeeded.findIndex(obj => obj.id === objId)
    const infoNeeded = [...this.state.infoNeeded];
    const task = { ...this.state.infoNeeded[taskIx] };
    task[fieldName] = value;
    infoNeeded[taskIx] = task;
    this.setState({ infoNeeded });
  }
  addNewTasks(newTasks) {
    const { onUpdate, weeklyPlan: { tasks = [] } } = this.props;
    if (newTasks.length > 0) {
      onUpdate(tasks.concat(newTasks));
    }
  }
  updateMoreInfo = (infoNeeded, newTasks = []) => {
    this.setState({ infoNeeded })
    this.addNewTasks(newTasks);
  }
  handleAddMoreInfo = () => {
    // Clean up numbers.
    const infoNeeded = [];
    const tasks = [];
    this.state.infoNeeded.forEach(element => {
      const { id, source, frequencyUnit, } = element;
      const frequency = element.frequencyUnit === 'day' ? 1 : parseInt(element.frequency);
      const duration = parseInt(element.duration || '0');
      const durationUnit = duration ? element.durationUnit : 'none';
      const frequencyOn = '';
      const count = frequency * (frequencyUnit === 'week' ? 1 : 7);
      if (frequency) {
        const newTask = {
          id,
          source,
          count,
          occurances: [],
          duration,
          durationUnit,
          frequencyOn
        }
        tasks.push(newTask)
      } else {
        infoNeeded.push(element);
      }
    })
    this.updateMoreInfo(infoNeeded, tasks);
  }
  handleRemoveSelected = (evt) => {
    const { id } = evt.target;
    const tasks = [...this.props.weeklyPlan.tasks];
    const ix = tasks.findIndex(task => task.id === id);
    if (ix >= 0) {
      tasks.splice(ix, 1);
      this.props.onUpdate(tasks)
    }
  }
  handleEditDuration = (evt) => {
    const { id } = evt.target;
    alert(`Implement edit duration ${id}`);
  }
  computeCantRemove() {
    const cantRemove = {};
    const { days = [] } = this.props.weeklyPlan;
    Object.keys(days).forEach(key => {
      const day = days[key];
      day.tasks.forEach(task => {
        cantRemove[task.id] = true;
      })
    })
    return cantRemove;
  }
  handleAddSuggested = (evt) => {
    const suggestedTasks = this.getSuggestedTasks();
    const tasks = [];
    Object.entries(suggestedTasks).forEach(([id, task]) => {
      const { wheelTreeNode } = task;
      let source;
      if (wheelTreeNode) {
        source = 'objectives';
      } else {
        source = 'todoList';
      }
      tasks.push(`${id}.${source}`);
    })
    this.addTasks(tasks);
  }
  getSuggestedTasks() {
    const { week, weeklyPlan, wheelTree, projects, taskList } = this.props;
    const { tasks } = weeklyPlan;
    const { infoNeeded } = this.state;
    const ret = {};
    const taskCandidates = getTaskCandidates(tasks, wheelTree, projects, taskList, infoNeeded);
    const haveGoals = taskCandidates.practicesWGoal;
    haveGoals.forEach(task => {
      // console.log('=====', task);
      const { id } = task;
      const { doc } = task.wheelTreeNode;
      if (!doc.lastCompleted) {
        ret[id] = task;
      } else if (task.frequencyUnit === 'day' || task.frequencyUnit === 'week') {
        ret[id] = task;
      } else if (task.frequencyUnit === 'month' || task.frequencyUnit === 'year') {
        if (task.frequency) {
          const duration = task.frequencyUnit === 'year' ? 365 : 30;
          const next = duration / task.frequency + doc.lastCompleted;
          console.log('==== y ', next, week + 7, next < week + 7);
          if (next < week + 7) {
            ret[id] = task;
          }
        }
      }
    });
    taskCandidates.todoList.forEach(task => {
      if (task.goal) {
        const { goalDate } = task.goal;
        if (goalDate) {
          const date = parseDate(goalDate);
          const day = dateToDay(date)
          if (day < week + 7) {
            const { id } = task;
            ret[id] = task;
          }
        }
      }
    })
    return ret;
  }
  renderSelected(selected) {
    // const taskHash = this.computeTaskHash();
    const ret = [];
    const cantRemove = this.computeCantRemove();
    selected.forEach(task => {
      let duration;
      if (task.durationUnit === 'none') {
        duration = "no time"
      } else {
        duration = `${task.duration} ${task.durationUnit || 'mins'}`;
      }
      let frequency;
      if (task.count > 1) {
        frequency = ` (x${task.count})`;
      }
      const canRemove = !cantRemove[task.id];
      ret.push(
        <div key={task.id} className="row">
          <div className="cell">{canRemove ? <Button buttonStyle="small-finger white" id={task.id} onClick={this.handleRemoveSelected}>-</Button> : null}</div>
          <div className="cell task-desc">{task.name} <Link id={task.id} onClick={this.handleEditDuration}>{duration}{frequency}</Link></div>
        </div>
      )
    })
    return ret;
  }

  handleAddTask = (evt) => {
    const { id } = evt.target;
    this.addTasks([id]);
  }

  getNewElem(elemId, source) {
    return getNewElem(this.props.wheelTreeHash, elemId, source)
  }

  addTasks(ids) {
    const newElems = [];
    const infoNeeded = [...this.state.infoNeeded];
    let needInfo = false;
    ids.forEach(compoundId => {
      const [elemId, source] = compoundId.split('.');
      const newElem = this.getNewElem(elemId, source);
      if (newElem) {
        newElems.push(newElem);
      } else {
        // Not enough info for a task, has to go into the 'get more info' alrea.
        infoNeeded.push({
          id: elemId,
          source,
          durationUnit: 'mins',
          frequency: 1,
          frequencyUnit: 'week'
        });
        needInfo = true;
      }
    })
    if (needInfo) {
      this.setState({ infoNeeded });
    }
    this.addNewTasks(newElems);
  }
  handleRemoveMoreInfo = (evt) => {
    const { id } = evt.target;
    const infoNeeded = [...this.state.infoNeeded];
    const ix = infoNeeded.findIndex(obj => obj.id === id);
    if (ix >= 0) {
      infoNeeded.splice(ix, 1);
      this.setState({ infoNeeded })
    }
  }
  render() {
    if (!this.props.wheelTree) {
      return null;
    }
    const { weeklyPlan, wheelTree, projects, taskList } = this.props;
    const { tasks } = weeklyPlan;
    const { infoNeeded } = this.state;
    const taskCandidates = getTaskCandidates(tasks, wheelTree, projects, taskList, infoNeeded);
    const suggested = this.getSuggestedTasks();
    return (
      <div className='task-picker'>
        <div className='tasks'>
          <div className='title'>Tasks</div>
          {this.renderSelected(taskCandidates.selected)}
          <MoreInfoNeeded
            weeklyPlan={weeklyPlan}
            taskCandidates={taskCandidates}
            infoNeeded={infoNeeded}
            onUpdateMoreInfo={this.updateMoreInfo}
            onAddMoreInfo={this.handleAddMoreInfo}
            onRemoveMoreInfo={this.handleRemoveMoreInfo}
          />
          <div>
            <Button onClick={this.handleAddSuggested}>Add Suggested</Button>
            <Button onClick={this.handleDone}>Done</Button>
          </div>
        </div>
        <AvailableTasks taskCandidates={taskCandidates} suggested={suggested} onAddTask={this.handleAddTask} />
      </div>
    )
  }
}

export default Tasks;
