import React, {Component} from 'react';
import {CommonTaskObject} from '../../components';
import {ROUTES} from '../../util/constants';
import Tasks from '../../data/store/tasks';
import {CommonTaskTypes} from '../../data/store/commonTaskTemplate';

class Todo extends Component {
  render() {
    const searchParams = new URLSearchParams(window.location.search);
    const retLoc = searchParams.get('r');
    const returnRoute = retLoc ?? ROUTES.TODOS;
    return <CommonTaskObject
      {...this.props}
      defaultType={CommonTaskTypes.TODO}
      store={Tasks}
      returnRoute={returnRoute}
    />
  }
}

export default Todo;
