import React from 'react';
import PropTypes from 'prop-types';
import PeopleData from "../../data/store/people";
import { registerWheelTreeListener } from '../../data/wheelTree';
import { Button, Form, Input, InputSizes } from '../../components';
import { ROUTES } from '../../util/constants';
import './people.scss';
import { findPeople } from './peopleUtil';
import { formatDay } from '../../util/dateHelper';
import { getFrequencyStr } from '../../util/taskHelper';

class Person extends React.Component {
  static propTypes = {
    id: PropTypes.string
  }
  constructor(props) {
    super(props);
    const { id } = props;
    this.state = {
      loading: !!id,
      person: {},
    }
    this.loadData();
  }

  loadData() {
    const { id } = this.props.match.params;
    if (id) {
      const personData = PeopleData.getDocumentRef(id);
      if (this.unsubscribePersonData) this.unsubscribePersonData();
      this.unsubscribePersonData = personData.onSnapshot(docRef => {
        const doc = Object.assign({}, docRef.data());
        this.setState({
          person: doc,
          loading: false,
        })
        const { wheelTreeHash } = this.state;
        this.checkForTaskMatches(doc, wheelTreeHash);
      })
    }
    this.unregisterWheelTree = registerWheelTreeListener((wheelTree, wheelTreeHash) => {
      this.setState({ wheelTreeHash });
      const { person } = this.state;
      this.checkForTaskMatches(person, wheelTreeHash);
    })
  }

  componentWillUnmount() {
    if (this.unsubscribePersonData) this.unsubscribePersonData();
    if (this.unregisterWheelTree) this.unregisterWheelTree();
  }

  checkForTaskMatches(person, wheelTreeHash) {
    if (!(person && person.name && wheelTreeHash)) return;
    const { id } = this.props.match.params;
    const withId = {id, ...person}
    const taskMatches = findPeople([withId], wheelTreeHash);
    if (taskMatches) {
      this.setState({ taskMatches });
    }
  }

  handleSubmit = async () => {
    const { id } = this.props.match.params;
    const { history, returnRoute = ROUTES.PEOPLE } = this.props;
    const { person } = this.state;
    await PeopleData.putDocument(id, person);
    history.push(returnRoute);
  }

  handleChange = (evt) => {
    const {id, value} = evt.target;
    const person = {...this.state.person};
    person[id] = value;
    this.setState({person});
  }

  handleCancel = () => {
    const { history } = this.props;

    if (this.unsubscribePersonData) this.unsubscribePersonData();
    history.push(ROUTES.PEOPLE);
  }

  handleGoalChange = (evt) => {
    const { value } = evt.target;
    const { person } = this.state;
    const newPerson = {...person}
    newPerson.goal = value;
    this.setState({person: newPerson})
  }

  renderGoals() {
    const { taskMatches, person } = this.state;
    const { name } = person;
    const goals = [];
    if (taskMatches) {
      Object.entries(taskMatches).forEach(([k,v]) => {
        const { name: gname, goal, lastCompleted } = v?.[0];
        // only display name of goal if it differs from name of person
        const displayName = name.substring(0, gname.length) === gname ? '' : gname;
        if (goal) {
          const frequency = getFrequencyStr(goal);
          const lastCompletedStr = lastCompleted ? `(last: ${formatDay(lastCompleted)})` : '';
          goals.push(
            <div key={k}>
              {displayName} {frequency} {lastCompletedStr}
            </div>
          )
        }
      })
    }
    return (
      <>
        <div className="title">Goals</div>
        {goals}
      </>
    );
  }

  render() {
    const { loading, person={} } = this.state;
    const { name, phone, email, notes } = person

    if (loading) {
      return 'loading...'
    }

    return (
      <div className="person">

        <Form onSubmit={this.handleSubmit}>
          <div className='form-elem'>
            <Input
              id='name'
              label='Name'
              size={InputSizes.MEDIUM3}
              onChange={this.handleChange}
              value={name}
              isRequired={true}
            />
          </div>
          <div className='form-elem'>
            <Input
              id='phone'
              label='Phone'
              size={InputSizes.MEDIUM3}
              onChange={this.handleChange}
              value={phone}
            />
          </div>
          <div className='form-elem'>
            <Input
              id='email'
              label='Email'
              size={InputSizes.MEDIUM3}
              onChange={this.handleChange}
              value={email}
            />
          </div>
          <div className='form-elem'>
            <Input
              id='notes'
              label='Notes'
              size={InputSizes.LARGE}
              onChange={this.handleChange}
              value={notes}
            />
          </div>
          {this.renderGoals()}
          <div className='form-elem'>
            <Button onClick={this.handleCancel}>Cancel</Button>
            <Button>Save</Button>
          </div>
        </Form >
      </div>
    )
  }
}

export default Person;
