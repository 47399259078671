import React from 'react';
import {FormContext} from '../form/Form';

export default function formElement(Wrapped) {
  return class extends React.Component {
    render() {
      // return (
      //   <Wrapped className='wrapped' {...this.props}/>
      // )
      return (
        <FormContext.Consumer>
          {
            (context) => (
              <Wrapped className='wrapped' formContext={context} {...this.props}/>
            )
          }
        </FormContext.Consumer>
      )
    }
  }
}
