export function findPerson(peopleHash, str) {
  const ret = [];
  const parts = str.toLowerCase().split(/\s/);
  parts.forEach((part, ix) => {
    const folks = peopleHash[part];
    if (folks) {
      if (Object.keys(folks).length === 1) {
        // There is one person who matches this.
        const k = Object.keys(folks)[0];
        ret.push(folks[k]);
      } else {
        // There is more than one. Figure out which one.
        if (ix < parts.length-1) {
          const nextToken = parts[ix+1];
          const chars = nextToken.length;
          // See if last name is indicated in the following token
          Object.keys(folks).forEach(lastName => {
            if (lastName.substring(chars) === nextToken) {
              ret.push(folks[lastName]);
            }
          })
        }
      }
    }
  })
  return ret;
}

export function findPeople(peopleList, wheelTreeHash) {
  const ret = {};
  if (!(peopleList && wheelTreeHash)) {
    return undefined;
  }
  const peopleHash = {};
  peopleList.forEach(person => {
    const { id, name } = person;
    const [first, ...other] = name.toLowerCase().split(/\s/);
    const rest = other.join(' ');
    if (!peopleHash[first]) {
      peopleHash[first] = {};
    }
    peopleHash[first][rest] = person;
  });
  Object.values(wheelTreeHash).forEach((v) => {
    const { doc } = v;
    if (!doc) return;    
    const { name } = doc;
    const peeps = findPerson(peopleHash, name);
    peeps && peeps.forEach((peep) => {
      const { id } = peep;
      if (!ret[id]) {
        ret[id] = []
      }
      ret[id].push(doc);
    })
  })
  return ret;
}
