import Values from './store/values';
import Objectives from './store/objectives';

let unsubscribeValues, unsubscribeObjectives;

let initialized;
let notificationComplete;
let tree;
let nodeHash;
let valueList;
let objectivesList;
let listeners = [];

function validateNode(id) {
  if (!nodeHash[id]) {
    nodeHash[id] = {
      id,
      children: []
    }
  }
}

function addNode(id, doc, parentId) {
  validateNode(id);
  nodeHash[id].doc = doc;
  if (parentId) {
    validateNode(parentId);
    nodeHash[parentId].children.push(nodeHash[id]);
  } else {
    tree.children.push(nodeHash[id]);
  }
}

function createTree() {
  nodeHash = {};
  tree = {
    children: []
  };

  if (valueList && objectivesList) {
    valueList.forEach(ref => {
      const {id, doc} = ref;
      addNode(id, doc);
    })
    objectivesList.forEach(ref => {
      const {doc, id} = ref;
      const {parent} = doc;
      if (!parent) {
        throw new Error(`Every objective must have a parent ${id}:${doc.name}`);
      }
      addNode(id, doc, parent);
    })
    listeners.forEach(listener => {
      listener(tree, nodeHash);
    })
    notificationComplete = true;
  }
}

function initialize() {
  const values = Values.getCollectionRef();
  const objectives = Objectives.getCollectionRef();
  nodeHash = {};

  unsubscribeValues = values.onSnapshot((results) => {
    valueList = [];
    results.forEach( docRef => {
      const doc = Object.assign({}, docRef.data());
      valueList.push({
        id: docRef.id,
        doc
      });
    })
    createTree();
  }, (error) => {
    console.log(error);
    alert('error', error);
  })

  unsubscribeObjectives = objectives.onSnapshot((results) => {
    objectivesList = [];
    results.forEach( docRef => {
      const doc = Object.assign({}, docRef.data());
      objectivesList.push({
        id: docRef.id,
        doc
      });
    })
    createTree();
  }, (error) => {
    console.log(error);
    alert('error', error);
  })
  initialized = true;
}

function denitialize() {
  unsubscribeValues();
  unsubscribeObjectives();
  initialized = false;
  notificationComplete = false;
}

export function registerWheelTreeListener(func) {
  listeners.push(func);

  if (!initialized) {
    initialize();
  }
  if (notificationComplete) {
    // There is not going to be a load event to trigger notification.  So do initial notification here.
    Promise.resolve().then(() => {
      // Doing this inside a promise/then allows this to be called from a constructor.
      func(tree, nodeHash);
    })
  }

  // Return an unsubscribe method
  return () => {
    const newListeners = listeners.filter(L => L!==func);
    listeners = newListeners;
    if (listeners.length === 0) {
      denitialize();
    }
  }
}
