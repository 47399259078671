// Similar to Calendar, except this knows how to load google calendar and franco
// items to populate the calendar with.

import React, {useState, useEffect} from 'react';
import {startOfDay, addDays} from 'date-fns';
import {getToday, dayToDate} from '../../util/dateHelper';
import Calendar from './Calendar';
import {withContext} from '../../util/context';
import './Calendar.scss';

let lastDay;

function FrCalendar(props) {
  const {context, showHeading=true, onItems} = props;
  const [sday, setDay] = useState(getToday());
  const day = props.day || sday;
  const onChangeDay = props.onChangeDay || setDay;
  const [items, setItems] = useState([]);

  useEffect(() => {
    const begin = startOfDay(dayToDate(day));
    const end = addDays(begin, 1);
    if (day!==lastDay) {
      lastDay = day;
      context.gapiWrapper.listUpcomingEvents(begin, end).then((results) => {
        const items = results.result.items
        console.log('===== gapiWrapper returned items', items);
        setItems(items);
        onItems && onItems(items);
      }, (error) => {
        console.log('===== gapiWrapper returned error', error);
        console.error(error);
        alert(`error ${error}`);
      });
    }
  }, [day])

  return <Calendar {...props} showHeading={showHeading} items={items} day={day} onChangeDay={onChangeDay}/>
}

export default withContext(FrCalendar);
