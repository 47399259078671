import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import './RadioGroup.scss';
import formElement from '../util/formElement';

class RadioGroup extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.string,
    choices: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    isRequired: PropTypes.bool,
    formContext: PropTypes.object // Provided automatically via formElement import.
  }

  handleChange = (evt) => {
    // const target = value.target;
    // const evt = {
    //   target: {
    //     id: this.props.id,
    //     value
    //   }
    // }
    this.props.onChange(evt);
  }
  checkValidity(value) {
    this.error = null;
    if (this.props.checkValidity) {
      this.error = this.props.checkValidity(value);
    }
    if ((!this.error) && this.props.isRequired && !value) {
      this.error = 'required';
    }
    if (this.props.formContext) {
      this.props.formContext.validity.setValid(this.props.id, this.error);
    }
  }
  getError() {
    if (!this.props.formContext) {
      return undefined;
    }
    const id = this.props.id;
    this.checkValidity(this.props.value);
    return this.props.formContext.validity.getError(id);
  }
  renderChoices() {
    const choices = [];
    this.props.choices.forEach(choice => {
      choices.push(
        <div key={choice.value}>
          <input type='radio'
            name={this.props.id}
            value={choice.value}
            id={choice.value}
            checked={this.props.value === choice.value}
            onChange={this.handleChange}
          />
          <label htmlFor={choice.value}>{choice.label}</label>
        </div>
      );
    })
    return (
      <Fragment>
        {choices}
      </Fragment>
    )
  }
  render() {
    let label = null;
    const error = this.getError();
    const showError = (Boolean(this.props.value) || (this.props.formContext && this.props.formContext.showInvalid)) && error;
    const errorMessage = showError ? ` (${this.getError()})` : '';
    if (this.props.label) {
      label = <label htmlFor={this.props.id}>{this.props.label}{errorMessage}</label>
    } else if (showError) {
      label = <label htmlFor={this.props.id}>{errorMessage}</label>
    }
    const errorClass = showError ? ' error' : '';

    const props = {
      id: this.props.id,
      onChange:this.handleChange,
      value: this.props.value || ''
    }
    if (this.props.style) {
      props.style = this.props.style;
    }

    return (
      <div className={`radio-group${errorClass}`}>
        {label}
        {this.renderChoices()}
      </div>
    )
  }

}

export default formElement(RadioGroup);
