import Tasks from '../data/store/tasks';
import WeeklyPlan from '../data/store/weeklyPlan';
import {timeInputsToMinutes} from './dateHelper';

export function findTask(tasks, taskId) {
  return tasks.find(task => task.id === taskId);
}

export function getCompleted(task) {
  return (task.occurances || []).reduce((count, occurance) => count + (occurance.r === 'forward' ? 0 : 1), 0);
}

export function getInstanceCount(task) {
  return task.count;
}

export function getDurationInMinutes(task) {
  let qty = parseInt(task.duration || "0");
  return timeInputsToMinutes(qty, task.durationUnit);
}

export function getInstanceId(task) {
  if (task.instance) {
    return `${task.id}.${task.instance}`;
  }
  return task.id;
}

export function getInstanceOf(task) {
  if (task.iof) {
    return task.iof;
  }
  return task.id;
}

export function getFrequencyStr(goal) {
  if (!goal) return null;
  const { frequency, frequencyUnit } = goal;
  if (frequency) {
    return `${frequency} per ${frequencyUnit}`;
  } else {
    if(frequencyUnit==='day') {
      return 'daily';
    }
  }
  return `${frequency}ly`;
}

export function getInstancesRemaining(task, day, week) {
  return getInstanceCount(task) - getCompleted(task);
}

export function getDailyPlan(weeklyPlan, day) {
  return (weeklyPlan.days || {})[day] || {status: 'none'}
}

export function getStatusOfDay(weeklyPlan, day) {
  const plan = getDailyPlan(weeklyPlan, day);
  if (!plan.tasks || plan.tasks.length === 0) {
    return 'none';
  }
  let status = 'done';
  plan.tasks.forEach(task => {
    if (!task.resolution) {
      status = 'open';
    }
  })
  return status;
}

export function getWeeklyPlanForDay(day, weeklyPlan={}, lastWeeklyPlan={}, nextWeeklyPlan={}) {
  let ret;
  const thisWeek = weeklyPlan.week ? weeklyPlan.week
                  : (lastWeeklyPlan.week ? lastWeeklyPlan.week + 7
                     : nextWeeklyPlan.week || day);
  if (day<thisWeek) {
    ret = lastWeeklyPlan;
  } else if (day>=thisWeek+7) {
    ret = nextWeeklyPlan;
  } else {
    ret = weeklyPlan;
  }
  return ret || {};
}

export function fetchTasks(unsubscribeTasksIn, setState) {
  const tasks = Tasks.getCollectionRef();
  if (unsubscribeTasksIn) unsubscribeTasksIn();
  const unsubscribeTasksOut = tasks.onSnapshot((results) => {
    const taskList = [];
    const taskHash = {};
    results.forEach( docRef => {
      const doc = Object.assign({}, docRef.data());
      doc.id = docRef.id;
      taskList.push(doc);
      taskHash[doc.id] = doc;
    })
    setState({
      taskList,
      taskHash
    })
  })
  return unsubscribeTasksOut;
}

export function calItemId(calItem) {
  return (calItem && calItem.extendedProperties && calItem.extendedProperties.private && calItem.extendedProperties.private.taskId) || calItem.iCalUID || calItem.id;
}

export function fetchWeeklyPlan(week, unsubscribeWeeklyPlanIn, setState) {
  const weeklyPlanRef = WeeklyPlan.getCollectionRef();
  if (unsubscribeWeeklyPlanIn) unsubscribeWeeklyPlanIn();
  const unsubscribeWeeklyPlanOut = weeklyPlanRef.where('week', ">=", week-7).onSnapshot( queryRef => {
    let lastWeek = {},
        thisWeek = {},
        nextWeek = {};
    if (!queryRef.empty) {
      queryRef.docs.forEach(docRef => {
        const doc = docRef.data();
        if (doc.week === week) {
          thisWeek = doc;
        } else if (doc.week === week - 7) {
          lastWeek = doc;
        } else if (doc.week === week + 7) {
          nextWeek = doc;
        }
      })
    }
    setState({
      lastWeeklyPlan: lastWeek,
      weeklyPlan: thisWeek,
      nextWeeklyPlan: nextWeek,
    });
  });
  return unsubscribeWeeklyPlanOut;
}
