import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {getEvents} from './calenderHelper';
import {DragContext, Droppable} from '../../util/dnd';
import {dateToTimeStr, addMinutes} from '../../util/dateHelper';
import {withContext} from '../../util/context';
import {ROUTES} from '../../util/constants';
import './Calendar.scss';

class CalendarDayList extends Component {
  static contextType = DragContext;
  static propTypes = {
      items: PropTypes.object,
      weeklyBlocking: PropTypes.object,
      day: PropTypes.number,
      onAddEvent: PropTypes.func,
      getEventInfo: PropTypes.func,
      history: PropTypes.object
  }
  constructor(props) {
    super(props);
    this.state = {
      dropTarget: {},
      addEvents: []
    }
    this.eventsId = 10000;
  }
  findTarget(y) {
    const ix = this.refs.findIndex(elem => {
      const ref = elem.ref.current;
      const br = ref.getBoundingClientRect();
      return y <= br.y;
    });
    let before, after;
    if (ix < 0) {
      if (this.refs.length) {
        before = this.refs[this.refs.length-1].event
      } else {
        before = 'begin'
      }
      after = 'end'
    } else {
      if (ix === 0) {
        before = 'begin'
      } else {
        before = this.refs[ix-1].event;
      }
      after = this.refs[ix].event;
    }
    return {before, after};
  }
  onDrag = (rect, dragId) => {
    if (this.props.onAddEvent && this.props.getEventInfo) {
      const target = rect ? this.findTarget(rect.y).after : {};
      this.setState({
        dropTarget: target
      })
    }
  }
  onDragEnd = (rect, dragId) => {
    if (rect && this.props.onAddEvent && this.props.getEventInfo) {
      const eventInfo = this.props.getEventInfo(dragId);
      const {before, after} = this.findTarget(rect.y);
      let startTime, endTime;
      if (before.endTime) {
        startTime = before.endTime;
        endTime = addMinutes(startTime, eventInfo.duration || 30);
      } else if (after.startTime) {
        endTime = after.startTime;
        startTime = addMinutes(endTime, -eventInfo.duration || -30);
      } else {
        throw new Error ('not implemented');
      }
      const addEvent = {
        id: dragId,
        type: 'a',
        startTime,
        endTime,
        desc: eventInfo.name
      }
      const addEvents = this.state.addEvents.slice();
      addEvents.push(addEvent);
      this.setState({
        addEvents
      })

      this.props.context.gapiWrapper.addEvent(addEvent).then(gevent => {
        console.log('added event', gevent)
      });
    }
    this.setState({
      dropTarget: {}
    })
  }
  handleAddEvent() {
    if (this.props.onAddEvent) {
    }
  }
  getEvents() {
    return getEvents(this.props.day, this.props.items || [], this.props.weeklyBlocking, this.state.addEvents);
  }
  handleCalendarClick = (evt) => {
    const id = evt.target.id || evt.target.parentNode.id;
    const events = this.getEvents();
    const event = events.find(event => event.id === id);
    if (event) {
      this.props.history.push(`${ROUTES.EVENT}/${id}`)
    }
  }
  renderItems() {
    const ret = [];
    const events = this.getEvents();
    this.refs = [];

    events.forEach(event => {
      const {startTime, endTime, id, desc} = event;
      const hiliteClass = (id === this.state.dropTarget.id) ? ' hilight': '';
      const ref = React.createRef();
      this.refs.push({ref, event, id});
      ret.push(
        <div key={id} id={id} className={`event row${hiliteClass}`} ref={ref} onClick={this.handleCalendarClick}>
          <div className={`cell begin`}>
            {dateToTimeStr(startTime)}
          </div>
          <div className='cell end'>
            {dateToTimeStr(endTime)}
          </div>
          <div className='cell'>
            {desc}
          </div>
        </div>
      )
    })
    const lastHilight = this.state.dropTarget === 'end' ? ' hilight' : '';
    return (
      <div className='calendar-day-list'>
        <Droppable dropId="calendar-day-list" onDrag={this.onDrag} onDragEnd={this.onDragEnd}>
          <div className='row headings'>
            <div className='cell start'>Start</div>
            <div className='cell end'>End</div>
            <div className='cell desc'>Desc</div>
          </div>
          {ret}
          <div className={`bottom-spacer${lastHilight}`}/>
        </Droppable>
      </div>
    )
  }
  render () {
    return this.renderItems();
  }
}

export default withContext(CalendarDayList);
