import React, {Component, Fragment} from 'react';
import {registerWheelTreeListener} from '../../data/wheelTree';
import SettingStore, {WEEKBLOCK_ID} from '../../data/store/settings';
import WeeklyPlan from '../../data/store/weeklyPlan';
import ProjectsData from '../../data/store/projects';
import ProcessNotes from '../dailyPlanning/ProcessNotes';
import Tasks from '../../data/store/tasks';
import {formatDay, getToday, firstOfWeek} from '../../util/dateHelper';
import {Button} from '../../components';
import WeeklyBlocking from './WeeklyBlocking';
import WeeklyTasks from './WeeklyTasks';
import './WeeklyPlanning.scss';
import { ROUTES } from '../../util/constants';

class WeeklyPlanning extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weekBlocking: {
        _waitingForData: true
      },
      week: firstOfWeek(getToday()),
      wheelTree: undefined,
      wheelTreeHash: undefined,
      lastWeeklyPlan: {
      },
      weeklyPlan: {
        _waitingForData: true
      },
      nextWeeklyPlan: {
      },
      projects: {
        _waitingForData: true
      },
      taskList: {
        _waitingForData: true
      }
    }
    this.loadData(this.state.week);
    this.unregisterWheelTree = registerWheelTreeListener((wheelTree, wheelTreeHash) => {
      this.setState({wheelTree, wheelTreeHash});
    })
  }
  loadData(week) {
    const weeklyPlanRef = WeeklyPlan.getCollectionRef();
    if (this.unsubscribeWeeklyPlan) this.unsubscribeWeeklyPlan();
    this.unsubscribeWeeklyPlan = weeklyPlanRef.where('week', ">=", week-7).onSnapshot( queryRef => {
      let lastWeek = {},
          thisWeek = {},
          nextWeek = {};
      if (!queryRef.empty) {
        queryRef.docs.forEach(docRef => {
          const doc = docRef.data();
          if (doc.week === week) {
            thisWeek = doc;
          } else if (doc.week === week - 7) {
            lastWeek = doc;
          } else if (doc.week === week + 7) {
            nextWeek = doc;
          }
        })
      }
      if (Object.keys(thisWeek).length > 0 || this.unsubscribeWeekBlocks) {
        this.setState({
          lastWeeklyPlan: lastWeek,
          weeklyPlan: thisWeek,
          nextWeeklyPlan: nextWeek,
          state: 'createTasks'
        })
      } else {
        const weekBlocks = SettingStore.getDocumentRef(WEEKBLOCK_ID);
        if (this.unsubscribeWeekBlocks) this.unsubscribeWeekBlocks();
        this.unsubscribeWeekBlocks = weekBlocks.onSnapshot( docRef => {
          if (docRef.exists) {
            const doc = Object.assign({}, docRef.data());
            this.setState({
              weekBlocking: doc
            })
          }
            this.setState({
              lastWeeklyPlan: lastWeek,
              weeklyPlan: thisWeek,
              state: 'confirmWb'
            })
        })
      }
    });
    if (this.unsubscribeProjecs) this.unsubscribeProjecs();
    this.unsubscribeProjecs = ProjectsData.query().onSnapshot( queryRef => {
      const projects = [];
      const docs = queryRef.docs;
      docs.forEach(docRef => {
        const {id} = docRef;
        const doc = Object.assign({}, docRef.data());
        projects.push({id, doc})
      })
      this.setState({projects});
    })
    const tasks = Tasks.getCollectionRef();
    if (this.unsubscribeTasks) this.unsubscribeTasks();
    this.unsubscribeTasks = tasks.onSnapshot((results) => {
      const taskList = [];
      results.forEach( docRef => {
        const doc = Object.assign({}, docRef.data());
        doc.id = docRef.id;
        taskList.push(doc);
      })
      this.setState({
        taskList
      })
    })
  }
  componentWillUnmount() {
    if (this.unregisterWheelTree) this.unregisterWheelTree();
    if (this.unsubscribeWeeklyPlan) this.unsubscribeWeeklyPlan();
    if (this.unsubscribeWeekBlocks) this.unsubscribeWeekBlocks();
    if (this.unsubscribeProjecs) this.unsubscribeProjecs();
    if (this.unsubscribeTasks) this.unsubscribeTasks();
  }
  updateWeeklyPlan(update) {
    const emptyDoc = {week: this.state.week, days:{}}
    const weeklyPlan = Object.assign(emptyDoc, this.state.weeklyPlan, update);
    WeeklyPlan.putDocument(this.state.week, weeklyPlan);
    this.setState({
      weeklyPlan: {
        _waitingForData: true
      }
    });
  }
  handleWeekBlockUpdate = (weekBlocking) => {
    this.updateWeeklyPlan({weekBlocking});
  }
  handleTaskCreation = (tasks) => {
    this.updateWeeklyPlan({tasks});
  }
  handleDone = () => {
    // navigate to home...
    this.props.history.push(ROUTES.HOME);
  }
  handleConfirmWb = () => {
    const {weekBlocking = this.state.weekBlocking} = this.state.weeklyPlan;
    this.updateWeeklyPlan({weekBlocking});
    // this.setState({
    //   wbEditState: 'read',
    //   state: 'createTasks'
    // })
  }
  newWeek = (week) => {
    this.setState({
      week,
      weeklyPlan: {
        _waitingForData: true
      }
    });
    if (week !== this.state.week) {
      this.loadData(week);
    }
  }
  handleIncWeek = () => {
    this.newWeek(this.state.week + 7);
  }
  handleDecWeek = () => {
    this.newWeek(this.state.week - 7);
  }
  render() {
    if (this.state.weeklyPlan._waitingForData) {
      return (
        <div className='weekly-planning'>
          <div>Weekly Planning</div>
          loading...
        </div>
      )
    }
    const weekBlocking = this.state.weeklyPlan.weekBlocking || this.state.weekBlocking;
    const blockingConfirmed = Boolean(this.state.weeklyPlan.weekBlocking);
    const {history} = this.props;
    return (
      <div className='weekly-planning'>
        <div>
          Weekly Planning for week starting {formatDay(this.state.week)}
          <Button buttonStyle='txt' onClick={this.handleIncWeek}>+</Button>
          <Button buttonStyle='txt' onClick={this.handleDecWeek}>-</Button>
        </div>
        <WeeklyBlocking
          week={this.state.week}
          data={weekBlocking}
          handleConfirm={this.handleConfirmWb}
          onChange={this.handleWeekBlockUpdate}
          showConfirm={!blockingConfirmed}
        />
        {blockingConfirmed ?
          <Fragment>
            <ProcessNotes weeklyPlan={this.state.weeklyPlan} history={history} title="1b. Notes" />
            <div className='section'>2. Tasks for this week</div>
            <div className='row'>
              <div className='cell'>
                <WeeklyTasks
                  week={this.state.week}
                  wheelTree={this.state.wheelTree}
                  wheelTreeHash={this.state.wheelTreeHash}
                  weeklyPlan={this.state.weeklyPlan}
                  onUpdate={this.handleTaskCreation}
                  onDone={this.handleDone}
                  projects={this.state.projects}
                  taskList={this.state.taskList}
                />
              </div>
            </div>
          </Fragment>
        :
          null
        }
      </div>
    )
  }
}

export default WeeklyPlanning;
