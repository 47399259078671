import React, {Component} from "react";
import {Link} from 'react-router-dom';
import Notes from '../../data/store/notes';
import {ROUTES} from '../../util/constants';
import './Notes.scss';

class NotesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInactive: false,
      noteList: []
    }
  }
  componentDidMount() {
    const notes = Notes.getCollectionRef();
    if (this.unsubscribe) this.unsubscribe();
    this.unsubscribe = notes.onSnapshot((results) => {
      const noteList = [];
      results.forEach( docRef => {
        const doc = Object.assign({}, docRef.data());
        doc.id = docRef.id;
        noteList.push(doc);
      })
      this.setState({
        noteList
      })
    })
  }
  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }
  handleNoteClick = (evt) => {
    let id = evt.target.id || evt.target.parentNode.id;
    this.props.history.push(`${ROUTES.NOTE}/${id}`)
  }

  toggleInactive = (evt) => {
    const { showInactive } = this.state;
    this.setState({showInactive: !showInactive});
  }

  renderNotes() {
    const ret = [];
    const { showInactive } = this.state;
    this.state.noteList.forEach(doc => {
      const { name, desc, status } = doc;
      const docActive = status !== 'inactive';
      const inactiveClass = docActive ? '' : 'inactive ';
      if (docActive || showInactive) {
        ret.push(
          <div key={doc.id} className="row">
            <div className={`${inactiveClass}note cell`} id={doc.id} onClick={this.handleNoteClick}>{doc.name}</div>
            <div className={`${inactiveClass}note cell`} id={doc.id} onClick={this.handleNoteClick}>{doc.desc}</div>
          </div>
        )
      }
    })
    return ret;
  }

  render() {
    const { showInactive } = this.state;
    const inactiveMsg = showInactive ? 'hide inactive' : 'show inactive';

    return (
      <div className='notes'>
        <div className='row heading'>
          <div className='cell'>Name</div>
          <div className='cell'>Description</div>
        </div>
        {this.renderNotes()}
          <div className='row footer'>
            <div className='cell'>
              <Link to={ROUTES.NOTE}>Add</Link>
            </div>
            <div className='cell'>
              <Link onClick={this.toggleInactive}>{inactiveMsg}</Link>
            </div>
          </div>
      </div>
    )
  }
}

export default NotesPage;
