
import {startOfDay, addDays} from 'date-fns';
import {errorAlert} from './utils';
const gapi = window.gapi;

const gapiConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  clientId: process.env.REACT_APP_CLIENT_ID,
  scope: "https://www.googleapis.com/auth/calendar.events",
  discoveryDocs: [
    "https://content.googleapis.com/discovery/v1/apis/calendar/v3/rest"
  ]
}

class GapiWrapper {
  constructor() {
    const prom = new Promise((resolve, reject) => {
      gapi.load('client:auth2', () => {
        console.log('==== constructed GapiWrapper');
        resolve(this.initGapiClient());
      })
    });
    console.log('===== constructor assigning this.client')
    this.client = prom;
  }
  initGapiClient = () => {
    console.log('==== initGapiClient', gapiConfig);
    let ret;
    try {
      ret = gapi.client.init(gapiConfig).then(() => {
        console.log('==== client initialized, assigning this.client');
        this.client = gapi.client;
        return this.client;
      }, (error) => {
        console.log('==== client initialization error', error);
        errorAlert(error);
      })
    } catch (e) {
      console.log('====== gapi initialization error');
      console.error(e);
      throw e;
    }
    return ret;
  }

  getClientPromise() {
    if (!this.client) {
      throw new Error('No client');
    }

    let clientPromise;
    if (this.client.then && typeof this.client.then === "function") {
      console.log('===== this.client is a promise', this.client);
      clientPromise = this.client;
    } else {
      console.log('===== this.client is not a promise', this.client);
      clientPromise = Promise.resolve(this.client);
    }
    return clientPromise;
  }

  updateEvent(event, id) {
    console.log('---updateEvent ',event);
    if (!id) {
      id = event.id
    }
    let request = gapi.client.calendar.events.update({
      'calendarId': 'primary',
      'eventId': id,
      'resource': event
    })
    return new Promise( (resolve, reject) => {
      request.execute(function(gevent) {
        console.log('Event created: ', gevent);
        resolve(gevent);
      });
    })
  }
  addEvent(event) {
    let reminderOverride;
    if (event.reminder) {
      reminderOverride = [
        {
          'method': 'popup',
          'minutes': event.reminder
        }
      ]
    }
    let gevent = {
      'extendedProperties': {
        'private': {
          'taskId': event.id
        }
      },
      'summary': event.desc,
      'description': `${event.desc} (from franco)`,
      'start': {
        'dateTime': event.startTime.toISOString(),
        'timeZone': Intl.DateTimeFormat().resolvedOptions().timeZone
      },
      'end': {
        'dateTime': event.endTime.toISOString(),
        'timeZone': Intl.DateTimeFormat().resolvedOptions().timeZone
      },
      'reminders': {
        'useDefault': false,
        'overrides': reminderOverride,
      }
    }
    console.log('---addEvent ',event);
    let request = gapi.client.calendar.events.insert({
      'calendarId': 'primary',
      'resource': gevent
    })
    return new Promise( (resolve, reject) => {
      request.execute(function(gevent) {
        console.log('Event created: ', gevent);
        resolve(gevent);
      });
    })
  }

  getEvent(id) {
    return this.getClientPromise().then(client => {
      return gapi.client.calendar.events.get({
          "calendarId": 'primary',
          "eventId": id
      });
    });
  }

  listUpcomingEvents(min, max) {
    min = min || startOfDay(new Date());
    max = max || addDays(min, 7);
    console.log('==== listUpcomingEvents fetching');
    return this.getClientPromise().then(client => {
      const timeMin = min.toISOString();
      const timeMax = max.toISOString();
      console.log('==== listUpcomingEvents clientPromise returns');
      return client.calendar.events.list({
        'calendarId': 'primary',
        'timeMin': timeMin,
        'timeMax': timeMax,
        'showDeleted': false,
        'singleEvents': true,
        'maxResults': 10,
        'orderBy': 'startTime'
      });
    })
  }

}

export default GapiWrapper;
