import {dayToDate, parseTime, getDaysFromDayList, getDayOfWeek} from '../../util/dateHelper';

class Columnizer {
  constructor() {
    this.columns = [];
  }
  insertItem(newItem) {
    let inserted = false;
    this.columns.forEach(column => {
      let last = 0;
      let ix=0;
      while(ix<column.length && !inserted) {
        const elem = column[ix];
        if (newItem.startTime >= last && newItem.endTime <= elem.startTime) {
          // There is space before elem.
          column.splice(ix, 0, newItem);
          inserted = true;
        } else {
          last = elem.endTime;
        }
        ix++;
      }
      if (!inserted && last < newItem.startTime) {
        // can be added to the end
        inserted = true;
        column.push(newItem);
      }
    })
    if (!inserted) {
      // did not fit any where. create a new column and add this one
      this.columns.push([newItem])
    }
  }
  insertGitem(gitem) {
    const start = new Date(gitem.start.dateTime);
    const end = new Date(gitem.end.dateTime);
    this.insertItem({
      type: 'g',
      id: gitem.id,
      startTime: start,
      endTime: end,
      desc: gitem.summary
    })
  }
  insertBlock(date, block, ix) {
    const start = new Date(date.getTime() + parseTime(block.start));
    const end = new Date(date.getTime() + parseTime(block.end));
    const desc = block.type==='s' ? 'Sleep' : (block.type==='w' ? 'Work' : block.desc);
    this.insertItem({
      type: 'b',
      id: `${ix}`,
      startTime: start,
      endTime: end,
      desc
    })
  }
  itemsToColumns(items) {
    items.forEach(item => {
      this.insertGitem(item)
    })
    return this.columns;
  }
  weeklyBlockingToColumns(day, weeklyBlocking={}) {
    const date = dayToDate(day);
    const dayOfWeek = (date.getDay() + 6) % 7;  // We have monday as zero based...
    (weeklyBlocking.blockList || []).forEach((block, ix) => {
      const days = getDaysFromDayList(block.days);
      if (days[dayOfWeek]) {
        // process this block.
        this.insertBlock(date, block, ix);
      }
    })
  }
  _getStartsEnds(columns) {
    const ret = [];
    columns.forEach(col => {
      const entries = [];
      col.forEach(item => {
        entries.push({
          time: item.startTime,
          type: 's',
          item
        })
        entries.push({
          time: item.endTime,
          type: 'e',
          item
        })
      })
      ret.push(entries);
    })
    return ret;
  }
  addEvents(events) {
    events.forEach(event => {
      const {id, startTime, endTime, desc} = event;
      this.insertItem({
        type: 'a',
        id,
        startTime,
        endTime,
        desc
      })
    })
  }
  columnsToEvents(columns) {
    const ixs = [];
    let itemsLeft = 0;
    const startsEnds = this._getStartsEnds(columns);
    for (let cx=0; cx<startsEnds.length; cx++){
      ixs[cx]=0;
      itemsLeft += startsEnds[cx].length;
    }

    const blocks = [];
    // Visually, a cluster of events are events that temporaly overlap each other
    // in some way. So a new cluster happens whenever the current block count is zero.
    let blockCount = 0;
    let clusterStart = -1;
    let clusterWidth = -1;
    while( itemsLeft>0 ) {
      // Find next item
      let nextCx = -1;
      let nextTime;
      for( let cx=0; cx<startsEnds.length; cx++) {
        const ix = ixs[cx];
        const column = startsEnds[cx];
        const time = ix<column.length ? column[ix].time : null;
        if (time && (!nextTime || time<nextTime)) {
          if (!time) {
            debugger;
          }
          nextCx = cx;
          nextTime = time;
        }
      }
      // We now know which elem from startsEnds is next
      if (nextCx>=0) {
        const ix = ixs[nextCx];
        ixs[nextCx]++;
        const column = startsEnds[nextCx];
        const elem = column[ix];
        if (!elem) {
          debugger;
        }
        switch (elem.type) {
          case 's': {
            if (blockCount===0) {
              clusterStart = blocks.length;
            }
            blockCount++;
            blocks.push({
              ...elem.item,
              cx: nextCx,
              max: -1
            })
            if (nextCx>clusterWidth) {
              clusterWidth = nextCx;
            }
            break;
          }
          case 'e': {
            blockCount--;
            if (blockCount===0) {
              // end of block.  update max.
              for( let jx=clusterStart; jx<blocks.length; jx++) {
                blocks[jx].max = clusterWidth;
              }
              clusterWidth = -1;
            }
            break;
          }
          default: {
            throw new Error('unexpected type for elem' + JSON.stringify(elem))
          }
        }
      }
      itemsLeft--;
    }
    return blocks;
  }
  columnsToLines(columns) {
    this.columns = [];
    this.ixs = [];
    this.lines = [];
    columns.forEach(col => {
      const entries = [];
      col.forEach(item => {
        entries.push({
          time: item.startTime,
          type: 's',
          item
        })
        entries.push({
          time: item.endTime,
          type: 'e',
          item
        })
      })
      this.columns.push(entries);
      this.ixs.push(0);
    })
    let stillLooking = true;
    let min;
    while(stillLooking) {
      stillLooking = false;
      min = 0;
      /* eslint-disable no-loop-func */
      this.ixs.forEach((ix,cx) => {
        const col = this.columns[cx];
        if (col.length > ix) {
          // this column still in the running
          if (!min || (min > col[ix].time)) {
            min = col[ix].time;
            stillLooking = true;
          }
        }
      })
      if (stillLooking) {
        // Make an entry in the line table.\
        const line = {
          time: min,
          items: {}
        }
        /* eslint-disable no-loop-func */
        this.ixs.forEach((ix, cx) => {
          const col = this.columns[cx];
          if (col.length > ix) {
            // this column still in the running
            const entry = col[ix];
            if (min === entry.time) {
              if (entry.type === 'e') {
                let nextTime;
                if (col.length > ix + 1) {
                  const nextEntry = col[ix+1];
                  nextTime = nextEntry.time;
                }
                if (min === nextTime) {
                  // this means next item starts where this ends, skip to that line
                  line.items[cx] = {item:col[ix+1].item, type:col[ix+1].type};
                  this.ixs[cx]++;
                } else {
                  // this means there is free time where this line ends
                  line.items[cx] = null;
                }
              } else {
                line.items[cx] = {item: entry.item, type: entry.type};
              }
              this.ixs[cx]++;
            }
          }
        })
        this.lines.push(line)
      }
    }
    return this.lines;
  }
};

/*
const days = ["Mo", "Tu", "we", "Th", "Fr", "Sa", "Su"];
function _getDaysFromDayList(dayList) {
  const ret = {};
  const parts = dayList.split(",");
  parts.forEach(part => {
    const [first, last] = part.split("-");
    let start = days.indexOf(first)
    let end = last ? days.indexOf(last) : start;
    if (start>=0 && end>=0) {
      while(start <= end) {
        ret[start] = 1;
        start++;
      }
    }
  })
  return ret;
}
*/

export function weeklyBlockingToLines(day, weeklyBlocking={}) {
  const date = dayToDate(day);
  const dayOfWeek = getDayOfWeek(day);  // We have monday as zero based...
  const inputs = [];
  (weeklyBlocking.blockList || []).forEach(block => {
    const days = getDaysFromDayList(block.days);
    if (days[dayOfWeek]) {
      // process this block.
      const start = new Date(date.getTime() + parseTime(block.start));
      const end = new Date(date.getTime() + parseTime(block.end));
      const desc = block.type==='s' ? 'Sleep' : (block.type==='w' ? 'Work' : block.desc);
      inputs.push({start, end, desc})
    }
  })
  inputs.sort((a, b) => (
    a.start < b.start ? -1 :
    (a.start > b.start ? 1 : 0)
  ))
  if (weeklyBlocking.incEx === 'ex') {
    // We want an exclusionary list, so that is basically this list...
    return inputs;
  }
  let curr = date;
  let items = [];
  const addItem = (start, end) => {
    items.push({
      start, end, desc: "unscheduled"
    })
  }
  inputs.forEach(block => {
    if (curr < block.start) {
      addItem(curr, block.start);
      curr = block.start;
    }
  })
  if (curr.getTime() < date.getTime() + 24*60*60*1000) {
    addItem(curr, new Date(date.getTime() + 24*60*60*1000));
  }
  return items;
}

export function itemsToColumns(items) {
  const cols = new Columnizer();
  return cols.itemsToColumns(items);
}

export function itemsToLines(items) {
  const cols = new Columnizer();
  const columns = cols.itemsToColumns(items);
  return cols.columnsToLines(columns);
}

export function getEvents(day, gitems, weeklyBlocking, addEvents) {
  const cols = new Columnizer();
  cols.itemsToColumns(gitems);
  cols.weeklyBlockingToColumns(day, weeklyBlocking)
  cols.addEvents(addEvents);
  return cols.columnsToEvents(cols.columns);
}
