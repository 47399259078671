// Given a list of objects, apportions them into columns such that no two objects overlap.

export function columnatorOverlapped(original) {
  const hash = {};
  const elems = original.map(e => {return {start:e.start.dateTime, end:e.end.dateTime, id:e.id}});
  elems.sort((a,b) => (a.start<b.start ? -1 : (a.start>b.start ? 1 : 0) ));
  const colEnds = [];
  elems.forEach(elem => {
    let found = false;
    colEnds.forEach((end, ix) => {
      if (!found && elem.start>=end) {
        colEnds[ix] = elems.end
        found = true;
        hash[elem.id] = ix;
      }
    })
    if (!found) {
      hash[elem.id] = colEnds.length;
      colEnds.push(elem.end)
    }

  })
  return hash;
}

function processCluster(elems, start, end, hash, colEnds) {
  let width = 1;
  for( let ix=0; ix<colEnds.length; ix++) if (ix) width = ix+1;
  for( let ix=start; ix<=end; ix++) {
    const elem=elems[ix]
    const { id } = elem;
    const entry = hash[id];
    entry.width = width;
  }
}
export function columnator(original, min) {
  const hash = {};
  const elems = original.map(e => {
    const start = new Date(e.start.dateTime);
    let end = new Date(e.end.dateTime);
    const origLen = end - start;
    if (min && (origLen < min)) {
      end = new Date(start.getTime() + min);
    }
    return {start, end, id:e.id}
  });
  elems.sort((a,b) => (a.start<b.start ? -1 : (a.start>b.start ? 1 : 0) ));
  let colEnds = [];
  let beginCluster;
  let clusterEnd = -1;
  elems.forEach((elem, ix) => {
    let col = 0;
    while (colEnds.length>col && colEnds[col] > elem.start) col++;
    if (elem.start >= clusterEnd) {
      // we are going to start a new cluster.
      if (beginCluster >= 0) {
        processCluster(elems, beginCluster, ix-1, hash, colEnds);
      }
      colEnds = [];
      beginCluster = ix;
    }
    if (elem.end > clusterEnd) {
      clusterEnd = elem.end;
    }
    colEnds[col] = elem.end;
    hash[elem.id] = { col };
  })
  processCluster(elems, beginCluster, elems.length-1, hash, colEnds);
  return hash;
}
