import React, {Component} from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ProjectData from '../../data/store/projects';
import {ROUTES} from '../../util/constants';
import Projects from '../../data/store/projects';
import {getUserId} from '../../util/fsGlobals';
import ProjectTask from './ProjectTask';
import ProjectInfo from './ProjectInfo';
import ProjectTasks from './ProjectTasks';
import ProjectBoard from '../projectBoard/ProjectBoard';
import './Projects.scss';
import 'react-tabs/style/react-tabs.css';

class Project extends Component {
  constructor(props) {
    super(props);

    const id = this.getId()
    if (Boolean(id)) {
      this.loadData(id);
      this.state = {
        doc: undefined,
        editTask: undefined,
        editProject: undefined
      }
    } else {
      this.state = {
        doc: {},
        editTask: undefined,
        editProject: {},
        showProjects: true
      }
    }
    this.state.tabIx = 0;  // Use pls to retain...
  }
  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }
  loadData(id) {
    if (this.unsubscribe) this.unsubscribe();
    this.unsubscribe = Projects.queryById(id).onSnapshot( queryRef => {
      if (queryRef.empty) {
        this.setState({doc:{}});
      } else {
        const {docs} = queryRef
        const doc = docs[0].data();
        if (!doc.tasks) {
          doc.tasks = [];
        }
        this.setState({doc})
      }
    })
  }
  getId() {
    const {id} = this.props.match.params;
    return id;
  }
  getEditMode() {
    return !this.getId() || (this.state && this.state.editProject);
  }
  handleEditTask = ({id, editTaskParentId} = {}) => {
    // 3 cases: add to top (no id, no parent), add to item (no id, parent), edit (id, no parent)
    let editTask;
    if (id) {
      [editTask, editTaskParentId] = this.findTask(id);
    } else {
      id = ProjectData.getCollectionRef().doc().id
      editTask = {id}
    }
    this.setState({
      editTask,
      editTaskParentId
    })
  }
  findTask(id, parentId, children = this.state.doc.children || []) {
    let ret = [undefined, undefined];
    children.forEach(child => {
      if (!ret[0]) {
        if (child.id === id) {
          ret = [child, parentId];
        } else {
          if (child.children && child.children.length > 0) {
            ret = this.findTask(id, child.id, child.children)
          }
        }
      }
    })
    return ret;
  }

  handleUpdateProject = (newProject) => {
    const id = this.getId();
    Projects.putDocument(id, newProject)
  };

  // ==== Handlers for <ProjectTask /> ====
  handleSaveTask = () => {
    let parent = this.state.doc;
    const task = this.state.editTask;
    if (this.state.editTaskParentId) {
      [parent] = this.findTask(this.state.editTaskParentId);
    }
    let ix=0;
    if (!parent.children) {
      parent.children = [];
    }
    // TODO replace with children.findIndex
    while(ix < parent.children.length && parent.children[ix].id !== task.id) ix++;
    if (ix < parent.children.length) {
      parent.children[ix] = task;
    } else {
      parent.children.push(task);
    }
    const id = this.getId()
    Projects.putDocument(id, this.state.doc);
    this.setState({
      editTask: undefined,
      editTaskParentId: undefined
    })
  }
  handleTaskUpdated = (task) => {
    const taskCopy = Object.assign(this.state.editTask, task);
    this.setState({
      editTask: taskCopy
    })
  }
  handleCancelTask = () => {
    this.setState({
      editTask: undefined,
      editTaskParentId: undefined
    })
  }

  // ==== Handlers for <ProjectInfo /> ====
  handleEditCancel = () => {
    const id = this.getId()
    if (!id) {
      // We were adding a new project. Return to projects.
      this.props.history.push(`${ROUTES.PROJECTS}`);
    } else {
      this.setState({
        editProject: undefined
      })
    }
  }
  handleEditChange = (change) => {
    const editProject = Object.assign({}, this.state.editProject, change);
    this.setState({
      editProject
    })
  }
  handleEditSubmit = () => {
    const id = this.getId();
    const uids = (this.state.doc || {}).uids || [
      getUserId()
    ]
    const updatedDoc = Object.assign({}, this.state.doc, this.state.editProject, {uids});
    Projects.putDocument(id, updatedDoc).then(docRef => {
      if (!id) {
        // Reload once we have a new document
        this.props.history.replace(`${ROUTES.PROJECT}/${docRef.id}`);
        this.loadData(docRef.id);
        this.setState({
          doc: undefined
        })
      }
      this.setState({
        editProject: undefined
      })
    });
  }
  handleRequestEdit = () => {
    if (!this.state.editProject) {
      this.setState({
        editProject: this.state.doc
      })
    }
  }
  handleTabChange = (ix) => {
    this.setState({tabIx: ix})
  }
  render() {
    if (this.state.doc === undefined) {
      return <div>loading...</div>
    }
    if (this.state.editTask) {
      return (
        <ProjectTask
          task={this.state.editTask}
          onTaskUpdated={this.handleTaskUpdated}
          onSaveTask={this.handleSaveTask}
          onCancel={this.handleCancelTask}
        />
      )
    }
    return (
      <div className='project'>
        <Tabs onSelect={this.handleTabChange} selectedIndex={this.state.tabIx}>
          <TabList>
            <Tab>Project</Tab>
            <Tab>Project Board</Tab>
          </TabList>
          <TabPanel>
            <ProjectInfo
              project={this.state.doc}
              edit={this.state.editProject}
              onCancel={this.handleEditCancel}
              onChange={this.handleEditChange}
              onSubmit={this.handleEditSubmit}
              onRequestEdit={this.handleRequestEdit}
            />
            { this.getEditMode() ?
              null
            :
              <ProjectTasks project={this.state.doc} onEditTask={this.handleEditTask}/>
            }
          </TabPanel>
          <TabPanel>
            <ProjectBoard project={this.state.doc} onUpdate={this.handleUpdateProject}/>
          </TabPanel>
        </Tabs>
      </div>
    )
  }
}

export default Project;
