import React, {Component} from 'react';
import GridBackground from './gridBackground';
import './Calendar.scss';


class WeeklyCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 1,  // These will be reset
      height: 1
    }
  }
  componentDidMount() {
    this.updateCanvasSize();
    window.addEventListener("resize", this.updateCanvasSize);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateCanvasSize);
  }
  updateCanvasSize = () => {
    this.setState({
      width:  this.refs.WeeklyCalendar.clientWidth,
      height:  this.refs.WeeklyCalendar.clientHeight
    })
  }
  render() {
    const topLabels = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];
    const leftLabels = [
      '12m', '2am', '4am', '6am', '8am', '10am',
      '12n', '2pm', '4pm', '6pm', '8pm', '10pm'
    ]
    return (
      <div className='weekly-calendar' ref='WeeklyCalendar'>
        <GridBackground
          cols={topLabels}
          rows={leftLabels}
          topLabelHeight={20}
          leftLabelWidth={40}
          margin={5}
          width={this.state.width}
          height={this.state.height}
          onScaleChange={this.props.onScaleChange}
        />
        <div className='items'>
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default WeeklyCalendar;
