import React, {Component} from 'react';
import WeeklyPlan from '../../data/store/weeklyPlan';
import Tasks from '../../data/store/tasks';
import {withContext} from '../../util/context';
import {getDailyPlan, getInstanceId, getInstanceOf, calItemId} from '../../util/taskHelper';
import {startOfDay, addDays} from 'date-fns';
import {formatDay, getToday, dayToDate, firstOfWeek, dateToTimeStr} from '../../util/dateHelper';
import {registerWheelTreeListener} from '../../data/wheelTree';
import {registerProjectTreeListener} from '../../data/projectTree';
import {Button, CheckBox} from '../../components';
import PropTypes from 'prop-types';
import {DragAndDrop, DragContext, Draggable, Droppable} from '../../util/dnd';
import './DailyDos.scss';

const withDndContext = Component => props => {
  return (
    <DragAndDrop>
      <Component {...props}/>
    </DragAndDrop>
  )
}

class DailyDos extends Component {
  static contextType = DragContext;
  static propTypes = {
    day: PropTypes.number.isRequired,
    context: PropTypes.object.isRequired
  }
  constructor(props) {
    super(props);
    const day = this.getDay();
    const week = firstOfWeek(day);
    this.state = {
      day,
      week,
      weeklyPlan: {
        _waitingForData: true
      },
      wheelTree: undefined,
      wheelTreeHash: undefined,
      taskHash: undefined,
      calendarItems: undefined,
      projectTree: undefined,
      buttonState: {},
    }
    this.fetchCalendarData(day);
    this.loadData(week);
    this.unregisterWheelTree = registerWheelTreeListener((wheelTree, wheelTreeHash) => {
      this.setState({wheelTree, wheelTreeHash});
    })
    this.unregisterProjectTreeListener = registerProjectTreeListener((projectTree, projectTreeHash) => {
      this.setState({projectTree, projectTreeHash})
    })
  }
  getDay() {
    return this.props.day || getToday();
  }
  componentDidMount() {
    const tasks = Tasks.getCollectionRef();
    if (this.unsubscribeTasks) this.unsubscribeTasks();
    this.unsubscribeTasks = tasks.onSnapshot((results) => {
      const taskList = [];
      const taskHash = {};
      results.forEach( docRef => {
        const doc = Object.assign({}, docRef.data());
        doc.id = docRef.id;
        taskList.push(doc);
        taskHash[doc.id] = doc;
      })
      this.setState({
        taskList,
        taskHash
      })
    })
  }
  componentWillUnmount() {
    if (this.unsubscribeWeeklyPlan) this.unsubscribeWeeklyPlan();
    if (this.unregisterWheelTree) this.unregisterWheelTree();
    if (this.unregisterProjectTreeListener) this.unregisterProjectTreeListener();
    if (this.unsubscribeTasks) this.unsubscribeTasks();
  }
  fetchCalendarData(day) {
    const begin = startOfDay(dayToDate(day));
    const end = addDays(begin, 1);
    this.props.context.gapiWrapper.listUpcomingEvents(begin, end).then((results) => {
      const calendarItems = results.result.items;
      this.setState({calendarItems});
    });
  }
  loadButtonState(weeklyPlan = this.state.weeklyPlan, day = this.state.day) {
    const dailyTasks = this.getDailyTasks(day, weeklyPlan);
    const buttonState = {};
    dailyTasks.forEach(task => {
      const {resolution} = task;
      const idinst = getInstanceId(task);
      // const resolution =  this.state.buttonState[idinst];
      buttonState[idinst] = Boolean(resolution === 'done')
    })
    this.setState({
      buttonState
    });
  }
  loadData(week) {
    const weeklyPlanRef = WeeklyPlan.getCollectionRef();
    if (this.unsubscribeWeeklyPlan) this.unsubscribeWeeklyPlan();
    this.unsubscribeWeeklyPlan = weeklyPlanRef.where('week', "==", week).onSnapshot( queryRef => {
      if (queryRef.empty) {
        this.setState({
          weeklyPlan: {
            _empty: true
          }
        })
      } else {
        const weeklyPlan = queryRef.docs[0].data();
        this.loadButtonState(weeklyPlan);
        this.setState({
          weeklyPlan
        });
      }
    });
  }
  getDocForTask(task) {
    const id = getInstanceOf(task);
    return this.getDocForId(id);
  }
  getDocForId(id) {
    // Just try them all...
    return (this.state.wheelTreeHash[id] && this.state.wheelTreeHash[id].doc)
      || (this.state.projectTreeHash && this.state.projectTreeHash[id])
      || (this.state.taskHash && this.state.taskHash[id])
      || {
        name: '(unknown)',
        _unknown: true
      };
  }
  getDailyTasks(day = this.state.day, weeklyPlan = this.state.weeklyPlan) {
    return getDailyPlan(weeklyPlan, day).tasks || []
  }
  getTaskForIid(iid) {
    const tasks = this.getDailyTasks();
    return tasks.find(task => getInstanceId(task) === iid)
  }
  updateWeeklyPlanOrder(moveId, beforeId) {
    const day = this.state.day;
    const newDailyTasks = this.getDailyTasks(day).slice();
    const mix = newDailyTasks.findIndex((elem) => elem.id === moveId);
    const bix = newDailyTasks.findIndex((elem) => elem.id === beforeId);
    if (mix>=0 && bix>=0 && mix!==bix) {
      const elem = newDailyTasks.splice(mix,1)[0];
      const rebix = newDailyTasks.findIndex((elem) => elem.id === beforeId);
      newDailyTasks.splice(rebix,0,elem)
    }
    const newDailyPlan = Object.assign({}, getDailyPlan(this.state.weeklyPlan, day));
    newDailyPlan.tasks = newDailyTasks;
    const newWeeklyPlan = Object.assign({}, this.state.weeklyPlan);
    newWeeklyPlan.days[day] = newDailyPlan;
    WeeklyPlan.putDocument(this.state.weeklyPlan.week, newWeeklyPlan);
    // console.log(newWeeklyPlan);
  }
  updateWeeklyPlan(buttonState = this.state.buttonState) {
    const day = this.state.day;
    const newDailyTasks = this.getDailyTasks(day).slice();
    const newWeeklyTasks = this.state.weeklyPlan.tasks.slice();

    newDailyTasks.forEach((dailyTask, ix) => {
      // update resolution in daily task list
      const newDailyTask = Object.assign({}, dailyTask);
      // const resolution =  buttonState[`${dailyTask.id}.${dailyTask.instance}`];
      const clicked =  buttonState[getInstanceId(dailyTask)];
      const {resolution} = newDailyTask;
      if (clicked && resolution !== 'done') {
        newDailyTask.resolution = 'done';
      } else if (!clicked && resolution === 'done') {
        delete newDailyTask.resolution;
      }
      newDailyTasks[ix] = newDailyTask;

    })
    const newDailyPlan = Object.assign({}, getDailyPlan(this.state.weeklyPlan, day));
    newDailyPlan.tasks = newDailyTasks;
    const newWeeklyPlan = Object.assign({}, this.state.weeklyPlan);
    newWeeklyPlan.days[day] = newDailyPlan;
    newWeeklyPlan.tasks = newWeeklyTasks;
    WeeklyPlan.putDocument(this.state.weeklyPlan.week, newWeeklyPlan);
    // return newWeeklyPlan;
  }

  createDescriptors() {
    const ret = {
      scheduled: [],
      unscheduled: []
    }
    const scheduled = {};
    this.state.calendarItems.forEach(event => {
      const id = calItemId(event);
      const taskDoc = this.getDocForId(event.iCalUID);
      const eventDate = event.start.dateTime || event.start.date;
      if (!eventDate) {
        console.log('====== invalid eventData', event.start, event.summary);
      }
      ret.scheduled.push({
        id,
        name: event.summary,
        taskDoc: (taskDoc._unknown) ? undefined : taskDoc,
        dateTime: new Date(eventDate),
        day: this.state.day,
        taskless: true,
      })
      scheduled[id] = event;
    })
    const tasks = this.getDailyTasks();
    tasks.forEach(task => {
      const idinst = getInstanceId(task);
      if (scheduled[idinst]) {
        const elem = ret.scheduled.find(s => s.id===idinst);
        if (elem) {
          elem.taskless = false;
        }
      } else {
        const doc = this.getDocForTask(task);
        let descriptor;
        if (doc) {
          descriptor = {
            id: idinst,
            name: doc.name,
            taskDoc: task,
            day: this.state.day
          };
        } else {
          descriptor = {
            id: idinst,
            name: `unknown ${task.id}`,
            taskDoc: task,
            day: this.state.day
          }
        }
        ret.unscheduled.push(descriptor);
      }
    })
    return ret;
  }
  handleCheckBox = (evt) => {
    const {id} = evt.target;
    const task = this.getTaskForIid(id);
    const newResolution = !Boolean(task.resolution === 'done');
    const newButtonState = Object.assign({}, this.state.buttonState);
    newButtonState[id] = newResolution;
    this.updateWeeklyPlan(newButtonState);
  }
  renderDescriptor(descriptor) {
    const {id, name, taskDoc, dateTime, taskless} = descriptor;
    const checked = this.state.buttonState[id];
    const dragging = this.state.dragId===id ? ' dragging' : '';
    const hasTask = !taskless;
    return (
        <div key={id} className={`row${dragging}`}>
          <div className='cell'>
            {hasTask ?
              <CheckBox
                id={id}
                onChange={this.handleCheckBox}
                value={checked} />
            :
              null
            }
          </div>
          { dateTime ?
            <div className='cell time'>
              {dateToTimeStr(dateTime)}
            </div>
          :
            null
          }
          <div className='cell desc'>
            <Draggable key={id} dragId={id}>
              {name}
            </Draggable>
          </div>
        </div>
    )
  }
  renderScheduledDescriptors(descriptors) {
    const ret = [];
    descriptors.scheduled.forEach(descriptor => {
      ret.push(this.renderDescriptor(descriptor));
    })
    return ret;
  }
  onDrag = (rect, dragId) => {
    if (!rect) {
      this.setState({
        inDrag: false
      })
    } else {
      const {vertical} = this.context.findNearest(rect, dragId);
      this.setState({
        nearestId: vertical && vertical.dragId,
        dragId,
        inDrag: true
      })
    }
  };
  onDragEnd = (rect, dragId) => {
    if (rect) {
      const {vertical} = this.context.findNearest(rect, dragId);
      this.updateWeeklyPlanOrder(dragId, vertical && vertical.dragId)
    }
    this.setState({
      nearestId: undefined,
      dragId: undefined,
      inDrag: false
    })
  }
  renderUnscheduledDescriptors(descriptors) {
    const ret = [];
    descriptors.unscheduled.forEach(descriptor => {
      if (this.state && this.state.inDrag) {
        if(this.state.nearestId===descriptor.id) {
          ret.push(
            <div key={`${descriptor.id}-s`} className='spacer' />
          )
        }
      }
      ret.push(this.renderDescriptor(descriptor));
    })
    return (
      <Droppable dropId='dropZone' onDrag={this.onDrag} onDragEnd={this.onDragEnd}>
        {ret}
      </Droppable>
    )
  }
  newDay = (day) => {
    const week = firstOfWeek(day);
    this.setState({day, week});
    if (week !== this.state.week) {
      this.loadData(week);
    } else {
      this.loadButtonState(undefined, day);
    }
    this.fetchCalendarData(day);
  }
  handleIncDay = () => {
    this.newDay(this.state.day + 1);
  }
  handleDecDay = () => {
    this.newDay(this.state.day - 1);
  }
  render() {
    if (this.state.weeklyPlan._waitingForData ||
        !this.state.wheelTree ||
        !this.state.taskHash ||
        !this.state.calendarItems ||
        !this.state.projectTree) {
      const marker = '' +
        (!this.state.weeklyPlan._waitingForData ? '' : 'weeklyPlan ') +
        (!!this.state.wheelTree ? '' : 'objectives ') +
        (!!this.state.taskHash ? '' : 'tasks ') +
        (!!this.state.calendarItems ? '' : 'calendar ') +
        (!!this.state.projectTree ? '' : 'projects ')
      return (
        <div className='daily-dos'>
          Loading (waiting for {marker})
        </div>
      )
    }
    if (this.state.weeklyPlan._empty) {
      return (
        <div className='daily-dos'>
          Create weekly plan
        </div>
      )
    }
    const {day} = this.state;
    const dateStr = formatDay(this.state.day);
    const descriptors = this.createDescriptors();
    const scheduled = this.renderScheduledDescriptors(descriptors);
    const unscheduled = this.renderUnscheduledDescriptors(descriptors);
    return (
      <div className='daily-dos'>
        <div>
          Daily Do's for {dateStr}
          <Button buttonStyle='txt' onClick={this.handleIncDay}>+</Button>
          <Button buttonStyle='txt' onClick={this.handleDecDay}>-</Button>
        </div>
        <div id={day} key={day}>
          <div className='section'>Calendar</div>
          {scheduled}
          <div className='section'>Unscheduled</div>
          {unscheduled}
        </div>
      </div>
    )
  }
}

export default withContext(withDndContext(DailyDos));
