import React, {Component} from 'react';
import './Appointment.scss';

class Appointment extends Component {
  render() {
    return <div>Appointment</div>
  }
}

export default Appointment;
