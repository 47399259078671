import React from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Form, InputSizes } from '../../components';
import './DailyPlanning.scss';
import ToDosStore from '../../data/store/tasks';
import { CommonTaskTypes } from '../../data/store/commonTaskTemplate';

class InlineTodo extends React.Component {
  static propTypes = {
    addTaskToPlan: PropTypes.func.isRequired,
  }
  constructor(props) {
    super(props);
    this.state = {
      addingInlineTodo: false,
      description: '',
    }
  }
  showInlineTodo = () => {
    const addingInlineTodo = !this.state.addingInlineTodo;
    const description = addingInlineTodo ? this.state.desription : '';
    this.setState({ addingInlineTodo, description });
  }
  onAddToDo = () => {
    const { description } = this.state;
    const { addTaskToPlan } = this.props;
    if (description) {
      // Create a new to do
      const doc = {
        name: description,
        objType: CommonTaskTypes.TODO,
        parent: 'none',
      }
      const id = undefined;
      ToDosStore.putDocument(id, doc).then((newDoc) => {
        // toggle form (turn it off)
        this.showInlineTodo();
        // Add it to the list of tasks for today
        addTaskToPlan && addTaskToPlan(newDoc.id);
      });
    }
  }
  handleDescriptionChange = (evt) => {
    const { value } = evt.target;
    this.setState({description: value});
  }
  render() {
    const { addingInlineTodo, description } = this.state;
    const buttonLabel = addingInlineTodo ? '-' : '+';
    const button = <Button buttonStyle="small-finger white" id="addTodo" onClick={this.showInlineTodo}>{buttonLabel}</Button>;
    if (!addingInlineTodo) {
      return <div className="add-new-to-do">{button} Add New To Do</div>
    }
    return (
      <>
        <div className='row title small'><div className="cell">Add New To do</div></div>
        <Form onSubmit={this.onAddToDo}>
          <div className="row add-new-to-do">
            <div className="cell">{button}</div>
            <div className="cell">
              <Input
                id="add-to-do"
                isRequired={true}
                label='Description'
                value={description}
                size={InputSizes.LARGE1}
                onChange={this.handleDescriptionChange}
              />
            </div>
          </div>
          <div className="row add-to-do-buttons">
            <div className="cell buttons"/>
            <div className="cell">
              <Button buttonStyle='small'>Add</Button></div>
            </div>
        </Form>
      </>
    )
  }
}

export default InlineTodo;
