import {CommonTaskTypes, commonTaskTemplate} from './commonTaskTemplate';

export const allowedTaskTypes = [
  CommonTaskTypes.ACCOMPLISHMENT,  // First one is default
  CommonTaskTypes.TRAIT,
  CommonTaskTypes.EXPERIENCE,
  CommonTaskTypes.PRACTICE
]
export const listName = 'objectives';

const Objectives = commonTaskTemplate(listName, allowedTaskTypes);

export default Objectives;
