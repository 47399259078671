import React, {Component, Fragment} from 'react';
import {DragContext, Draggable} from '../../util/dnd';
import {formatDay} from '../../util/dateHelper';
import {getDailyPlan, getInstanceId} from '../../util/taskHelper';
import {registerProjectTreeListener} from '../../data/projectTree';
import './DailyPlanning.scss';

class DailyTasks extends Component {
  static contextType = DragContext;
  constructor(props) {
    super(props);
    this.state = {};
    this.unregisterProjectTreeListener = registerProjectTreeListener((projectTree, projectTreeHash) => {
      this.setState({
        projectTree,
        projectTreeHash
      })
    })
  }
  componentWillUnmount() {
    if (this.unregisterProjectTreeListener) this.unregisterProjectTreeListener();
  }
  getDocForTask(task) {
    const {id} = task;
    // Just try them all...
    return (this.props.objHash[id] && this.props.objHash[id].doc)
      || (this.state.projectTreeHash && this.state.projectTreeHash[id])
      || (this.props.taskHash && this.props.taskHash[id])
      || {
        name: '(unknown)'
      };
  }
  getDailyTasks(day = this.props.day) {
    return getDailyPlan(this.props.weeklyPlan, day).tasks || []
  }
  renderDailyTaskLines() {
    const ret = [];
    this.getDailyTasks().forEach(task => {
      const {name} = this.getDocForTask(task);
      const instanceId = getInstanceId(task);
      const duration = task.duration ? `{timeInputsToMinutes(task.duration, task.durationUnit)} mins` : '';
      ret.push(
        <Draggable key={instanceId} dragId={instanceId}>
          {duration} {name} {task.instance}
        </Draggable>
      )
    })
    return ret;
  }
  renderDailyTasks() {
    return (
        this.renderDailyTaskLines()
    )
  }
  render() {
    if (this.props.weeklyPlan._waitingForData || !this.props.objHash) {
      return <div>loading...</div>
    }
    const day = formatDay(this.props.day);
    return (
      <Fragment>
      <div className='section'>Tasks for {day}</div>
      <div className='tasks'>
        {this.renderDailyTasks()}
      </div>
      </Fragment>
    )
  }
}

export default DailyTasks;
