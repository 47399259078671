import React, {Component} from "react";

class Main extends Component {
  render() {
    return (
      <div>
        Main
      </div>
    )
  }
}

export default Main;
