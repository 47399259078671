import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Form, Input, Button} from '../../components';
import {ROUTES} from '../../util/constants';
import './Login.scss';
import {withContext} from '../../util/context';
import app from 'firebase/app';

const gapi = window.gapi;

class LoginWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signup: this.props.signup
    }
  }

  async handleGoogleAuth() {
    const googleAuth = gapi.auth2.getAuthInstance()

    try {
      const googleUser = await googleAuth.signIn();

      const token = googleUser.getAuthResponse().id_token;

      const credential = app.auth.GoogleAuthProvider.credential(token);

      await app.auth().signInAndRetrieveDataWithCredential(credential);

    } catch(err) {
      console.log(err);
    }

  }

  signIn = () => {
    this.setState({
      signup: false
    })
  }

  signUp = () => {
    this.setState({
      signup: true
    })
  }

  handleChange = (evt) => {
    this.setState({
      [evt.target.id]: evt.target.value,
      error: null
    })
  }

  formValidate = () => {
    if (this.state.signup) {
      if (this.state.pass !== this.state.pass2) {
        return {
          'pass2': 'Passwords must match'
        }
      }
    }
    return null; // No validation problems
  };

  handleSubmit = () => {
    const signin = !Boolean(this.state.signup);
    const {email, pass} = this.state;
    let promise;
    if (signin) {
      promise = this.props.context.firebaseWrapper.signIn(email, pass);
    } else {
      promise = this.props.context.firebaseWrapper.createUser(email, pass);
    }
    return promise.catch(error => {
      let {message} = error;
      if (error.code === 'auth/invalid-password') {
        message = 'Invalid password';
      }
      this.setState({error: message});
    })
  };

  render () {
    const signin = !Boolean(this.state.signup);
    const buttonLabel = signin ? 'Sign In' : 'Sign Up';
    const showPwd2 = !signin && Boolean(this.state.pass);
    const {email, pass, pass2} = this.state;
    return (
      <div className="login-widget">
        <div className="partner-auth">
          <Button onClick={this.handleGoogleAuth}>
            <span className="google-icon"/>
            Sign in with Google
          </Button>
          <div>
            <span className="strike"/> or <span className="strike" />
          </div>
        </div>
        <Form formValidate={this.formValidate} onSubmit={this.handleSubmit}>
          <div className='submit-err'>
            {this.state.error}
          </div>
          <div className='form-elem'>
            <Input
              id='email'
              label='Email'
              value={email}
              isRequired={true}
              onChange={this.handleChange}
            />
          </div>
          <div className='form-elem'>
            <Input
              id='pass'
              label='Password'
              value={pass}
              isRequired={true}
              onChange={this.handleChange}
            />
            {
              signin ?
                <div className='note-text'>
                  <Link to={ROUTES.PASSWORD_RESET}>Forgot password or user id?</Link>
                </div>
              : null
            }
          </div>
          {
            showPwd2 ?
              <div className='form-elem'>
                <Input
                  id='pass2'
                  label='Confirm Password'
                  value={pass2}
                  isRequired={true}
                  onChange={this.handleChange}
                />
              </div>
            : null
          }
          <div className='form-elem'>
            <Button>
              {buttonLabel}
            </Button>
          </div>
          {
            signin ?
              <div className='form-elem signup-link'>
                Don't have an account yet?  <div className='link' onClick={this.signUp}>Sign Up</div>
              </div>
            :
              <div className='form-elem signup-link'>
                Already havee an account?  <div className='link' onClick={this.signIn}>Sign In</div>
              </div>
          }
        </Form>
      </div>
    )
  }
}

export default withContext(LoginWidget);
